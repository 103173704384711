<div class="brand-bar brand-bar-light fixed-top">
  <div class="brand-bar-header">
    <a
      id="corpLink"
      [href]="userNavigationServiceURL"
      class="brand-bar-brand mr-auto"
      aria-title="NielsenIQ Studio"
    >
      <span class="brand-icon"
        ><img src="assets/images/NIQ-BASES-logo-blues.svg" alt="NielsenIQ_Studio_Logo"
      /></span>
    </a>
  </div>
  <div role="navigation">
    <ul class="brand-bar-options">

      <li class="user-info-dropdown" *ngIf="userInfo">
        <button class="margin-left-0" mat-button disableRipple [matMenuTriggerFor]="menu">
          <span class="user-welcome">Hello</span>
          <span class="user-name">{{ userInfo.fullName }}</span>
          <span class="sif sif-10 sif-chevron-s"></span>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <ng-content select="[menu-buttons]"></ng-content>
          <button *ngFor="let link of links" class="user-navigation" mat-menu-item>
            <a class="link" [href]="link.url"> {{ link?.label }} </a>
          </button>
          <button class="logout-btn" (click)="logout($event)" mat-menu-item>
            <a class="link">Log Out</a>
          </button>
        </mat-menu>
      </li>
    </ul>
  </div>
</div>
