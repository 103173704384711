/**
 *	Interceptor to do the following tasks before sending request to the server
 *	and before your application sees it.
 * 	i. Show/ Hide Loader
 *	ii. Append authorization header
 *	iii. Send JSESSION cookie
 **/
// angular imports
import { Injectable, NgModule, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpHeaders,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
// rxjs imports
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { throwError } from "rxjs";

import { HttpLoaderService } from "../shared/components/http-loader/http-loader.service";

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private httpLoaderService: HttpLoaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Display Overlay Loader
    if (!this.httpLoaderService.noSpinnerCall) {
      this.httpLoaderService.display(true);
    }
    this.httpLoaderService.noSpinnerCall = false;

    // Add 'arwen' specific header for Gandalf to recognize
    // Send current URL so upon successful login, Gandalf can redirect to this URL
    let reqHeaders = new HttpHeaders({
      source: "arwen",
      targetUrl: window.location.href,
    });

    // Add 'withCredentials' property to set cookies in cross-site Access-Control requests
    const arwenRequest = req.clone({
      withCredentials: true,
      headers: reqHeaders,
    });

    /**
     * Handles common errors based on error status code
     * 401: session time out - redirects to login page
     * 403: invalid permission - redirects to access-denied page
     * 404: invalid api request - redirects to invalid URL page (page not found)
     * Other errors are returned to its respective component
     *
     */
    return next.handle(arwenRequest).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.httpLoaderService.display(false);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        /**
         * Injecting router inside the interceptor to avoid
         * cyclic dependency between Router and HTTP.
         */
        const router = this.injector.get(Router);
        if (error.error && error.status == 401) {
          let errorRedirectMessage = error.error;
          if (typeof errorRedirectMessage == "string")
            errorRedirectMessage = JSON.parse(errorRedirectMessage);
          if (errorRedirectMessage.message)
            window.location.href = errorRedirectMessage.message;
        } else if (error.error && error.status == 403) {
          router.navigateByUrl("access-denied");
        } else if (error.error && error.status == 404) {
          router.navigateByUrl("invalid-url");
        } else if (error.error && error.status == 500) {
          router.navigateByUrl("system-error");
        }
        return throwError(error);
      })
    );
  }
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
    HttpLoaderService,
  ],
})
export class InterceptorModule {}
