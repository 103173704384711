import { AppConfigService } from './config/app-config.service';
import { NgModule, APP_INITIALIZER, ModuleWithProviders, Injector } from '@angular/core';
import { configFactory } from './config/app-config.initializer';
import { translationFactory } from './i18n/translation.initializer';
import { TranslateService } from '@ngx-translate/core';


@NgModule()
export class CoreModule {

  /**
   * `forRoot()` method returns a `ModuleWithProviders` object which provides
   * `root` modules with the shared service instances.
   *
   * @static
   * @returns {ModuleWithProviders}
   * @memberof CoreModule
   */
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AppConfigService,
        {
          provide: APP_INITIALIZER,
          useFactory: configFactory,
          deps: [AppConfigService],
          multi: true
        },
        {
          provide: APP_INITIALIZER,
          useFactory: translationFactory,
          deps: [TranslateService],
          multi: true
        }
      ]
    };
  }
}
