// angular imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// MISC imports
import { AppConfigService } from '../../core/config/app-config.service';
import { ORGANIZATIONS_API, SEARCH_ORGNAME } from '../utils/api-list';

@Injectable()
export class OrganizationService {

  constructor (
    private httpClient: HttpClient,
    private appConfigService: AppConfigService) {
  }

  /**
   * Get list of Organizations from Answers
   * @param none => gets the entire list of Organizations
   * @param searchTerm => gets list of Organizations with search term
   * */
  getOrganizations(searchTerm) {
    let getOrgsAPI = searchTerm === '' ? `${this.appConfigService.config.gandalf.url}/${ORGANIZATIONS_API}` : `${this.appConfigService.config.gandalf.url}/${ORGANIZATIONS_API}?${SEARCH_ORGNAME}=${searchTerm}`;
    return this.httpClient.get<any>(getOrgsAPI)
  }

  /**
   * Create new Organization with specified details
   * @param Create Organization form data
   * */
  createOrganization(orgInfo) {
    return this.httpClient.post<any>(`${this.appConfigService.config.gandalf.url}/${ORGANIZATIONS_API}`, orgInfo)
  }

  /**
   * Update Organization with specified details
   * @param Update Organization form data
   * */
  updateOrganization(orgInfo) {
    return this.httpClient.put<any>(`${this.appConfigService.config.gandalf.url}/${ORGANIZATIONS_API}`, orgInfo)
  }

}
