export const MESSAGES = {
  CREATE_GROUP_TITLE: 'Create New Group',
  EDIT_GROUP_TITLE: 'Edit Group: ',
  GROUP_NAME_REQUIRED: 'Group Name is required',
  MANAGE_MEMBERS: 'Members updated successfully',
  EDIT_GROUP: ' is updated successfully',
  CREATE_GROUP: ' is created successfully',
  EDIT_FLAG: ' is updated successfully',
  EDIT_USER_FLAGS: ' flags are updated successfully'
};
