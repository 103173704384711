// angular imports
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { NgModel, FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// MISC imports
import { UsersService } from '../../services/users.service';
import { HttpLoaderService } from '../../../shared/components/http-loader/http-loader.service';
import { MESSAGES } from '../../utils/messages';
import { CommonService } from '../../../shared/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { openToast } from 'src/app/utils/common-functions';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';

@Component({
  selector: 'um-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit, OnDestroy {

  resetPasswordEmail: any;
  invalidEmailError: boolean;
  tokenExpired = false;
  inactiveEmailError = false;
  loginUrl = '';
  resetPasswordForm: FormGroup;
  serverError: string;
  disableResendActivationLinkButton: boolean;
  isNotificationError: boolean;
  notificationErrorMsg: string;

  @ViewChild('resetPasswordEmailField') emailField: ElementRef;
  @ViewChild('successToast') successToast: ElementRef;
  @ViewChild('notificationToast') notificationToast: ElementRef;
  @ViewChild('resendActivationLinkButton') resendActivationLinkButton: ElementRef;
  
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(private usersService: UsersService,
    private route: ActivatedRoute,
    private httpLoaderService: HttpLoaderService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar) { }

  ngOnInit() {
    this.createResetPasswordForm();
    this.route.params.subscribe(params => {
      if (params['tokenExpired']) {
        this.tokenExpired = true;
        this.showResetErrorMessage(MESSAGES.PASSWORD_RESET_LINK_EXPIRED);
      }
    });
  }

  ngAfterViewInit() {
    this.formGroupDirective.resetForm();
  }

  createResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      resetPasswordEmail: ['', [Validators.required ,Validators.pattern(/^([a-zA-Z0-9'_\-\+\.]+)@([a-zA-Z0-9_\-\+\.]+)\.([a-zA-Z]{2,50})$/)]],
    });
  }

  // Redirect the user to Studio home page
  cancelForm() {
    this.commonService.redirectToStudio();
  }

  // Display error message on invalid email input
  showResetErrorMessage(errorText) {
    this.invalidEmailError = true;
    this.resetPasswordForm.get('resetPasswordEmail').setErrors({ serverError: true })
    this.serverError = errorText
  }

  /**
   * Redirect to Studio Login
   * @param none
   */
  returnToLogin() {
    this.commonService.redirectToStudio();
  }

  resendActivationLink() {
    const { resetPasswordEmail } = this.resetPasswordForm.value;
    this.usersService.resendActivationLinkByUser(resetPasswordEmail, null).subscribe(data => {
      this.disableResendActivationLinkButton = true;
      
      openToast(this._snackbar, "A new activation email has been sent to \"" + resetPasswordEmail + "\"", STATUS.SUCCESS);

    },
      err => {
        // Hide Overlay-spinner
        this.httpLoaderService.display(false);
        this.isNotificationError = true;
        this.notificationErrorMsg = err.error.message;
      });
  }

  resetUserPassword() {
    const { resetPasswordEmail } = this.resetPasswordForm.value;
    if (this.resetPasswordForm.valid) {
      this.usersService.resetUserPassword(resetPasswordEmail).subscribe(data => {
        // Display error message on inactive email input
        if (data.errorCode === 202) {
          this.inactiveEmailError = true;
          this.showResetErrorMessage(data.error);
          return false;
        }

        // Display account creation notification message
        openToast(this._snackbar, MESSAGES.PASSWORD_RESET_CHECK_EMAIL_FOR_DETAILS, STATUS.SUCCESS);

        //this.notificationToast.nativeElement.close();
        this.isNotificationError = false;
        // Reset input values of form
        this.resetPasswordForm.get('resetPasswordEmail').setValue('');
        this.resetPasswordForm.get('resetPasswordEmail').setErrors(null);
      },
        err => {
          // Hide Overlay-spinner
          this.httpLoaderService.display(false);

          // Display error message on invalid email input
          if (err.error.errorCode === 400) {
            this.showResetErrorMessage(err.error.error);
            return false;
          }
        });
    }
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.resetPasswordForm.controls[controlName].hasError(errorName);
  }

  ngOnDestroy() {
    this.formGroupDirective.resetForm();
  }
}
