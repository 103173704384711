<div class="search-area">
        <div class="search-pane form-inline pl-4 d-flex align-items-center field">
            <mat-form-field inline appearance="standard">
              <label matPrefix>USER SEARCH:</label>
              <input matInput class="col-4 userSearchTerm" #userSearchTerm [(ngModel)]="searchValue" (keyup.enter)="searchByValue(userSearchTerm.value)" #clientSearch>
              <button matSuffix mat-icon-button class="search-btn" (click)="searchByValue(userSearchTerm.value)">
                <mat-icon class="sif sif-search" title="search"></mat-icon>
              </button>
            </mat-form-field>
          </div>
</div>
<div class="um-content">
    <mat-card>
        <div class="no-search-results" #noResultsMsg *ngIf="userList.length == 0 && currentSearchTerm">
            <mat-icon class="sif sif-info-filled"></mat-icon>
            <span class="pl-3">Your search for "<span class="search-value">{{currentSearchTerm}}</span>" did not return any results.</span>
        </div>
        <div class="table-data" infiniteScroll
             infiniteScrollDistance="2"
             [fromRoot]="true"
             [infiniteScrollContainer]="'.overflow-container'"
             (scrolled)="onScroll()">
            <ng-container *ngIf="userList.length > 0" >
                <table class="um-table table table-bordered table-hover table-condensed table-sticky">
                    <thead>
                        <tr>
                            <th style="width: 284px;">User Name</th>
                            <th style="width: 230px;">Role</th>
                            <th style="width: 282px;">Organizations</th>
                            <th style="width: 85px;">Account Enabled</th>
                            <th style="width: 85px;">Account Expired</th>
                            <th style="width: 85px;">Account Locked</th>
                            <th style="width: 92px;">Actions</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let user of userList; let rowIndex = index">
                        <tr class="user-td">
                            <td>{{user.email}}</td>
                            <td>{{user.globalRole | roleName: user.isInternalUser:roleMap}}</td>
                            <td>{{user.organization}}</td>
                            <td class="text-center">
                                {{user.accountEnabled ? 'YES' : 'NO'}}
                            </td>
                            <td class="text-center">
                                {{(user.expirationDate | expiredDate) ? 'YES' : 'NO'}}
                            </td>
                            <td class="text-center">
                                {{user.accountLocked ? 'YES' : 'NO'}}
                            </td>
                            <td class="text-center">
                                <div id="editUserIcon" class="edit-icon" [ngClass]="{'active-edit-icon': rowIndex == selectedIndex}"  #editIcon>    
                                    <mat-icon class="sif sif-edit mr-4" title="edit" (click)="activateEditPane(user, rowIndex)"></mat-icon>
                                    <mat-icon *ngIf="isAdmin" class="sif sif-flag-filled" title="feature flag" (click)="openUserFeatureFlags(user.email)"></mat-icon>
                                </div>
                            </td>
                        </tr>
                        <!-- editUserPane.updatedUserDetails -->
                        <tr class="detailed-container">
                            <td colspan="7">
                                <um-edit-user *ngIf="rowIndex == selectedIndex" [user]="user" [userData]="user" [isAdmin]="isAdmin" [countryList]="countryList" [timezoneList]="timezoneList" [roleMap]="roleMap" [organisationList]="organisationList" [userGroupsList]="userGroupsList" (onEditPaneClose)="closeEditPane($event, 'editCancel', 0)" (onUserUpdate)="saveUserDetails($event)"
                                    #editUserPane></um-edit-user>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </mat-card>
</div>

<ng-template modal="" #closeConfirmationDialog>
    <div class="closeConfirmationDialog">
    <h2 mat-dialog-title>Abandon Changes?</h2>
    <p mat-dialog-content>Any unsaved changes will be lost. Are you sure you want to Continue? </p>
    <div class="buttons" mat-dialog-actions>
        <button mat-button id="highlightButton" class="button-focus mr-3" (click)="goToPrevAction()" tabindex="-1">Cancel</button>
        <button mat-flat-button color="primary" class="m-0" (click)="continueNavDialog()" tabindex="-1">Continue</button>
    </div>
    </div>
</ng-template>
