import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  TemplateRef
} from '@angular/core';
import { GroupsService } from "../../services/groups.service";
import { HttpLoaderService } from "../../../shared/components/http-loader/http-loader.service";
import { Group } from "../../models/group.model";
import { compareArray, trimSpaces } from "../../../../utils/common-functions";
import { MESSAGES } from '../../utils/messages';
import { GROUP_TYPE } from '../../utils/common-variables';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'um-update-groups',
  templateUrl: './update-groups.component.html',
  styleUrls: ['./update-groups.component.scss']
})
export class UpdateGroupsComponent implements OnInit {


  @Input() selectedGroup: Group;
  @Input() currentGroup: Group;
  @Input() updateType: string;
  @Input() userData;
  header: string;
  groupNameRequired: string;
  roleMap: any;
  selectedCategory: string;
  groupsForm: FormGroup;
  filteredCategories: Observable<any>;
  serverError: string;
  dirtyDialogRef: MatDialogRef<any>;
  editGroupNameDialogRef: MatDialogRef<any>;
  categoriesList = GROUP_TYPE;

  /**
   * Group object.
   * @type {Group}
   * @memberof UpdateGroupComponent
   */
  public groupDetails: { group: Group } = {
    'group': new Group()
  };


  @Output() closePanel = new EventEmitter<number>();
  @Output() onEditPaneClose = new EventEmitter<boolean>();
  @Output() continueDialog = new EventEmitter<any>();

  @ViewChild('dirtyFormDialog') dirtyFormDialog: TemplateRef<any>;
  @ViewChild('editGroupNameDialog') editGroupNameDialog: TemplateRef<any>;

  constructor(private groupService: GroupsService, private httpLoaderService: HttpLoaderService, private formBuilder: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {

    this.groupDetails.group = Object.assign({}, this.selectedGroup);
    this.createGroupsForm();
    this.groupsForm.get('groupName').setValue(this.groupDetails.group.groupName);
    this.groupsForm.get('description').setValue(this.groupDetails.group.description);

    switch (this.updateType) {
      case 'create':
        this.header = MESSAGES.CREATE_GROUP_TITLE
        this.selectedCategory = "Internal Users";
        break
      case 'edit':
        this.header = MESSAGES.EDIT_GROUP_TITLE + this.groupDetails.group.groupName
        if (this.groupDetails.group.isInternalGroup == true) {
          this.selectedCategory = "Internal Users"
        } else {
          this.selectedCategory = "All Users"
        }
        break
    }

    this.groupsForm.get('category').setValue({ label: this.selectedCategory, value: this.selectedCategory });
    this.groupNameRequired = MESSAGES.GROUP_NAME_REQUIRED
  }


  createGroupsForm() {
    this.groupsForm = this.formBuilder.group({
      groupName: ['', Validators.maxLength(256)],
      category: [''],
      description: ['', Validators.maxLength(5000)]
    });
  }

  /*
  * Trim spaces in input fields on blur event
  * @param targetElement => target input element
  */
  public inputBlurHandler(evnt, fieldName): void {
    if (evnt.target.value) {
      evnt.target.value = trimSpaces(evnt.target.value);
      this.groupsForm.get(fieldName).setValue(trimSpaces(evnt.target.value));
    }
  }

  /*
* Emit an event to close the panel along with parameters
* @param {any} response
*/
  private closeGroupPanel(response): void {
    this.closePanel.emit(response);
  }

  /**
   * cancel any changes that are made in the form
   */
  cancelChanges() {
    let dirtyFormStatus = this.checkFormStatus();
    if (!dirtyFormStatus) {
      this.onEditPaneClose.emit(dirtyFormStatus);
    }
    else {
      this.closePanel.emit(null);
    }
  }


  /*
* Create new Group on successful form validation
* @param none
*/
  private updateGroup(): void {
    if (this.groupsForm.valid) {
      const { groupName, description } = this.groupsForm.value;
      this.groupDetails.group.groupName = groupName;
      this.groupDetails.group.description = description;
      if (this.updateType == 'create') {
        this.formatCategory(this.groupDetails);
        this.groupService.createGroup(this.groupDetails).subscribe(data => {
          this.closeGroupPanel(data);
        },
          err => {
            this.createGroupErrorHandler(err);
          });

      } else if (this.updateType == 'edit') {

        if (this.currentGroup.groupName != this.groupDetails.group.groupName) {
          this.editGroupNameDialogRef = this.dialog.open(this.editGroupNameDialog, { disableClose: true });
        }
        else {
          this.groupService.updateGroup(this.groupDetails).subscribe(data => {
            this.closeGroupPanel(data);
          },
            err => {
              this.createGroupErrorHandler(err);
            });
        }
      }
    }
  }

  private formatCategory(groupDetails): void {
    const { category } = this.groupsForm.value;
    if (category && category.value) {
      if ("Internal Users" == category.value) {
        groupDetails.group.isInternalGroup = true;
      } else {
        groupDetails.group.isInternalGroup = false;
      }
    } else {
      groupDetails.group.isInternalGroup = true;
    }
  }


  /*
   * Emit an event to continue navigation even if the form is dirty
   * @param none
   */
  continueDirtyNavigation(): void {
    this.continueDialog.emit(true);
    this.dirtyDialogRef.close();
  }


  /**
   * Handle update Group error scenario
   *
   * @param err The error object
   */
  private createGroupErrorHandler(err): void {
    if (err.status && err.status != 401) {
      this.httpLoaderService.display(false);
      this.groupsForm.get('groupName').setErrors({ 'serverError': true });
      this.serverError = err.error.error;
    }
  }

  /**
   * check whether there are any updated changes in the form
   */
  public checkFormStatus(): boolean {
    const { groupName, description } = this.groupsForm.value;
    this.groupDetails.group.groupName = groupName;
    this.groupDetails.group.description = description;
    const dirtyForm = compareArray(this.currentGroup, this.groupDetails.group);
    if (!dirtyForm) {
      this.dirtyDialogRef = this.dialog.open(this.dirtyFormDialog, { disableClose: true });
      return false;
    }
    return dirtyForm;
  }

  public changeSelectedCategory(): void {
    console.log("it works ");
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.groupsForm.controls[controlName].hasError(errorName);
  }
}
