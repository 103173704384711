import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { openToast } from 'src/app/utils/common-functions';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpLoaderService } from 'src/app/modules/shared/components/http-loader/http-loader.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MESSAGES } from 'src/app/modules/groups/utils/messages';

@Component({
  selector: 'app-user-feature-flags',
  templateUrl: './user-feature-flags.component.html',
  styleUrls: ['./user-feature-flags.component.scss']
})
export class UserFeatureFlagsComponent implements OnInit {

  @Input() userEmail: string;
  selectedFlags = [];
  @Input() userFeatureFlags: any = [];
  initialUserFeatureFlags: any;

  dirtyFormDialogRef: MatDialogRef<any>;
  confirmationDialogRef: MatDialogRef<any>;
  @ViewChild('dirtyFormDialog') dirtyFormDialog: TemplateRef<any>;
  @ViewChild('confirmationDialog') confirmationDialog: TemplateRef<any>;

  constructor(private usersService: UsersService, private _snackbar: MatSnackBar, private httpLoaderService: HttpLoaderService, private matDialogRef: MatDialogRef<any>, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.initialUserFeatureFlags = JSON.parse(JSON.stringify(this.userFeatureFlags));
  }

  /*
* Emit an event to close the panel on clicking 'cancel' button
* @param none
*/
  closeUserFlagsModal(): void {
    if (this.selectedFlags.length) {
      this.dirtyFormDialogRef = this.dialog.open(this.dirtyFormDialog, { disableClose: true });
    } else {
      this.matDialogRef.close();
    }
  }


  continueDirtyNavigation(): void {
    this.dirtyFormDialogRef.close();
    this.matDialogRef.close();
  }

  userFeatureFlagChange(flag, index) {
    const { featureName, enabled } = flag;
    const selectedFeatureFlagNames = this.selectedFlags.map(data => data.featureName);
    if (selectedFeatureFlagNames.includes(featureName)) {
      const selectedFlagIndex = selectedFeatureFlagNames.indexOf(featureName);
      if (enabled === this.initialUserFeatureFlags[index].enabled) {
        this.selectedFlags.splice(selectedFlagIndex, 1);
      } else {
        this.selectedFlags[selectedFlagIndex] = { featureName, enabled };
      }
    } else {
      this.selectedFlags.push({ featureName, enabled });
    }
  }

  saveAndClose() {
    if (!this.selectedFlags.length) {
      this.matDialogRef.close();
      return;
    }
    this.confirmationDialogRef = this.dialog.open(this.confirmationDialog, { disableClose: true });
  }

  updateUserLevelFeatureFlags() {
    this.confirmationDialogRef.close();
    const payload = { featurePolicies: this.selectedFlags };
    this.usersService.updateUserLevelFeatureFlags(this.userEmail, payload).subscribe(data => {
      openToast(this._snackbar, this.userEmail + MESSAGES.EDIT_USER_FLAGS, STATUS.SUCCESS);
      this.matDialogRef.close();
    }, (error) => {
      this.httpLoaderService.display(false);
      openToast(this._snackbar, error.error.error, STATUS.ERROR);
    });
  }
}
