<div class="search-area">
    <div class="field org-search">
        <div class="search-pane form-inline p-0 d-flex align-items-center field">
            <mat-form-field inline appearance="standard">
              <label matPrefix>ORGANIZATION SEARCH:</label>
              <input matInput class="col-4 orgSearchTerm" maxlength="256" [(ngModel)]="searchValue" (keyup.enter)="searchByValue(orgSearchTerm.value)" #orgSearchTerm>
              <button matSuffix mat-icon-button class="search-btn" (click)="searchByValue(orgSearchTerm.value)">
                <mat-icon class="sif sif-search" title="search"></mat-icon>
              </button>
            </mat-form-field>
          </div>
    </div>
</div>
<div class="um-content">
    <mat-card>
        <um-create-organization *ngIf="showNewOrg" (closePanel)="closeCreateOrgPanel($event)"></um-create-organization>
        <div class="buttons" *ngIf="!showNewOrg">
            <button mat-flat-button color="secondary" (click)="showNewOrg = true">Create New</button>
        </div>
        <div class="no-search-results" *ngIf="organizationList.length == 0 && currentSearchTerm">
            <mat-icon class="sif sif-info-filled"></mat-icon>
            <span class="pl-3">Your search for "<span class="search-value">{{currentSearchTerm}}</span>" did not return any results.</span>
        </div>
        <div class="table-data">
            <ng-container *ngIf="organizationList.length > 0">
                <table class="um-table table table-bordered table-hover table-condensed table-sticky">
                    <thead>
                        <tr>
                            <th style="width: 60px;">ID</th>
                            <th style="width: 450px;">Organization Name</th>
                            <th style="width: 100px;">Type</th>
                            <th style="width: 650px;">Description</th>
                            <th style="width: 100px;">Actions</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let org of organizationList; let rowIndex = index">
                        <tr class="user-td">
                            <td>{{rowIndex + 1}}</td>
                            <td>{{org.orgName}}</td>
                            <td>{{org.orgType | titlecase}}</td>
                            <td>{{org.description}}</td>
                            <td class="text-center">
                                <span class="edit-icon" [ngClass]="{'active-edit-icon': rowIndex == selectedIndex}" (click)="openEditOrgPane(rowIndex)">
                                    <mat-icon class="sif sif-edit" title="edit"></mat-icon>
                                </span>
                            </td>
                        </tr>
                        <tr class="detailed-container">
                            <td colspan="5">
                                <um-edit-organization *ngIf="rowIndex == selectedIndex" [selectedOrganization]="org" [currentOrganization]="org" (continueDialog)="continueNavigation()" (closePanel)="closeEditOrgPanel($event)"></um-edit-organization>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </mat-card>
</div>
