/* common constant variables used across 'Organization' module */

// Type of Organizations
export const ORGANIZATION_TYPE = [
  {
    "label":"Client",
    "value": "Client"
  },
  {
    "label": "Panel",
    "value": "Panel"
  }
];
