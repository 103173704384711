<div class="header-boundary-bar"></div>
<div class="pageWrapper">
<div class="">
  <um-niq-logo></um-niq-logo>
  <um-welcome-text></um-welcome-text>
</div>
<div class="account-wrapper" [style.height]="errorType === 'invalidUrl' ? '300px' : '400px'">
  <div class="account-container">
    <div class="accountHeader">
      <h3>Welcome to Studio</h3>
    </div>
    <!-- Error when there is 500 response -->
    <ng-container *ngIf="errorType == 'systemError'">
      <ng-container
        *ngTemplateOutlet="
          systemErrorTemplate;
          context: { outletContext: outletContext }
        "
      ></ng-container>
    </ng-container>

    <!-- Error when URL is invalid -->
    <ng-container *ngIf="errorType == 'invalidUrl'">
      <ng-container
        *ngTemplateOutlet="
          invalidUrlTemplate;
          context: { outletContext: outletContext }
        "
      ></ng-container>
    </ng-container>

    <!-- Error when user doesn't have permission to access the page -->
    <ng-container *ngIf="errorType == 'invalidPermission'">
      <ng-container
        *ngTemplateOutlet="
          accessErrorTemplate;
          context: { error: error, outletContext: outletContext }
        "
      >
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Template to be used when URL is invalid -->
<ng-template #invalidUrlTemplate let-outletContext="outletContext">
  <div class="invalid-address-content">
    <error-alert
      message="An invalid URL has been used to access this page. Please reference your email to confirm the correct URL."
    >
    </error-alert>
    <div class="text-center">
      <span class="text-link mt-3" buttonclass (click)="returnToLogin()"
        >Go to Studio Login</span
      >
    </div>
  </div>
</ng-template>

<!-- Template to be used when there the API is not available -->
<ng-template #systemErrorTemplate let-outletContext="outletContext">
  <div class="text-center">
    <div class="error-image">
      <img
        src="../../../../../assets/images/system_error.svg"
        alt="system-error"
      />
    </div>
    <div class="error-content">
      <h1>System Error</h1>
      <p class="info-text">Please come back after some time.</p>
      <p class="text-link mt-3" (click)="returnToLogin()" buttonclass>Go Back</p>
    </div>
  </div>
</ng-template>

<!-- Template to be used when user doesn't have permission to access the page -->
<ng-template
  #accessErrorTemplate
  let-error="error"
  let-outletContext="outletContext"
>
  <div class="text-center">
    <div class="error-image">
      <img
        src="../../../../../assets/images/forbidden.svg"
        alt="access-error"
      />
    </div>
    <div class="error-content">
      <h1 *ngIf="error == 'accessForbidden'">Access Forbidden</h1>
      <h1 *ngIf="error == 'accessDenied'">Access Denied</h1>
      <p class="info-text">You don't have permission to view this page.</p>
      <p class="text-link mt-3" (click)="returnToLogin()" buttonclass>Go Back</p>
    </div>
  </div>
</ng-template>
</div>
<div class="footer-boundary-bar"></div>
