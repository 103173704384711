/* 
* 
*/
// angular imports
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleName'
})
export class RoleNamePipe implements PipeTransform {

  transform(role: any, isInternalUser:any, roleMap:any): any {
    if(!role) {
      return '';
    }
    let roleList = [];
    isInternalUser ? roleList = roleMap.nielsenRoles : roleList = roleMap.nonNielsenRoles;
    for(let roleItem in roleList) {
      if(role.toLowerCase() == roleList[roleItem].name.toLowerCase()) {
        return roleList[roleItem].displayName;
      }
    }
  }

}
