<form [formGroup]="editUserForm">
    <div class="detailed-view detailExpandAnim">
        <div class="detailed-header">
            <h5 class="mb-0">Edit - "{{user.email}}"</h5>
        </div>
        <div class="detailed-content">
            <div class="details-pane container-fluid">
                <div class="content-pane row">
                    <div class="field-data">
                        <div class="field col">
                            <um-autocomplete [list]="rolesList" [id]="'editRole'" label="User Role" placeholder="Select Role" [required]="true" ngDefaultControl formControlName="userRole">
                            </um-autocomplete>
                        </div>
                    </div>
                    <div class="field-data">
                        <div class="field col">
                            <um-autocomplete [list]="countryList" [id]="'editCountry'" label="Country" placeholder="Select Country" [required]="true" ngDefaultControl formControlName="country">
                        </um-autocomplete>
                        </div>
                    </div>
                    <div class="field-data">
                        <div class="field col">
                            <um-autocomplete [list]="timezoneList" [id]="'editTimezone'" label="Time Zone" placeholder="Select Time Zone" [required]="true" ngDefaultControl formControlName="timeZone">
                            </um-autocomplete>
                        </div>
                    </div>
                    <div *ngIf="isAdmin" class="field-data">
                        <div class="field col">
                            <mat-form-field appearance="standard">
                                <label class="custom-field-label required">Password</label>
                                <input type="password" class="editUserPassword" aria-label="Password" matInput
                                    autocomplete="off" maxlength="256" formControlName="password">
                                <mat-error
                                    *ngIf="checkError('password', 'pattern') || checkError('password', 'required')">
                                    Valid password is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="field-data">
                        <div class="field col"></div>
                    </div>
                </div>
                <div class="content-pane bottom-pane row">
                    <div class="field-data" *ngIf="isAdmin && user.status =='NEW'">
                        <div class="field col mt-25">
                            <button type="button" mat-flat-button (click)="resendActivationLink()" color="secondary"
                                class="resendActivationLinkButton">Resend Activation</button>
                        </div>
                    </div>
                    <div class="field-data">
                        <div class="field col toggle-field">
                            <label class="toggle-value">Account Locked</label>
                            <div>
                                <mat-slide-toggle class="accountLockToggleButton toggle-mat" color="accent" #editAccountLock
                                    [checked]="user.accountLocked" formControlName="accountLocked"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                    <div class="field-data">
                        <div class="field col">
                            <mat-form-field appearance="standard">
                                <label class="custom-field-label">Expiration Date</label>
                                <input #editDateInputField class="date-picker AccountExpiryDatePicker"
                                    [ngClass]="{'error-input': editUserForm?.controls?.accountExpDate?.value | expiredDate}" readonly matInput
                                    [matDatepicker]="picker" placeholder="mm/dd/yyyy" formControlName="accountExpDate"
                                    (click)="picker.open()">
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="field-data">
                        <div class="field col user-access">
                            <label class="custom-field-label">Archive Access</label>
                            <ng-container
                                *ngIf="user.globalRole == 'ROLE_Panel' || user.globalRole == 'ROLE_Limited_Access'">
                                <mat-checkbox [disabled]="true" class="userArchiveAccess" formControlName="archive"
                                    #userArchiveAccess></mat-checkbox>
                            </ng-container>
                            <ng-container
                                *ngIf="user.globalRole != 'ROLE_Panel' && user.globalRole != 'ROLE_Limited_Access'">
                                <mat-checkbox class="userArchiveAccess" [color]="'accent'" formControlName="archive"
                                    #userArchiveAccess></mat-checkbox>
                            </ng-container>
                        </div>
                    </div>
                    <div class="field-data">
                        <div class="field col user-access">
                            <label class="custom-field-label">Innovation Lab Access</label>
                            <ng-container
                                *ngIf="user.globalRole == 'ROLE_Panel' || user.globalRole == 'ROLE_Limited_Access'">
                                <mat-checkbox [disabled]="true" class="userArchiveAccess" formControlName="innovationLab"
                                    #userinnovationLabAccess></mat-checkbox>
                            </ng-container>
                            <ng-container
                                *ngIf="user.globalRole != 'ROLE_Panel' && user.globalRole != 'ROLE_Limited_Access'">
                                <mat-checkbox class="userArchiveAccess" [color]="'accent'" formControlName="innovationLab"
                                    #userinnovationLabAccess></mat-checkbox>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div *ngIf="permissionsList.organizations" class="content-pane bottom-pane row pb-3">
                    <div class="mt-25">
                        <label class="custom-field-label">Organization Membership</label>
                        <i class="normal-text pr-1">{{user.organization}}</i>
                        <mat-icon class="sif sif-edit edit-panel-icon orgMembershipModalButton"
                            (click)="showOrgModal = true" title="edit"></mat-icon>
                        <org-search *ngIf="showOrgModal" [modalOrigin]="modalOrgMap.editUser" [userEmail]="user.email"
                            [userOrg]="user.organization" [organisationsList]="organisationList"
                            (selectOrgName)="selectOrganization($event)" (hideOrgModal)="showOrgModal = false">
                        </org-search>
                    </div>
                </div>
                <div *ngIf="permissionsList.organizations" class="content-pane bottom-pane row">
                    <div class="mt-25">
                        <label class="custom-field-label">Organization Access</label>
                        <i
                            class="normal-text pr-1">{{userOrgAccessNames && userOrgAccessNames.length > 0 ? userOrgAccessNames : "Select Organization Access"}}</i>
                        <mat-icon class="sif sif-edit edit-panel-icon orgAccessModalButton"
                            (click)="showOrgAccessModal = true" title="edit"></mat-icon>
                        <org-search *ngIf="showOrgAccessModal" [modalOrigin]="modalOrgMap.orgAccess"
                            [isInternalUser]="user.isInternalUser" [userEmail]="user.email"
                            [userOrg]="userOrgAccessData" [organisationsList]="organisationList"
                            (selectOrgAccess)="selectOrganizationAccess($event)"
                            (hideOrgModal)="showOrgAccessModal = false"></org-search>

                    </div>
                </div>
                <div *ngIf="permissionsList.groups" class="content-pane bottom-pane row">
                    <div class="mt-25">
                        <label class="custom-field-label">Groups Management</label>
                        <i
                            class="normal-text pr-1">{{userGroupsList && userGroupsList.length > 0 ? userGroupsList : "Select User Groups"}}</i>
                        <mat-icon class="sif sif-edit edit-panel-icon groupModalButton" (click)="showGroupsModal = true"
                            title="edit"></mat-icon>
                        <group-search *ngIf="showGroupsModal" [modalOrigin]="modalGroupMap.usergroups"
                            [userEmail]="user.email" [isInternalUser]="isInternalUser" [userGroupsList]="userGroupsList"
                            (selectGroups)="selectGroupAccess($event)" (hideGroupModal)="showGroupsModal = false">
                        </group-search>
                    </div>
                </div>
            </div>
        </div>
        <div class="detailed-footer d-flex justify-content-end">
            <div class="text-right">
                <div class="d-inline-block">
                    <button mat-button class="viewUserCancelButton" (click)="cancelChanges()"
                        color="secondary">Cancel</button>
                </div>
                <div class="d-inline-block">
                    <button mat-flat-button class="m-0 viewUserSubmitButton" (click)="saveEditedUser()"
                        color="primary">Save & Close</button>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Update Organization Access Dialog -->
<ng-template modal="" #updateOrgAccessDialog>
    <div class="updateOrgAccessDialog">
        <h2 mat-dialog-title class="text-center">Organization Access</h2>
        <p mat-dialog-content>User's organization access has been updated to match new membership.</p>
        <div class="buttons" mat-dialog-actions>
            <button mat-flat-button color="primary" class="m-0 updateOrgAccessDialogOkBtn"
                (click)="updateOrgAccessDialogRef.close()">OK</button>
        </div>
    </div>
</ng-template>