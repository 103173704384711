import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {GROUPS_API} from "../utils/groups-api-list";
import {AppConfigService} from "../../core/config/app-config.service";
import {GET_GROUPS} from "../../users/utils/api-list";

@Injectable()
export class GroupsService {

  constructor (
    public httpClient: HttpClient,
    public appConfigService: AppConfigService) {
  }


  /**
   * Get list of Groups
   * @param none => gets the entire list of Groups
   * @param searchTerm => gets list of Groups with search term
   * */
  getGroups() {
    let getGroupsAPI = `${this.appConfigService.config.gandalf.url}/${GET_GROUPS}`;
    return this.httpClient.get<any>(getGroupsAPI)
  }

  /**
   *  Get list of group members for a given group
   * @param groupName
   */

  getGroupMembersByName(groupName){
    let groupParams = new HttpParams();
    groupParams = groupParams.append('groupName',encodeURIComponent(groupName))
    let getGroupsAPI = `${this.appConfigService.config.gandalf.url}/${GET_GROUPS}/members`;
    return this.httpClient.get<any>(getGroupsAPI,{params: groupParams})

  }

  /**
   * create a new Group with given info
   * @param groupInfo
   */
  createGroup(groupInfo) {
    return this.httpClient.post<any>(`${this.appConfigService.config.gandalf.url}/${GROUPS_API}`, groupInfo)
  }

  /**
   *  update Group with given info
   * @param groupInfo
   */
  updateGroup(groupInfo){
    return this.httpClient.put<any>(`${this.appConfigService.config.gandalf.url}/${GROUPS_API}`, groupInfo)

  }

  /**
   * Add/Remove Group members from a given group
   * @param groupName
   * @param selectedUsers
   */

  updateGroupMembers(groupParams) {

    return this.httpClient.put<any>(`${this.appConfigService.config.gandalf.url}/${GET_GROUPS}/members`,groupParams)

  }

}
