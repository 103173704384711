import { Permission } from './permission.model';

/**
 * UserInfo entity contains the user's information and list of Permissions.
 * 
 * @export
 * @interface UserInfo
 */
export class UserInfo {

  /**
   * Details of current logged-in user.
   * @type      {string}
   * @memberof  UserInfo
   */
  user: Array<any>;

  /**
   * List of administrative permissions of user.
   * @type      {any}
   * @memberof  UserInfo
   */
  permissions: {administration: Permission};

}