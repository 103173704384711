import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { NgModel, FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { UsersService } from "../../services/users.service";
import { User } from "../../models/user";
import { UserRequest } from "../../models/userRequest";
import { CommonService } from "../../../shared/services/common.service";
import { HttpLoaderService } from "../../../shared/components/http-loader/http-loader.service";
import { MESSAGES } from "../../utils/messages";
import { trimSpaces, openToast } from "../../../../utils/common-functions";
import { MatSnackBar } from '@angular/material/snack-bar';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';

@Component({
  selector: 'um-request-account',
  templateUrl: './request-account.component.html',
  styleUrls: ['./request-account.component.scss'],
  providers: [UsersService]
})

export class RequestAccountComponent implements OnInit, AfterViewChecked {

  emailExists: boolean;
  resendSuccess: boolean;
  @Input() redirectPageForActivationLink: string;
  requestAccountForm: FormGroup;
  disableResendActivateButton: boolean;
  serverError: string;
  emailAlreadyExists = MESSAGES.ACCOUNT_EMAIL_ALREADY_EXISTS;
  notificationErrorMsg: string;
  isNotificationError: boolean;
  // User fields from 'userRequest' class
  requestAttributes: { userRequest: UserRequest } = {
    'userRequest': new UserRequest()
  };

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(private usersService: UsersService,
    private commonService: CommonService,
    private httpLoaderService: HttpLoaderService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar) { }

  ngOnInit() {
    this.createRequestAccountForm();
    this.initialize();
  }

  initialize() {
    this.resendSuccess = false;
    if (this.redirectPageForActivationLink == undefined) {
      this.redirectPageForActivationLink = 'inactive';
    } else if (this.redirectPageForActivationLink == 'active' && this.resendSuccess) {
      this.disableResendActivateButton = true;
    }
  }

  createRequestAccountForm() {
    this.requestAccountForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: ['', Validators.pattern(/^([a-zA-Z0-9'_\-\+\.]+)@([a-zA-Z0-9_\-\+\.]+)\.([a-zA-Z]{2,5})$/)],
      telephone: ['', Validators.pattern(/^([0-9\-\+]{2,20})$/)],
      jobTitle: [''],
      aboutUserField: ['']
    });
  }

  ngAfterViewChecked() {
    if (this.redirectPageForActivationLink == 'active' && this.resendSuccess) {
      this.disableResendActivateButton = true;
    }
  }

  // Back to initial email field state after checking existing email scenario
  initialEmailSetup(e) {
    if (this.emailExists) {
      this.requestAccountForm.get('email').setErrors({ required: true });
      this.emailExists = false;
    }
  }

  // Trim spaces on blur in input fields
  inputBlurHandler(evnt, fieldName) {
    if (evnt.target.value) {
      evnt.target.value = trimSpaces(evnt.target.value);
      this.requestAccountForm.get(fieldName).setValue(trimSpaces(evnt.target.value));
    }
  }

  requestUserAccount() {
    if (this.requestAccountForm.valid) {
      this.isNotificationError = false;
      this.accountCreationRequestSubmission();
    } else {
      this.isNotificationError = true;
      this.notificationErrorMsg = MESSAGES.ENTER_REQUIRED_DETAILS;
    }
    // return false;
  }

  accountCreationRequestSubmission() {
    this.requestAttributes.userRequest = this.requestAccountForm.value;
    this.usersService.accountCreationRequestSubmission(this.requestAttributes).subscribe(data => {
      this.requestSubmitted(data, this.requestAccountForm.value.email);
    },
      err => {
        // Hide Overlay-spinner
        this.httpLoaderService.display(false);

        if (err.error.errorCode === 307) {
          this.redirectPageForActivationLink = 'active';
          this.isNotificationError = true;
          this.notificationErrorMsg = MESSAGES.ACCOUNT_EXISTS_NEEDS_TO_BE_ACTIVATED;

          this.initialize();
        }

        if (err.error.errorCode === 400) {
          this.showEmailErrorMessage(err.error.error);
          return false;
        }
      });
  }

  // Display error message on invalid email address
  showEmailErrorMessage(errorText) {
    this.isNotificationError = true;
    this.notificationErrorMsg = errorText;
    this.serverError = errorText;
    this.requestAccountForm.get('email').setErrors({ serverError: true });
  }

  requestSubmitted(data, email) {
    // Display Error Message when email already exists
    if (data.errorCode === 202) {
      this.isNotificationError = true;
      this.notificationErrorMsg = MESSAGES.ACCOUNT_EMAIL_ALREADY_EXISTS;
      this.emailAlreadyExists = MESSAGES.ACCOUNT_EMAIL_ALREADY_EXISTS;
      this.emailExists = true;
      this.requestAccountForm.get('email').setErrors({ emailAlreadyExists: true });
      return false;
    }

    // Display account creation notification message
    openToast(this._snackbar, MESSAGES.ACCOUNT_CREATION_REQUEST_SUCCESFULLY_SENT + email + "\"", STATUS.SUCCESS)
    // Reset input values of form
    this.formGroupDirective.resetForm();
  }

  /**
   * Redirect to Studio Login
   * @param none
   */
  returnToLogin() {
    this.commonService.redirectToStudio();
  }

  /**
   * Redirect to Resend activation page
   * @param none
   */
  resendActivationPage() {
    const { email } = this.requestAccountForm.value;
    this.usersService.resendActivationLinkByUser(email, null).subscribe(data => {
      this.isNotificationError = false;
      this.notificationErrorMsg = '';
      this.resendSuccess = true;
      this.disableResendActivateButton = true;
      openToast(this._snackbar, "A new activation email has been sent to \"" + email + "\"", STATUS.SUCCESS);

    },
      err => {
        // Hide Overlay-spinner
        this.httpLoaderService.display(false);
        this.isNotificationError = true;
        this.notificationErrorMsg = err.error.message;
      });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.requestAccountForm.controls[controlName].hasError(errorName);
  }

}
