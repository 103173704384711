import { TranslateService } from '@ngx-translate/core';

/**
 * Loads translation files for the user locale.
 *
 * @param {TranslateService} translate
 */
export function translationFactory(translate: TranslateService) {
  return () => new Promise<any>((resolve: any, reject: any) => {
    const langToSet = 'en-US';
    translate.setDefaultLang(langToSet);
    translate.use(langToSet).toPromise().then(() => {
      console.log('Translations loaded successfully.');
      resolve();
    }).catch((response: any) => {
      reject('Translations loading failed.');
    });
  });
}