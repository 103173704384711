<ng-template modal #userSearchDialog>
  <div class="userSearchDialog">
  <h2 mat-dialog-title>{{modalTitle}} : {{groupName}}</h2>

  <div class="user-search-content" mat-dialog-content>
    <div *ngIf="searchInput=='edit'" class="search-area search-modal">
      <div class="field">
        <mat-form-field inline appearance="standard">
          <label matPrefix>SEARCH:</label>
          <input matInput class="userSearchTerm" [formControl]="searchControl" maxlength="256" (keyup.enter)="searchByValue()">
          <button matSuffix mat-icon-button class="search-btn" (click)="searchByValue()">
            <mat-icon class="sif sif-search" title="search"></mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div class="search-results-container">
      <div *ngIf="usersList && usersList.length > 0" class="results-container">
        <p *ngIf="searchInput=='view'" class="tiny-text">Members: {{usersList.length}}</p>
        <p *ngIf="searchInput=='edit'" class="tiny-text">Search Results: {{usersList.length}}</p>
        <p *ngIf="searchInput=='edit'" class="tiny-text"> Check users you want to add to this group. Uncheck those you want remove.</p>
        <div *ngIf="searchInput=='edit'" class="users-results-box">
          <div *ngFor="let user of usersList">
            <mat-checkbox
            [checked]="modifiedUsersValues.indexOf(user.label) > -1 ? 'checked' : null"
            (change)="toggleUserAccessSelection($event, user)"
            aria-labelledby="Select User" noink #userAccessList>
            <span [class.text-bold]="userAccessList.checked">{{user.value}}  <{{user.label}}> </span>
             </mat-checkbox>   
        </div>
        </div>
        <div *ngIf="searchInput=='view'" class="users-results-box">
         <div *ngFor="let user of usersList"   value="{{user.value}}" label="{{user.label}}" aria-labelledby="Select User" noink #userAccessList>
          {{user.value}}  <{{user.label}}>
         </div>
        </div>
      </div>
      <div *ngIf="(!usersList || usersList.length == 0)&&searchInput=='view'" class="no-results-container pt-3">


        <h3 class="text-uppercase">No Users assigned to the Group</h3>
        <p class="normal-text">Click on <mat-icon class="sif sif-group" title="group"></mat-icon> to assign users to the group</p>
      </div>
      <div *ngIf="(!usersList || usersList.length == 0)&&searchInput!='view'" class="no-results-container pt-3">
                <span class="no-results-icon">
                  <mat-icon class="sif sif-search"></mat-icon>
              </span>
        <h3 class="text-uppercase">There are no search results</h3>
        <p class="normal-text">Type a name, email or client name in the<br> Search Box to find users to add to this Group
      </div>
  </div>
  </div>
  <div *ngIf="searchInput=='view'" class="buttons" mat-dialog-actions>
    <button  mat-flat-button color="primary"  class="m-0 userSearchOkButton" (click)="closeUserModal($event)">OK</button>
  </div>
  <div *ngIf="searchInput=='edit'" class="buttons" mat-dialog-actions>
    <button  mat-button color="secondary" (click)="closeUserModal(null)" class="userSearchCancelButton">Cancel</button>
    <div class="modal-dropdown-button">
      <button  mat-flat-button color="primary"  class="m-0 userSearchSaveCloseButton" (click)="updateAndSaveMembers('saveandclose')">Save & Close</button>
    </div>
  </div>
  </div>
</ng-template>
