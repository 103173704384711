// angular imports
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  TemplateRef
} from '@angular/core';
import { UsersService } from "../../../users/services/users.service";
import { GroupsService } from "../../../groups/services/groups.service";
import { USERS_MODAL_TITLE_MAP } from "../../../../utils/common-variables";
import { HttpLoaderService } from "../http-loader/http-loader.service";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { openToast } from 'src/app/utils/common-functions';
import { STATUS } from '../error-alert/error-alert.component';

@Component({
  selector: 'user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {

  modalTitle: string;
  usersList: any;

  existingUsersList = []
  selectedUsersData = [];
  modifiedUsersValues = [];
  dialogRef: MatDialogRef<any>;
  searchControl = new FormControl();

  @Input() modalOrigin: string;
  @Input() groupName: string;
  @Input() isInternalGroup: boolean;
  @Input() searchInput: string;
  @Output() hideUserModal = new EventEmitter();
  @Output() closePanel = new EventEmitter<number>();

  @ViewChild('userSearchDialog') userSearchDialog: TemplateRef<any>;

  constructor(private usersService: UsersService,
    private groupsService: GroupsService,
    private httpLoaderService: HttpLoaderService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.modalTitle = USERS_MODAL_TITLE_MAP[this.modalOrigin];
    this.getUsersListByGroup(this.groupName);
  }


  // Get Users list
  formatUsers(usersList) {
    let formattedUsersList = [];
    for (let user in usersList.users) {
      formattedUsersList.push({
        label: usersList.users[user].email,
        value: usersList.users[user].firstName
      });
    }
    return formattedUsersList;
  }


  // Get Users By Group
  getUsersListByGroup(groupName) {
    this.groupsService.getGroupMembersByName(groupName).subscribe(data => {
      this.usersList = [];
      let resultData = data;
      if (resultData && this.searchInput == 'view') {
        this.usersList = this.formatUsers(resultData);
        this.usersList = this.usersList.sort(this.compare);
      }
      else if (resultData && resultData.users.length > 0 && this.searchInput == 'edit') {
        for (let user in resultData.users) {
          this.existingUsersList.push(resultData.users[user].email)
        }
      }
      this.modifiedUsersValues = [...this.existingUsersList];
      this.dialogRef = this.dialog.open(this.userSearchDialog, { width: '450px', panelClass: 'custom-modalbox', disableClose: true });
    },
      err => {
        this.manageMembersErrorHandler(err);
      })
  }

  /**
   * Get list of Users from Answers
   * @param none => gets the entire list of users
   * @param searchTerm => gets list of users with search term
   * */
  getUserList(searchTerm) {
    if (this.isInternalGroup == true) {
      this.usersService.getUsersWithInternalFlag(searchTerm).subscribe(data => {
        this.usersList = this.formatUsers(data);
      });
    } else {
      this.usersService.getUsers(searchTerm).subscribe(data => {
        this.usersList = this.formatUsers(data);
      });
    }
  }


  searchByValue() {
    const searchValue = this.searchControl.value;
    this.getUserList(searchValue);
  }

  /**
   * Close Manage Members Modal
   * @param response
   */
  closeUserModal(response) {
    if (this.searchInput == "view") {
      this.hideUserModal.emit(true);
    }
    else if (this.searchInput == "edit") {
      this.closePanel.emit(response);
    }
    this.dialogRef.close();
  }

  /**
   * Update the list of Members after every change in Manage Members Modal
   * @param event
   */
  toggleUserAccessSelection(event, user) {
    //if a new user is assigned to the group
    if (event.checked && !(this.modifiedUsersValues.indexOf(user.label) > -1)) {
      this.selectedUsersData.push({
        type: 'add',
        emailId: user.label
      })
      this.modifiedUsersValues.push(user.label)
    } else {
      //if existing user is removed from the group
      if (this.modifiedUsersValues.indexOf(user.label) > -1 && !((this.selectedUsersData.map(user => user.emailId)).indexOf(user.label) > -1)) {
        this.selectedUsersData.push({
          type: 'remove',
          emailId: user.label
        })
      }
      else {
        this.selectedUsersData = this.selectedUsersData.filter(selection => selection.emailId != user.label);
      }
      this.modifiedUsersValues.splice(this.modifiedUsersValues.indexOf(user.label), 1)
    }

  }

  /**
   *  Save updated list of Group Members
   * @param type
   */
  updateAndSaveMembers(type) {

    if (this.selectedUsersData && this.selectedUsersData.length <= 0) {
      this.closeUserModal(null)
    }
    let groupParams = { "groupName": this.groupName, "selectedUsers": this.selectedUsersData }
    this.groupsService.updateGroupMembers(groupParams).subscribe(data => {
      this.closeUserModal(data);
    },
      err => {
        this.manageMembersErrorHandler(err);
      });



  }


  /**
   * Handle Create Organization error scenario
   *
   * @param err The error object
   */
  private manageMembersErrorHandler(err): void {
    if (err.status && err.status != 401) {
      this.httpLoaderService.display(false);

    }
  }

  /**
   * show success model along with message given
   * @param message
   */
  showSuccessToast(message) {
    openToast(this._snackBar, message, STATUS.SUCCESS);
  }


  compare(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

}
