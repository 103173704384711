import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from './app-config.model';
import { Injectable } from '@angular/core';


@Injectable()
export class AppConfigService {
  /**
   * Configuration object for the app.
   *
   * @static
   * @type {AppConfig}
   * @memberof AppConfigService
   */
  public config: AppConfig;

  /**
   * Constructor for initializing the service.
   *
   * @param http
   */
  constructor(private http: HttpClient) {}

  /**
   * Loads the config file and returns a void promise.
   *
   * @returns {Promise<void>}
   */
  public load(): Promise<void> {
    const filename = environment.name === 'default' ? `config.json` : `config.${environment.name}.json`;
    const path = `assets/config/${filename}`;
    return new Promise<void>((resolve, reject) => {
      this.http.get<AppConfig>(path).toPromise().then((appConfig: AppConfig) => {
        this.config = appConfig;
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${path}': ${JSON.stringify(response)}`);
      });
    });
  }
}
