import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from './app-layout.component';
import { ViewUsersComponent } from '../users/components/view-users/view-users.component';
import { ListGroupsComponent } from '../groups/components/list-groups/list-groups.component';
import { ViewOrganizationComponent } from '../organizations/components/view-organization/view-organization.component';
import { HeartBeatResolver } from '../shared/services/heart-beat-resolver.service';
import { AccessibilityResolver } from '../core/accessibility/accessibility-resolver.service';
import { ARWEN_PAGE_NAMES } from '../core/accessibility/page-names';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/users',
        pathMatch: 'full'
    },
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: 'users',
                component: ViewUsersComponent,
                data: {
                    "pageName": ARWEN_PAGE_NAMES.usersView
                },
                resolve: {
                    "status": HeartBeatResolver
                },
                canActivate: [AccessibilityResolver],
            },
            {
                path: 'groups',
                component: ListGroupsComponent,
                data: {
                    "pageName": ARWEN_PAGE_NAMES.groupsView
                },
                resolve: {
                    "status": HeartBeatResolver
                },
                canActivate: [AccessibilityResolver],
            },
            {
                path: 'organizations',
                component: ViewOrganizationComponent,
                data: {
                    "pageName": ARWEN_PAGE_NAMES.organizationView
                },
                resolve: {
                    "status": HeartBeatResolver
                },
                canActivate: [AccessibilityResolver],
            },
            {
                path: 'application-management',
                loadChildren: () => import('../application-management/application-management.module').then(m => m.ApplicationManagementModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppLayoutRoutingModule { }
