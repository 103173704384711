<form name="editOrgForm" [formGroup]="orgForm">
<div class="detailed-view detailExpandAnim">
    <div class="detailed-header">
        <h5>Edit {{selectedOrganization.orgType | titlecase}} - "{{selectedOrganization.orgName}}"</h5>
    </div>
    <div class="detailed-content pb-0">
        <div class="details-pane">
                <div class="row content-pane px-2">
                    <div class="field col-3 mw-100 px-3">
                        <mat-form-field appearance="standard">
                            <label class="custom-field-label required">Organization Name</label>
                            <input type="text"
                                   class="editOrgName"
                                   aria-label="Organization Name"
                                   matInput
                                   required
                                   autocomplete="off"
                                   maxlength="256"
                                   (blur)="inputBlurHandler($event, 'orgName')"
                                   (change)="resetOrgNameMessage()"
                                   formControlName="orgName">
                                  <mat-error *ngIf="checkError('orgName', 'required') || checkError('orgName', 'invalidOrgName')">
                                    {{invalidOrgNameMsg}}
                                  </mat-error>
                                  <mat-error *ngIf="checkError('orgName', 'serverError')">
                                    {{orgNameServerError}}
                                  </mat-error>
                          </mat-form-field>
                    </div>
                    <div class="field col-9 mw-100 px-3">
                        <mat-form-field appearance="standard">
                            <label class="custom-field-label">Description</label>
                            <input type="text"
                                  class="editOrgDescription"
                                   aria-label="Description"
                                   matInput
                                   autocomplete="off"
                                   maxlength="256"
                                   (blur)="inputBlurHandler($event, 'description')"
                                   formControlName="description">
                          </mat-form-field>
                    </div>
                </div>
                <!-- Commenting Division section as its not been implemented yet -->
                <!-- <div class="content-pane division-block">
                    <span class="custom-field-label">Organization Divisions</span>
                    <span class="text-link">Add/Edit Divisions</span>
                </div> -->
                <div class="content-pane">
                    <div class="field pt-10 mw-100 w-100">
                        <span class="custom-field-label mb-0">Domain Names</span>
                        <span>Comma separated domain names including extensions. e.g. pepsico.com, pepsico.co.uk, etc.</span>
                        <mat-form-field appearance="standard">
                            <input type="text"
                                    class="editOrgDomain"
                                   aria-label="Domain Names"
                                   matInput
                                   autocomplete="off"
                                   maxlength="2000"
                                   (blur)="inputBlurHandler($event, 'domainNames')"
                                   (change)="resetOrgDomainMessage()"
                                   formControlName="domainNames">
                                   <mat-error *ngIf="checkError('domainNames', 'invalidDomainName')">
                                    {{invalidDomainsMsg}}
                                   </mat-error>
                                   <mat-error *ngIf="checkError('domainNames', 'serverError')">
                                    {{domainNamesServerError}}
                                  </mat-error>  
                          </mat-form-field>
                    </div>
                </div>
        </div>
    </div>
    <div class="detailed-footer">
        <div class="text-right mt-3">
            <div class="d-inline-block">
                <button mat-button color="secondary" class="editOrgCancelButton" (click)="closeOrgPanel()">Cancel</button>
            </div>
            <div class="d-inline-block">
                <button mat-flat-button color="primary" class="m-0 editOrgSubmitButton" (click)="validateForm()">Save & Close</button>
            </div>
        </div>
    </div>
</div>
</form>


<!-- Confirmation dialog upon navigating from a dirty form -->
<ng-template modal #dirtyFormDialog>
    <div class="dirtyFormDialog">
    <h2 mat-dialog-title>Abasndon Changes?</h2>
    <p mat-dialog-conntent>Any unsaved changes will be lost. Are you sure you want to Continue? </p>
    <div class="buttons" mat-dialog-actions>
        <button mat-button class="editOrgCancelDialog" class="button-focus" (click)="dirtyFormDialogRef.close()" tabindex="-1">Cancel</button>
        <button mat-flat-button color="primary" class="m-0 editOrgContinueDialog" (click)="continueDirtyNavigation()" tabindex="-1">Continue</button>
    </div>
    </div>
</ng-template>
