<div class="header-boundary-bar"></div>
<div class="pageWrapper">
<div class="">
  <um-niq-logo></um-niq-logo>
  <um-welcome-text></um-welcome-text>
</div>
<div class="account-wrapper" #accountContainer>
  <div class="account-container">
    <div class="accountHeader">
      <h3>Change Password</h3>
    </div>
    <error-alert class="changePasswordToast" *ngIf="isNotificationError" [message]="notificationErrorMsg"></error-alert>
    <form name="changePasswordForm" (keyup.enter)="changeUserPassword()" [formGroup]="changePasswordForm">
      <div class="field" *ngIf="changePasswordPage">
        <mat-form-field appearance="standard">
          <label class="custom-field-label">Current Password</label>
          <input type="password" class="currentPassword" aria-label="Current Password" matInput required
            autocomplete="off" placeholder="*Enter password" maxlength="256" formControlName="currentPassword">
          <mat-error *ngIf="checkError('currentPassword', 'required')">
            Current password is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field" *ngIf="!changePasswordPage">
        <mat-form-field appearance="standard">
          <label class="custom-field-label"
            [class.disable-color]="changePasswordForm.controls.email.disabled">Email</label>
          <input type="text" class="resetAccountEmail" aria-label="Email" matInput disabled autocomplete="off"
            maxlength="256" formControlName="email">
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field appearance="standard">
          <label class="custom-field-label">New Password</label>
          <input type="password" class="changePasswordNew" aria-label="New Password" matInput required
            autocomplete="off" placeholder="*Enter password" maxlength="256" (input)="passwordChangeHandler()" formControlName="password">
          <mat-error *ngIf="checkError('password', 'required') || checkError('password', 'pattern')">
            Password must meet requirements
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field appearance="standard">
          <label class="custom-field-label">Confirm New Password</label>
          <input type="password" placeholder="*Reenter password" class="changePasswordCnfmPassword" aria-label="Confirm New Password" matInput required
            autocomplete="off" maxlength="256" (input)="passwordChangeHandler()" formControlName="confirmPassword">
          <mat-error *ngIf="checkError('confirmPassword', 'required')">
            Please confirm your password
          </mat-error>
          <mat-error
            *ngIf="checkError('confirmPassword', 'passwordsMustMatch') || checkError('confirmPassword', 'pattern')">
            {{passwordsMustMatchMsg}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field inline-message info-text">
        Passwords must meet the following criteria:
        <ul class="list-style-inside m-0 mt-2 p-0">
          <li>Minimum length of 6 characters</li>
          <li>Must contain at least 1 lowercase letter</li>
          <li>Must contain at least 1 uppercase letter</li>
        </ul>
      </div>
      <button mat-flat-button color="primary" class="mx-0 changePasswordSubmitButton"
        (click)="changeUserPassword()">Update Password</button>
      <button *ngIf="changePasswordPage" mat-button  (click)="cancelForm()" class="changePasswordCancelButton" type="button">Cancel</button>
    </form>
  </div>
</div>
</div>
<div class="footer-boundary-bar"></div>
