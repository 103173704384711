<h2 mat-dialog-title>Control Features Available for "{{userEmail}}"</h2>
<mat-dialog-content>
    <table class="um-table table table-bordered table-hover table-condensed table-sticky">
        <thead appStickyHeader>
            <tr>
                <th class="text-center">Id</th>
                <th style="width: 60%;">Feature</th>
                <th class="text-center">Global Status</th>
                <th class="text-center">User Status</th>
            </tr>
        </thead>
        <tbody *ngFor="let flag of userFeatureFlags; let index = index">
            <tr class="user-td">
                <td class="text-center">{{index + 1}}</td>
                <td>{{flag.featureName}}</td>
                <td class="text-center">
                    <span
                    [ngClass]="flag.enabledForAll ? 'status-active': 'status-not-active'">{{flag.enabledForAll ? 'ON': 'OFF'}}</span>
                </td>
                <td class="text-center">
                    <div class="d-flex justify-content-center align-items-center user-status-container">
                        <mat-slide-toggle *ngIf="flag.isTogglable" class="mr-2" [(ngModel)]="flag.enabled" (change)="userFeatureFlagChange(flag, index)"></mat-slide-toggle>
                        <strong *ngIf="!flag.enabled" class="status-not-active user-status">OFF</strong>
                        <strong *ngIf="flag.enabled" class="status-active user-status">
                            ON
                        </strong>
                    </div>
                   
                </td>
            </tr>
        </tbody>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button tabindex="-1" (click)="closeUserFlagsModal()">Cancel</button>
  <button mat-flat-button color="primary" class="m-0"
            tabindex="-1" (click)="saveAndClose()">Save & Close</button>
</mat-dialog-actions>

<ng-template modal #confirmationDialog>
    <div class="confirmationDialog">
    <h2 mat-dialog-title>Confirm your action.</h2>
    <mat-dialog-content>
        <p>Are you sure you want to update the following flags for "{{userEmail}}" ?</p>

        <div class="container mt-4">
            <ng-container *ngFor="let flag of this.selectedFlags;">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4 text-center">
                        <strong>{{flag.featureName}}</strong>
                    </div>
                    <div class="col-4 text-center">
                        <span
                    [ngClass]="flag.enabled ? 'status-active': 'status-not-active'">{{flag.enabled ? 'ON': 'OFF'}}</span>
                    </div>
                    <div class="col-2"></div>
                </div>
            </ng-container>
        </div>

    </mat-dialog-content>
    <div class="buttons" mat-dialog-actions>
      <button mat-button class="mr-3 updateGroupCancelDialog" (click)="confirmationDialogRef.close()" tabindex="-1">Cancel</button>
      <button mat-flat-button color="primary" class="m-0 updateGroupContinueDialog" (click)="updateUserLevelFeatureFlags()" tabindex="-1">Yes</button>
    </div>
    </div>
  </ng-template>

  <!-- Confirmation dialog upon navigating from a dirty form -->
<ng-template modal #dirtyFormDialog>
    <div class="dirtyFormDialog">
    <h2 mat-dialog-title>Abandon Changes?</h2>
    <mat-dialog-content>
        <p>Any unsaved changes will be lost. Are you sure you want to Continue?</p>
    </mat-dialog-content>
    <div class="buttons" mat-dialog-actions>
        <button mat-button class="editOrgCancelDialog" class="button-focus" (click)="dirtyFormDialogRef.close()" tabindex="-1">Cancel</button>
        <button mat-flat-button color="primary" class="m-0 editFlagContinueDialog" (click)="continueDirtyNavigation()" tabindex="-1">Continue</button>
    </div>
    </div>
</ng-template>