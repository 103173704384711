// angular imports
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// polymer imports
// MISC imports
import { InterceptorModule } from '../interceptor/interceptor.module';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { HealthPageComponent } from "./components/health/health-page.component";
import { UserInfoService } from './services/user-info.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule, AUTOCOMPLETE_OPTION_HEIGHT } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio'
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { translationLoaderFactory } from '../core/i18n/translation.loader';
import { WelcomeTextComponent } from './components/welcome-text/welcome-text.component';
import { NiqLogoComponent } from './components/niq-logo/niq-logo.component';



@NgModule({
  declarations: [
    ErrorPageComponent,
    HealthPageComponent,
    ErrorAlertComponent,
    AutocompleteComponent,
    WelcomeTextComponent,
    NiqLogoComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    InterceptorModule,
    FormsModule,
    MatTabsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    MatSnackBarModule,
    MatMenuModule,
    ScrollingModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translationLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    ErrorAlertComponent,
    AutocompleteComponent,
    WelcomeTextComponent,
    NiqLogoComponent,
    MatTabsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    MatSnackBarModule,
    MatMenuModule,
    ScrollingModule,
    TranslateModule
  ],
  providers: [UserInfoService, [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'never' } },
    { provide: MAT_SELECT_CONFIG, useValue: { disableOptionCentering: true } },
    { provide: AUTOCOMPLETE_OPTION_HEIGHT, useValue: 48 }
  ]],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
