// angular imports
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {trigger, transition, style, animate, state,keyframes} from '@angular/animations';
// rxjs imports
// MISC imports
import { HttpLoaderService } from './http-loader.service';

@Component({
  selector: 'um-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss']
})
export class HttpLoaderComponent implements OnInit {

    showLoader: boolean;

    constructor(private httpLoaderService: HttpLoaderService) {
      this.httpLoaderService.status.subscribe((val: boolean) => {
        this.showLoader = val;
      });
    }

    ngOnInit() {}

}
