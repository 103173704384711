import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../core/config/app-config.service';
import { GLOBAL_FEATURE_FLAGS } from '../../users/utils/api-list'

@Injectable({
  providedIn: 'root'
})
export class ApplicationManagementService {

  constructor(public httpClient: HttpClient, private appConfigService: AppConfigService) { }

  getGlobalFeatureFlags() {
    return this.httpClient.get(`${this.appConfigService.config.gandalf.url}${GLOBAL_FEATURE_FLAGS}`);
  }

  updateGlobalFeatureFlag(payload) {
    return this.httpClient.put(`${this.appConfigService.config.gandalf.url}${GLOBAL_FEATURE_FLAGS}`, payload);
  }
}
