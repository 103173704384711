export const ARWEN_PAGE_NAMES = {
  usersView: "users",
  createAccount: "createAccount",
  editUser: "editUser",
  organizationListView: "organizationListView",
  organizationView: "organizations",
  createOrganization: "createOrganization",
  editOrganization: "editOrganization",
  changePasswordInAdminPage: "changePasswordInAdminPage",
  resetActivationLinkInAdminPage: "resetActivationLinkInAdminPage",
  groupsView: "groups",
  requestAccountView: "requestAccount",
  featureflagView: 'featureflagView'
}
