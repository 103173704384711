// angular imports
import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// MISC imports
import { UsersService } from '../../services/users.service';
import { convertToRegex, trimSpaces, openToast } from '../../../../utils/common-functions';
import { User } from '../../models/user';
import { getKeyValueList } from '../../utils/common-functions';
import { MESSAGES } from '../../utils/messages';
import { CommonService } from '../../../shared/services/common.service';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';

@Component({
  selector: 'um-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
  providers: [UsersService]
})

export class ActivateAccountComponent implements OnInit {
  token: string;
  password: any;
  confirmPassword: any;
  country: string;
  timezone: string;
  licenceAgreement = false;
  agreementError = false;
  userInfo: any;
  activateAccountForm: FormGroup;
  countryList = [];
  timezoneList = [];
  filteredCountries: Observable<any>;
  filteredTimeZone: Observable<any>;
  passwordsMustMatchMsg: string;
  isNotificationError: boolean;
  notificationErrorMsg: string;
  // User fields from 'User' class
  userData = {
    "activateAccount": true,
    "user": new User()
  };

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar) {
    this.userInfo = this.route.snapshot.data['userInfo'];
  }

  ngOnInit() {
    this.createActivateAccountForm();
    this.activateAccountForm.get('email').disable();
    this.token = this.userInfo.token;
    

    this.commonService.getTimezones().subscribe(data => {
      this.timezoneList = getKeyValueList(data.timezone);
      this.activateAccountForm.patchValue(this.userInfo.user.users);
    });
    this.commonService.getCountries().subscribe(data => {
      this.countryList = getKeyValueList(data.countries);
      this.activateAccountForm.patchValue(this.userInfo.user.users);
    });
  }

  createActivateAccountForm() {
    this.activateAccountForm = this.formBuilder.group({
      firstName: [''],
      middleName: [''],
      password: ['', Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])[\S.*?]{6,}$/)],
      lastName: [''],
      email: ['', Validators.pattern(/^([a-zA-Z0-9'_\-\+\.]+)@([a-zA-Z0-9_\-\+\.]+)\.([a-zA-Z]{2,50})$/)],
      confirmPassword: ['', Validators.pattern(/^$/)],
      countryName: [''],
      timeZone: [''],
      isEULA: ['', Validators.requiredTrue]
    });
  }

  // Trim spaces on blur in input fields
  inputBlurHandler(evnt, fieldName) {
    if (evnt.target.value) {
      evnt.target.value = trimSpaces(evnt.target.value);
      this.activateAccountForm.get(fieldName).setValue(trimSpaces(evnt.target.value));
    }
  }

  passwordChangeHandler(e) {
      const {password, confirmPassword} = this.activateAccountForm.value;
      if (!confirmPassword) {
        this.activateAccountForm.get('confirmPassword').setErrors({required: true});
        return false;
      }
      if (password) {
        this.activateAccountForm.get('confirmPassword').setValidators([Validators.required, Validators.pattern(convertToRegex(password))]);
      } else {
        this.activateAccountForm.get('confirmPassword').setErrors({passwordsMustMatch: true});
      }
      this.activateAccountForm.get('confirmPassword').updateValueAndValidity();
      this.passwordsMustMatchMsg = MESSAGES.PASSWORD_MATCH;
  }

  accountActivated() {
    // Display notification message
    openToast(this._snackbar, MESSAGES.ACCOUNT_SUCCESFULLY_CREATED + this.userData.user.email + "\"", STATUS.SUCCESS);
    // Reset input values of form
    this.agreementError = false;
    this.formGroupDirective.resetForm();
  }

  activateUserAccount() {
    this.activateAccountForm.get('isEULA').markAsDirty();
    if (this.activateAccountForm.valid) {
      const {countryName, timeZone} = this.activateAccountForm.value;
      this.isNotificationError = false;
      this.agreementError = false;

      this.userData.user = { ...this.userData.user, ...this.activateAccountForm.getRawValue() };
      this.userData.user.countryName = countryName.value;
      this.userData.user.timeZone = timeZone.value;
      this.userData.user.globalRole = "default";
      
      this.usersService.activateUserAccount(this.token, this.userData).subscribe(data => {
        this.commonService.redirectToStudio();
      });

    } else {
      this.isNotificationError = true;
      this.notificationErrorMsg = MESSAGES.ENTER_REQUIRED_DETAILS;
    }
  }

  public checkError(controlName: string, errorName: string) {
    return this.activateAccountForm.controls[controlName].hasError(errorName);
  }

  public displayFn(autoCompleteObject): string {
    return autoCompleteObject && autoCompleteObject.label;
  }

     /**
   * Redirect to Studio Login
   * @param none
   */
     public returnToLogin() {
      this.commonService.redirectToStudio();
    }
}
