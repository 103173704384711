<h1 class="title">Log Out</h1>
<div class="description">
  <p>Logging out will log you out of all tabs that are open in NielsenIQ Studio. Are you sure you want to log out?</p>
</div>

<form>
  <div class="footer">


    <button mat-buttoncancel class="cancel-button" (click)="close($event)">Cancel</button>
    <button mat-button class="done-button" (click)="submit($event)">Log out</button>

  </div>
</form>
