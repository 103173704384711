import { environment } from '../../environments/environment';
import { DEFAULT_ORGANIZATION } from '../modules/users/utils/common-variables';
import { FormGroup, AbstractControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorAlertComponent } from '../modules/shared/components/error-alert/error-alert.component';

/* Trim spaces at the beginning and end and
 * convert multiple spaces to single space
 * @param value for trimming spaces
 */
export let trimSpaces = function (value) {
	value = value.trim().replace(/\s\s+/g, ' ');
	return value;
};

/* Converts the given parameter to a regex
 * @param value to be converted to regex
 */
export let convertToRegex = function (value) {
	value = value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
	return value;
};

/* Compare two arrays that are in same order
 * @param - objects to be compared
 */
export let compareArray = function (arr1, arr2) {
	return JSON.stringify(arr1) === JSON.stringify(arr2);
};

/* Extract domain from Email
 * @param - email address
 */
export let extractEmailDomain = function (email) {
	let emailDomainValue = email.substring(email.lastIndexOf('@') + 1);
	return emailDomainValue;
};

/* Checks Email Domain is part of selected Organisation's domains
 * @params - User Email, selected Organisations
 * @returns: response object of matched result and various Organization values
 */
export let checkDomainMismatch = function (emailDomain, organizations) {
	let response = {
		result: "noOrgDomain",
		matchedOrgs: [],
		mismatchedOrgs: [],
		noDomainOrgs: [],
		mismatchedAndNoDomainOrgs: []
	};
	emailDomain = emailDomain.toLowerCase();
	organizations.map(org => {
      if (org.domains) {
        let domainNames = org.domains.toLowerCase();
        let domainSeparationRegex = /[ ,]+/;
        let orgDomains = domainNames.split(domainSeparationRegex);
        if (orgDomains.includes(emailDomain) || org.label.toLowerCase() == DEFAULT_ORGANIZATION.toLowerCase() ) {
          response.matchedOrgs.push(org.label);
        } else if (org.label.toLowerCase() != DEFAULT_ORGANIZATION.toLowerCase()) {
          response.mismatchedOrgs.push(org.label);
          response.mismatchedAndNoDomainOrgs.push(org.label);
        }
      } else  if (org.label.toLowerCase() != DEFAULT_ORGANIZATION.toLowerCase()) {
        response.noDomainOrgs.push(org.label);
        response.mismatchedAndNoDomainOrgs.push(org.label);
      }
	});
	if(response.noDomainOrgs.length == 0) {
		response.result = response.mismatchedOrgs.length == 0 ? "domainMatched" : "domainMismatched";
	}
	return response;
}

/**
 * Accepts list of Organization Values and returns their respective Labels and Values
 *
 * @param {Array<any>} orgList List of Organizations
 * @param {Array<any>} valueList List of selected Organization values
 * @returns {Array<any>} resultant Organizations Data
 * @memberof OrgSearchService
 */
export let getUserOrgAcessData = function (orgList, valueList) {
	let userOrgAccess = [];
	if (valueList && valueList.length > 0) {
		for (let orgIndex = 0; orgIndex < orgList.length; orgIndex++) {
			for (let valIndex = 0; valIndex < valueList.length; valIndex++) {
				if (orgList[orgIndex].orgName === valueList[valIndex]) {
					userOrgAccess.push({
						value: orgList[orgIndex].orgName,
						label: orgList[orgIndex].displayName,
						domains: orgList[orgIndex].domainNames
					  })
					break;
				}
			}
			if (valueList.length == userOrgAccess.length) break;
		}
	}
	return userOrgAccess;
}

export let openToast = function(instance: MatSnackBar, message: string , status: string) {
	const componentRef = instance.openFromComponent(ErrorAlertComponent, {
		panelClass: 'panelClass',
		duration: 1500,
		horizontalPosition: "center",
		verticalPosition: 'top'
	  });
	  componentRef.instance.message = message;
	  componentRef.instance.status = status;
}