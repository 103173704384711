/* common constant variables used across 'Groups' module */

// Type of Groups
export const GROUP_TYPE = [
  {
    "label":"Internal Users",
    "value": "Internal Users"
  },
  {
    "label": "All Users",
    "value": "All Users"
  }
];
