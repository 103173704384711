// angular imports
import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { NgModel, FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// MISC imports
import { UsersService } from '../../services/users.service';
import { convertToRegex, openToast } from '../../../../utils/common-functions';
import { User } from '../../models/user';
import { HttpLoaderService } from '../../../shared/components/http-loader/http-loader.service';
import { MESSAGES } from '../../utils/messages';
import { CommonService } from '../../../shared/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';
@Component({
  selector: 'um-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  // User fields from 'User' class
  userResetInfo = new User();
  changePasswordPage: boolean = false;
  changePasswordForm: FormGroup;
  passwordsMustMatchMsg: string;
  isNotificationError: boolean;
  notificationErrorMsg: string;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  @ViewChildren('changePasswordNew, changePasswordCnfmPassword') resetPasswordFields: QueryList<any>;
  @ViewChildren('currentPassword, changePasswordNew, changePasswordCnfmPassword') updatePasswordFields: QueryList<any>;

  constructor(private route: ActivatedRoute,
    private httpLoaderService: HttpLoaderService,
    private usersService: UsersService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar) { }

  ngOnInit() {
    this.createChangePasswordForm();
    const handlerResponse = this.route.snapshot.data['userInfo'];
    this.changePasswordPage = handlerResponse.changePasswordPage;
    if (!this.changePasswordPage) {
      this.userResetInfo.token = handlerResponse.token;
      this.changePasswordForm.get('email').setValue(handlerResponse.email);
      this.changePasswordForm.get('email').disable();
    }
  }

  createChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      email: [''],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])[\S.*?]{6,}$/)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(/^$/)]]
    });
  }

  /**
   * Redirect to Studio Login Page
   * @param none
   */
  cancelForm() {
    this.formGroupDirective.resetForm();
    this.commonService.redirectToStudio();
  }

  passwordChangeHandler() {
    const { password, confirmPassword } = this.changePasswordForm.value;
    if (!confirmPassword) {
      this.changePasswordForm.get('confirmPassword').setErrors({ required: true });
      return false;
    }
    if (password) {
      this.changePasswordForm.get('confirmPassword').setValidators([Validators.required, Validators.pattern(convertToRegex(password))]);
    } else {
      this.changePasswordForm.get('confirmPassword').setErrors({ passwordsMustMatch: true });

    }
    this.changePasswordForm.get('confirmPassword').updateValueAndValidity();
    this.passwordsMustMatchMsg = MESSAGES.PASSWORD_MATCH;
  }

  changePasswordRequestHandler(data) {
    if (data && data.errorCode) {
      this.isNotificationError = true;
      this.notificationErrorMsg = data.error;
      return false;
    }
    this.isNotificationError = false;
    openToast(this._snackbar, 'Password is changed successfully', STATUS.SUCCESS);

    // Reset password values of form
    this.formGroupDirective.resetForm();
    this.commonService.redirectToStudio();
  }

  changeUserPassword() {
    this.userResetInfo = { ...this.userResetInfo, ...this.changePasswordForm.getRawValue() };
    if (this.changePasswordPage) {
      if (this.changePasswordForm.valid) {
        this.usersService.changeUserPassword(this.userResetInfo).subscribe(data => {
          this.changePasswordRequestHandler(data);
        },
          err => {
            // Hide Overlay-spinner
            this.httpLoaderService.display(false);
            if (err.error.errorCode === 422) {
              this.changePasswordRequestHandler(err.error);
            }
          });
      }
    } else {
      if (this.changePasswordForm.get('password').valid && this.changePasswordForm.get('confirmPassword').valid) {
        this.usersService.updateUserPassword(this.userResetInfo.token, this.userResetInfo).subscribe(data => {
          this.changePasswordRequestHandler(data);
        });
      }
    }
  }

  public checkError(controlName: string, errorName: string) {
    return this.changePasswordForm.controls[controlName].hasError(errorName);
  }

   /**
   * Redirect to Studio Login
   * @param none
   */
   public returnToLogin() {
    this.commonService.redirectToStudio();
  }
}
