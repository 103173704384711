// Angular imports
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// MISC imports
import { OrganizationService } from '../../services/organization.service';
import { EditOrganizationComponent } from '../edit-organization/edit-organization.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { openToast } from 'src/app/utils/common-functions';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';

@Component({
  selector: 'um-view-organization',
  templateUrl: './view-organization.component.html',
  styleUrls: ['./view-organization.component.scss'],
  providers: [OrganizationService]
})
export class ViewOrganizationComponent implements OnInit {

  organizationList = [];
  showNewOrg = false;
  selectedIndex = -1;
  currentSelectedIndex = -1;
  searchValue: any;
  prevSearchTerm = '';
  currentSearchTerm = '';

  @ViewChild(EditOrganizationComponent)
  private editOrgComponent: EditOrganizationComponent;

  constructor(private organizationService: OrganizationService, private _snackbar: MatSnackBar) { }

  ngOnInit() {
    this.getOrganizations('');
  }

  /**
  * Get list of Organizations from Answers
  * @param none => gets the entire list of Organizations
  * @param searchTerm => gets list of Organizations with search term
  * */
  getOrganizations(searchTerm) {
    if (searchTerm === '') {
      this.searchValue = '';
      this.prevSearchTerm = '';
    }
    this.organizationService.getOrganizations(searchTerm).subscribe(data => {
      this.organizationList = data.orgs;
      if (searchTerm) this.currentSearchTerm = decodeURIComponent(searchTerm);
      //this.selectedIndex = -1;
      this.resetEditOrgPanel();
    });
  }

  /**
   * Get list of Organizations with search term
   * @param searchTerm => search field value
   * */
  searchByValue(searchTerm) {
    // this.currentSearchTerm = '';
    if (searchTerm !== this.prevSearchTerm) {
      if (searchTerm) {
        this.getOrganizations(encodeURIComponent(searchTerm));
      } else {
        this.getOrganizations('');
      }
    }
    this.prevSearchTerm = searchTerm;
  }

  openEditOrgPane(index) {
    if (this.currentSelectedIndex == -1) {
      this.selectedIndex = index;
    } else if (this.currentSelectedIndex != index) {
      const dirtyEditForm = this.editOrgComponent.checkFormStatus();
      if (dirtyEditForm) {
        this.selectedIndex = index;
      }
    }
    this.currentSelectedIndex = index;
  }

  continueNavigation() {
    this.selectedIndex == this.currentSelectedIndex ? this.resetEditOrgPanel() : this.selectedIndex = this.currentSelectedIndex;
  }

  closeCreateOrgPanel(newOrg) {
    this.showNewOrg = false;
    if (newOrg) {
      openToast(this._snackbar, 'The organization \"' + newOrg.orgName + "\" is created successfully", STATUS.SUCCESS);
      this.getOrganizations('');
    }
  }

  resetEditOrgPanel() {
    this.selectedIndex = -1;
    this.currentSelectedIndex = -1;
  }

  closeEditOrgPanel(updatedOrg) {
    if (updatedOrg) {
      this.getOrganizations('');
    }
    this.resetEditOrgPanel();
  }

}
