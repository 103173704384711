// angular imports
import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

// MISC imports
import { CommonService } from "../../services/common.service";
import { HELP_URL } from "../../../../utils/common-variables";
import { MatDialogRef, DialogPosition, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppConfigService } from "src/app/modules/core/config/app-config.service";
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';

@Component({
  selector: "um-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  @Input() currentUser;
  @Input() pageTitle;
  userInfo: any = {};
  dialogRef: MatDialogRef<any>;
  helpUrl = HELP_URL;
  logoutURL: string;
  homeURL: string;
  userNavigationServiceURL: string;

  @ViewChild("logoutConfirmationDialog")
  logoutConfirmationDialog: TemplateRef<any>;

  constructor(
    private commonService: CommonService,
    private dialog: MatDialog,
    private router: Router,
    public appConfigService: AppConfigService,
    private eleRef: ElementRef,
    private changeDetection: ChangeDetectorRef,
    private matDiag: MatDialog,
  ) {}

  ngOnInit() {
    this.userInfo.fullName =
      this.currentUser.firstName.charAt(0).toUpperCase() + this.currentUser.firstName.slice(1) + " " + this.currentUser.lastName;
      this.userNavigationServiceURL = `${this.appConfigService.config.projectserviceui.url}`;
  }

  ngAfterViewInit() {
    if (this.eleRef) {
      this.logoutURL = this.commonService.logoutUser();
      this.changeDetection.detectChanges();
    }
  }

  /**
   * Redirect to Studio Login Page
   * @param none
   */
  loadStudioHomePage() {
    this.commonService.redirectToStudio();
  }

  navigateToEditProfile() {
    this.router.navigate(["edit"]);
  }

  /**
   * Logs out from the studio app.
   */
  public logout(event): void {
    const panelClass = 'logout-modal';
    const position: DialogPosition = { top: '30px' };
    const data = { logoutURL: this.logoutURL };
    this.matDiag.open(LogoutDialogComponent, { data, position, panelClass, disableClose: true });
  }
}
