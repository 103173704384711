// angular imports
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'licence-text',
  templateUrl: './licence-text.component.html',
  styleUrls: ['./licence-text.component.scss']
})
export class LicenceTextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
