import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModel } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { InterceptorModule } from '../interceptor/interceptor.module';

import { AppLayoutComponent } from './app-layout.component';
import { ExpiredDatePipe } from '../shared/pipes/expired-date/expired-date.pipe';

import { NavBarComponent } from '../shared/components/nav-bar/nav-bar.component';
import { ViewUsersComponent } from './../users/components/view-users/view-users.component';
import { EditUserComponent } from '../users/components/edit-user/edit-user.component';
import { ViewOrganizationComponent } from './../organizations/components/view-organization/view-organization.component';
import { RoleNamePipe } from '../users/pipes/role-name/role-name.pipe';
import { OrgSearchComponent } from '../shared/components/org-search/org-search.component';
import { GroupSearchComponent } from "../shared/components/group-search/group-search.component";
import { UserSearchComponent } from "../shared/components/user-search/user-search.component";
import { CreateAccountComponent } from '../users/components/create-account/create-account.component';
import { CreateOrganizationComponent } from '../organizations/components/create-organization/create-organization.component';
import { EditOrganizationComponent } from '../organizations/components/edit-organization/edit-organization.component';
import { ListGroupsComponent } from "../groups/components/list-groups/list-groups.component";
import { UpdateGroupsComponent } from "../groups/components/update-groups/update-groups.component";
import { OrgSearchService } from '../shared/components/org-search/org-search.service';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { SharedModule } from '../shared/shared.module';
import { AppLayoutRoutingModule } from './app-layout-routing.module';
import { BrandBarModule } from '@basesstudio/ngx-components';
@NgModule({
  declarations: [
    AppLayoutComponent,
    ExpiredDatePipe,
    RoleNamePipe,
    NavBarComponent,
    ViewUsersComponent,
    EditUserComponent,
    ViewOrganizationComponent,
    CreateAccountComponent,
    OrgSearchComponent,
    UserSearchComponent,
    CreateOrganizationComponent,
    EditOrganizationComponent,
    ListGroupsComponent,
    UpdateGroupsComponent,
    GroupSearchComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    InterceptorModule,
    InfiniteScrollModule,
    SharedModule,
    BrandBarModule,
    AppLayoutRoutingModule
  ],
  providers: [OrgSearchService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppLayoutModule { }
