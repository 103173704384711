import { Injectable } from '@angular/core';
import { HttpLoaderService } from '../components/http-loader/http-loader.service';
import { AppConfigService } from '../../core/config/app-config.service';

// MISC imports
import {GANDALF_HEALTH_API, HEART_BEAT, GET_ROLES_MAP, GET_COUNTRIES, GET_TIMEZONES} from "../../../utils/api-list";
import {HttpClient} from "@angular/common/http";
import { BehaviorSubject } from 'rxjs';

/**
 * This service provides common utility functions.
 *
 * @example
 * constructor(private commonService: CommonService) { }
 *
 * @export
 * @class CommonService
 */
@Injectable()
export class CommonService {

  private userAccessSource = new BehaviorSubject<string>(null);
  userAccess$ = this.userAccessSource.asObservable();

  constructor(
    public httpClient: HttpClient,
    private httpLoaderService: HttpLoaderService,
    public appConfigService: AppConfigService){};

   /**
   * The Studio Home Page URL constructed based on environment
   *
   * @param none
   * @returns {string} Studio URL
   * @memberof CommonService
   */
  public getStudioUrl(): string {
    const studioLoginURL = this.appConfigService.config.studio.url;
    return studioLoginURL;
  }

  /**
   * Redirect to Studio Home Page while displaying the overlay-spinner
   *
   * @param none
   * @returns void
   * @memberof CommonService
   */
  public redirectToStudio(): void {
    this.httpLoaderService.display(true);
	  window.location.href = this.getStudioUrl();
  };

  /**
   * Check the health API for Gandalf
   */
  checkGandalfHealth() {
    return this.httpClient.get<any>(`${this.appConfigService.config.gandalf.url}/${GANDALF_HEALTH_API}`)
  }

  /**
   * Logout user session
   * @param none
   */
  logoutUser() {
    return`${this.appConfigService.config.gandalf.url}` + "/logout?targetUrl=" + encodeURIComponent(window.location.href);
  }

  /**
   * check if user session exists
   * @param none
   * */
  checkHeartBeat(noSpinnerCall) {
    if(noSpinnerCall) this.httpLoaderService.noSpinnerCall = true;
    return this.httpClient.get<any>(`${this.appConfigService.config.gandalf.url}/${HEART_BEAT}`);
  }

  /**
   * Get roles of nielsen and non-nielsen users from Gandalf
   * @param none
   * */
  getRolesMap() {
    return this.httpClient.get<any>(`${this.appConfigService.config.gandalf.url}/${GET_ROLES_MAP}`);
  }

  /**
   * Get list of Countries from Answers
   * @param none
   * */
  getCountries() {
    return this.httpClient.get<any>(`${this.appConfigService.config.gandalf.url}/${GET_COUNTRIES}`);
  }

  /**
   * Get list of Timezones from Answers
   * @param none
   * */
  getTimezones() {
    return this.httpClient.get<any>(`${this.appConfigService.config.gandalf.url}/${GET_TIMEZONES}`);
  }

  setUserAccess(userAccess) {
    this.userAccessSource.next(userAccess);
  }
}
