// angular imports
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// MISC imports
import { InterceptorModule } from '../interceptor/interceptor.module';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    InterceptorModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class OrganizationsModule { }

