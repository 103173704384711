<div class="header-boundary-bar"></div>
<div class="pageWrapper">
<div class="">
    <um-niq-logo></um-niq-logo>
    <um-welcome-text></um-welcome-text>
  </div>
<div class="account-wrapper" #accountContainer>
    <div class="account-container">
        <div class="accountHeader">
            <h3>Activate Account</h3>
        </div>
        <div>
           <form name="resendActivationForm" [formGroup]="resendActivationForm">
                <div class="field">
                            <mat-form-field appearance="standard">
                                <label class="custom-field-label ">Email</label>
                                <input type="text"
                                       class="resendActivationEmail"
                                       placeholder="*Enter a confirmable email address"
                                       #resendActivationEmail
                                       aria-label="User Email"
                                       matInput
                                       required
                                       autocomplete="off"
                                       maxlength="256"
                                       formControlName="userEmail">
                                      <div class="mat-error" *ngIf="checkError('userEmail', 'serverError')">
                                        {{serverError}}
                                      </div>
                                      <div class="mat-error" *ngIf="resendActivationForm.controls['userEmail'].hasError('invalidToken')">
                                        This account activation link has expired
                                      </div>
                              </mat-form-field>
                </div>
            <div class="field inline-message info-text">
                 Click the "Resend Activation" button to receive an invite through email .
            </div>
            <button mat-flat-button color="primary" class="mx-0 resendActivationSubmitButton" [disabled]="disableResendButton" (click)="resendActivationLink()" #resendActivationSubmitButton>Resend Activation</button>
          <div class="account-services-section account-locked-section buttons buttonclass">
            <span class="text-link mt-4" (click)="returnToLogin()">Return to Log In</span>
          </div>
        </form>
        </div>
    </div>
</div>
</div>
<div class="footer-boundary-bar"></div>
