// angular imports
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// rxjs imports

@Injectable()
export class HttpLoaderService {

    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public noSpinnerCall : boolean;

    display(value: boolean) {
        this.status.next(value);
    }

}
