/**
 * DEFAULT Environment configuration of Arwen.
 * Note: Currently, default configuration is similar to `Test` configuration.
 *
 *
 * Arwen URL: dlin09s5.dev.affinnova.com
 * Arwen Server: afdlin09.dev.affinnova.com
 * Studio : dwin04s7.dev.affinnova.com
 * Gandalf : dlin09s3.dev.affinnova.com/um
 * Sonic : dlin09s2.dev.affinnova.com
 * Reporting : dlin04s8.dev.affinnova.com (shared with demo)
 * Sync Service : dlin09s4.dev.affinnova.com
 *
 *
 * The file contents for the current environment will overwrite these during build.
 * The build system defaults to the dev environment which uses `environment.ts`, but if you do
 * `ng build` then `environment.ts` will be used instead.
 * The list of which env maps to which file can be found in `.angular-cli.json`.
 *
 */

export const environment = {
  name: 'default',
  production: false
};
