/* converts supplied date to date object and 
* compares with current date to know the validity
*/
// angular imports
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expiredDate'
})
export class ExpiredDatePipe implements PipeTransform {

  transform(value: any): any {
    if(value) {
      let currentDate = new Date().setHours(0,0,0,0).valueOf();
      let givenDate = new Date(value).setHours(0,0,0,0).valueOf();
      return currentDate > givenDate;
    }else {
      return true;
    }
  }

}
