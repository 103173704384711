// angular imports
import { Component, ViewChild, ElementRef, ViewChildren, QueryList, OnInit } from '@angular/core';
import { NgModel, FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';

// MISC imports
import { UsersService } from '../../services/users.service';
import { trimSpaces, openToast } from '../../../../utils/common-functions';
import { User } from '../../models/user';
import { MESSAGES } from '../../utils/messages';
import { CommonService } from '../../../shared/services/common.service';
import { HttpLoaderService } from '../../../shared/components/http-loader/http-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';

@Component({
	selector: 'um-create-account',
	templateUrl: './create-account.component.html',
	styleUrls: ['./create-account.component.scss'],
	providers: [UsersService]
})
export class CreateAccountComponent implements OnInit {

	emailExists: boolean;
	// User fields from 'User' class
	userDetails: { user: User } = {
		'user': new User()
	};
	createAccountForm: FormGroup;
	emailAlreadyExistMsg = MESSAGES.ACCOUNT_EMAIL_ALREADY_EXISTS;
	serverError: string;
	notificationErrorMsg: string;
	isNotificationError: boolean;

	@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

	constructor(private usersService: UsersService,
		private commonService: CommonService,
		private httpLoaderService: HttpLoaderService,
		private formBuilder: FormBuilder,
		private _snackbar: MatSnackBar) { }

	ngOnInit() {
		this.createAccountFormInit();
	}

	createAccountFormInit() {
		this.createAccountForm = this.formBuilder.group({
			firstName: [''],
			middleName: [''],
			lastName: [''],
			email: ['', Validators.pattern(/^([a-zA-Z0-9'_\-\+\.]+)@([a-zA-Z0-9_\-\+\.]+)\.([a-zA-Z]{2,50})$/)]
		});
	}

	// Back to initial email field state after checking existing email scenario
	initialEmailSetup(e) {
		if (this.emailExists) {
			this.createAccountForm.get('email').setErrors({ pattern: true });
			this.emailExists = false;
		}
	}

	// Trim spaces on blur in input fields
	inputBlurHandler(evnt, fieldName) {
		if (evnt.target.value) {
			evnt.target.value = trimSpaces(evnt.target.value);
			this.createAccountForm.get(fieldName).setValue(trimSpaces(evnt.target.value));
		}
	}

	// Redirect the user to Studio home page
	cancelForm() {
		this.formGroupDirective.resetForm();
		this.commonService.redirectToStudio();
	}

	// Display error message on invalid email address
	showEmailErrorMessage(errorText) {
		this.isNotificationError = true;
		this.notificationErrorMsg = MESSAGES.ERROR_BELOW_DETAILS;
		this.createAccountForm.get('email').setErrors({ serverError: true });
		this.serverError = errorText;
	}

	accountCreated(userData) {
		// Display Error Message when email already exists
		if (userData.errorCode === 202) {
			this.isNotificationError = true;
			this.notificationErrorMsg = MESSAGES.ERROR_BELOW_DETAILS;
			this.emailExists = true;
			this.createAccountForm.get('email').setErrors({ emailExist: true });
			return false;
		}

		// Display account creation notification message
		openToast(this._snackbar, MESSAGES.ACCOUNT_SUCCESFULLY_CREATED + userData.users.email + "\"", STATUS.SUCCESS);
		// Reset input values of form
		this.formGroupDirective.resetForm();
	}

	continueAccountSubmission() {
		this.userDetails.user = this.createAccountForm.value;
		this.usersService.createUserAccount(this.userDetails).subscribe(data => {
			this.accountCreated(data);
		},
			err => {
				// Hide Overlay-spinner
				this.httpLoaderService.display(false);

				// Display error message on invalid and multiple Organization email address
				if (err.error.errorCode === 400 || err.error.errorCode === 409) {
					this.showEmailErrorMessage(err.error.error);
					return false;
				}
			});
	}

	createUserAccount() {
		if (this.createAccountForm.valid) {
			this.isNotificationError = false;
			this.continueAccountSubmission();
		} else {
			this.isNotificationError = true;
			this.notificationErrorMsg = MESSAGES.ENTER_REQUIRED_DETAILS;
		}
	}

	public checkError = (controlName: string, errorName: string) => {
		return this.createAccountForm.controls[controlName].hasError(errorName);
	}
/**
   * Redirect to Studio Login
   * @param none
   */
	returnToLogin() {
		this.commonService.redirectToStudio();
	  }
}