import { Component, Input } from '@angular/core';

export enum STATUS {
  SUCCESS = "success",
  ERROR = "error"
}

@Component({
  selector: 'error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss']
})
export class ErrorAlertComponent {

  @Input() message: string;
  @Input() status: string;

  constructor() { }
}
