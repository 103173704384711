<div class="header-boundary-bar"></div>
<div class="pageWrapper">
  <div class="">
    <um-niq-logo></um-niq-logo>
    <um-welcome-text></um-welcome-text>
  </div>
  <div class="account-wrapper lg-wrapper">
    <div class="account-container">
      <div class="accountHeader">
        <h3>Activate Account</h3>
      </div>
      <error-alert class="activateAccountToast" *ngIf="isNotificationError"
        [message]="notificationErrorMsg"></error-alert>
      <form name="activateAccountForm" #activateForm (keyup.enter)="activateUserAccount()"
        [formGroup]="activateAccountForm">
        <div class="top-form-fields">
          <div class="left-form-fields">
            <div class="field">
              <mat-form-field appearance="standard">
                <label class="custom-field-label required">First Name</label>
                <input type="text" class="activateAccountFirstName" aria-label="First Name" matInput required
                  autocomplete="off" maxlength="256" tabindex="1" (blur)="inputBlurHandler($event, 'firstName')"
                  formControlName="firstName">
                <mat-error *ngIf="checkError('firstName', 'required')">
                  First Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field appearance="standard">
                <label class="custom-field-label">Middle Name</label>
                <input type="text" class="activateAccountMiddleName" aria-label="Middle Name" matInput
                  autocomplete="off" maxlength="256" tabindex="3" (blur)="inputBlurHandler($event, 'middleName')"
                  formControlName="middleName">
                <!-- <mat-error *ngIf="checkError('middleName', 'required')">
                                  Middle Name is required
                                </mat-error> -->
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field appearance="standard">
                <label class="custom-field-label required">Last Name</label>
                <input type="text" class="activateAccountLastName" aria-label="Last Name" matInput required
                  autocomplete="off" maxlength="256" tabindex="2" (blur)="inputBlurHandler($event, 'lastName')"
                  formControlName="lastName">
                <mat-error *ngIf="checkError('lastName', 'required')">
                  Last Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field appearance="standard">
                <label class="custom-field-label required"
                  [class.disable-color]="activateAccountForm.controls.email.disabled">Email</label>
                <input type="text" class="activateAccountEmail" aria-label="Email" matInput required autocomplete="off"
                  maxlength="256" tabindex="4" (blur)="inputBlurHandler($event, 'email')" formControlName="email">
                <mat-error *ngIf="checkError('email', 'required') || checkError('email', 'pattern')">
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <um-autocomplete [list]="countryList" [id]="'activateAccountCountry'" label="Country" [required]="true"
                formControlName="countryName" placeholder="Select Country">
              </um-autocomplete>
            </div>
            <div class="field">
              <um-autocomplete [list]="timezoneList" [id]="'activateAccountTimezone'" label="Time Zone"
                [required]="true" formControlName="timeZone" placeholder="Select Time Zone">
              </um-autocomplete>
            </div>


          </div>
          <div class="right-form-fields">
            <div class="field">
              <mat-form-field appearance="standard">
                <label class="custom-field-label required">Password</label>
                <input type="password" class="activateAccountPassword" aria-label="Password" matInput required
                  autocomplete="off" maxlength="256" tabindex="5" (input)="passwordChangeHandler($event)"
                  formControlName="password">
                <mat-error *ngIf="checkError('password', 'required') || checkError('password', 'pattern')">
                  Valid password is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field appearance="standard">
                <label class="custom-field-label required">Confirm Password</label>
                <input type="password" class="activateAccountCnfmPassword" aria-label="Confirm Password" matInput
                  required autocomplete="off" maxlength="256" tabindex="6" (input)="passwordChangeHandler($event)"
                  formControlName="confirmPassword">
                <mat-error *ngIf="checkError('confirmPassword', 'required')">
                  Please confirm your password
                </mat-error>
                <mat-error
                  *ngIf="checkError('confirmPassword', 'passwordsMustMatch') || checkError('confirmPassword', 'pattern')">
                  {{passwordsMustMatchMsg}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="full-form-field password-criteria info-text">
              Passwords must meet the following criteria:
              <ul class="m-0 p-0">
                <li>Minimum length of 6 characters</li>
                <li>Must contain at least 1 lowercase letter</li>
                <li>Must contain at least 1 uppercase letter</li>
              </ul>
            </div>
            <div class="full-form-field licence-text info-text">
              <label required>Terms Of Service</label>
              <div class="licence-content">
                <licence-text></licence-text>
              </div>
            </div>
          </div>
        </div>
        <div class="licence-agreement-field info-text">
          <mat-checkbox class="activateAccountLicenceAgreement" required formControlName="isEULA"
            name="userArchiveAccess" #cb> <span [class.text-bold]="cb.checked">I agree to the terms of
              service</span></mat-checkbox>
          <mat-error *ngIf="checkError('isEULA', 'required') && activateAccountForm.controls.isEULA.dirty"
            class="error-text">
            Please read and confirm that you agree to the Terms of Service
          </mat-error>
        </div>
        <button class="mx-0 activateAccountSubmitButton" mat-flat-button color="primary" (click)="activateUserAccount()"
          #activateAccountSubmitButton buttonclass>Activate</button>
        <div class="account-services-section account-locked-section buttons buttonclass">
          <span class="text-link mt-4 buttonclass" (click)="returnToLogin()">Return to Log In</span>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="footer-boundary-bar"></div>