import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpClient } from '@angular/common/http';

/**
 * Adds multi file ngx-translate support.
 *
 * @param {HttpClient} httpClient
 */
export function translationLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
      { prefix: './assets/i18n/ngx-components/', suffix: '.json' },
  ]);
}