<div class="header-boundary-bar"></div>
<div class="pageWrapper">
<div class="">
  <um-niq-logo></um-niq-logo>
  <um-welcome-text></um-welcome-text>
</div>
<div class="account-wrapper" #accountContainer>
  <div class="account-container">
    <div class="accountHeader">
      <h3>Expired Password</h3>
    </div>
    <error-alert class="changePasswordToast" *ngIf="isNotificationError" [message]="notificationErrorMsg"></error-alert>
    <form name="expiredPasswordForm" #changePasswordForm (keyup.enter)="changeUserPassword()"
      [formGroup]="expiredPasswordForm">
      <div class="field">
        <mat-form-field appearance="standard">
          <label class="custom-field-label required">Current Password</label>
          <input type="password" class="currentPassword" #currentPassword aria-label="Current Password"
            placeholder="*Enter password" matInput required autocomplete="off" maxlength="256"
            formControlName="currentPassword" />
          <mat-error *ngIf="checkError('currentPassword', 'required')">
            Current password is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field appearance="standard">
          <label class="custom-field-label required">New Password</label>
          <input type="password" class="changePasswordNew" #changePasswordNew aria-label="New Password"
            placeholder="*Enter password" matInput required autocomplete="off" maxlength="256"
            (input)="passwordChangeHandler()" formControlName="password" />
          <mat-error *ngIf="checkError('password', 'required') || checkError('password', 'pattern')">
            Password must meet requirements
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field appearance="standard">
          <label class="custom-field-label required">Confirm New Password</label>
          <input type="password" class="changePasswordCnfmPassword" #changePasswordCnfmPassword
            placeholder="*Reenter password" aria-label="Confirm New Password" name="changePasswordCnfmPassword" matInput
            required autocomplete="off" maxlength="256" (input)="passwordChangeHandler()"
            formControlName="confirmPassword" />
          <mat-error *ngIf="checkError('confirmPassword', 'required')">
            Please confirm your password
          </mat-error>
          <mat-error
            *ngIf="checkError('confirmPassword', 'passwordsMustMatch') || checkError('confirmPassword', 'pattern')">
            {{passwordsMustMatchMsg}}
          </mat-error>

        </mat-form-field>
      </div>
      <div class="field inline-message info-text">
        Passwords must meet the following criteria:
        <ul class="list-style-inside m-0 mt-2 p-0">
          <li>Minimum length of 6 characters</li>
          <li>Must contain at least 1 lowercase letter</li>
          <li>Must contain at least 1 uppercase letter</li>
        </ul>
      </div>
      <button mat-flat-button color="primary" class="mx-0 changePasswordSubmitButton"
        (click)="changeUserPassword()">Update Password</button>
      <div class="account-services-section account-locked-section buttons buttonclass">
        <span class="text-link mt-4" (click)="returnToLogin()">Return to Log In</span>
      </div>
    </form>
  </div>
</div>
</div>
<div class="footer-boundary-bar"></div>
