/**
 * accessibility-resolver service is used to check the permission to access Arwen's pages for any
 * logged-in user.
 *
 * This service checks with Gandalf for user's permissions to access pages based on role. If the user
 * has permission to access the requested page, then this route-guard accepts the request and allows
 * user to view the page. If the user doesn't have permission, then it redirects to access-fobidden page.
 */
import { Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';

import { UserInfo } from './user-info.model';
import { AppConfigService } from '../config/app-config.service';
import { UserInfoService } from '../../shared/services/user-info.service';
import { USER_INFORMATION } from '../../../utils/api-list';
import {ARWEN_PAGE_NAMES} from "./page-names";
import { map } from 'rxjs/operators';

@Injectable()
export class AccessibilityResolver implements CanActivate {
  public userInfo = new UserInfo();
  private _userInfo:BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(this.userInfo);
  currentUserInfo = this._userInfo.asObservable();

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private router: Router,
    private userInfoService: UserInfoService) {
    }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const pageName = route.data['pageName'];
    const userDetailsURL = `${this.appConfigService.config.gandalf.url}/${USER_INFORMATION}`;

    this.currentUserInfo.subscribe(data => this.userInfo = data);

    if(this.userInfo && this.userInfo.user && this.userInfo.permissions) {
      const isAccessible = this.userInfo.permissions.administration[pageName];
      if(!isAccessible) this.router.navigateByUrl('access-forbidden');
      return true;
    } else {
      return this.httpClient.get(userDetailsURL)
      .pipe(map((data: UserInfo) => {
        this.userInfo = data;
        this._userInfo.next(this.userInfo);
        this.userInfoService.setUserInfo(this.userInfo);
        const isAccessible = this.userInfo.permissions.administration[pageName];

        //checking default home page access
        if(!isAccessible && pageName==ARWEN_PAGE_NAMES.usersView) {
         const isGroupsAccessible = this.userInfo.permissions.administration[ARWEN_PAGE_NAMES.groupsView];
          this.router.navigateByUrl('groups');
         if(!isGroupsAccessible ){
           const isOrgsAccessible = this.userInfo.permissions.administration[ARWEN_PAGE_NAMES.organizationView];
           this.router.navigateByUrl('organizations');
         }
        }
        else if(!isAccessible) this.router.navigateByUrl('access-forbidden');

        return true;
      }))
    }
  }
}
