<div class="header-boundary-bar"></div>
<div class="pageWrapper">
<div class="">
  <um-niq-logo></um-niq-logo>
  <um-welcome-text></um-welcome-text>
</div>
<div class="account-wrapper" [style.height]="isNotificationError === true ? '560px' : '490px'">
    <div class="account-container">
        <div class="accountHeader">
            <h3>Create Account</h3>
        </div>
        <error-alert class="createAccountToast" *ngIf="isNotificationError" [message]="notificationErrorMsg"></error-alert>
        <form name="createAccountForm" (keyup.enter)="createUserAccount()" [formGroup]="createAccountForm">
            <div class="field">
                <mat-form-field appearance="standard">
                    <label class="custom-field-label">First Name</label>
                    <input type="text"
                          class="createAccountFirstName"
                           aria-label="First Name"
                           placeholder="*Enter your first name"
                           matInput
                           required
                           autocomplete="off"
                           maxlength="256"
                           (blur)="inputBlurHandler($event, 'firstName')"
                           formControlName="firstName">
                          <mat-error *ngIf="checkError('firstName', 'required')">
                            First Name is required
                          </mat-error>
                  </mat-form-field>
            </div>
            <div class="field">
                <mat-form-field appearance="standard">
                    <label class="custom-field-label">Middle Name</label>
                    <input type="text"
                          class="createAccountMiddleName"
                           aria-label="Middle Name"
                           placeholder="Enter a midle name or initial"
                           matInput
                           autocomplete="off"
                           maxlength="256"
                           (blur)="inputBlurHandler($event, 'middleName')"
                           formControlName="middleName">
                          <mat-error *ngIf="checkError('middleName', 'required')">
                            Middle Name is required
                          </mat-error>
                  </mat-form-field>
            </div>
            <div class="field">
                <mat-form-field appearance="standard">
                    <label class="custom-field-label">Last Name</label>
                    <input type="text"
                          class="createAccountLastName"
                           aria-label="Last Name"
                           placeholder="*Enter a last name"
                           matInput
                           required
                           autocomplete="off"
                           maxlength="256"
                           (blur)="inputBlurHandler($event, 'lastName')"
                           formControlName="lastName">
                          <mat-error *ngIf="checkError('lastName', 'required')">
                            Last Name is required
                          </mat-error>
                  </mat-form-field>
            </div>
            <div class="field">
                    <mat-form-field appearance="standard">
                        <label class="custom-field-label">Email</label>
                        <input type="text"
                              class="createAccountEmail"
                               aria-label="Email"
                               placeholder="*Enter a confirmable email address"
                               matInput
                               required
                               autocomplete="off"
                               maxlength="256"
                               (blur)="inputBlurHandler($event, 'email')"
                               (input)="initialEmailSetup($event)"
                               formControlName="email">
                              <mat-error *ngIf="checkError('email', 'required') || checkError('email', 'pattern')">
                                Please enter a valid email address
                              </mat-error>
                              <mat-error *ngIf="checkError('email', 'serverError')">
                                {{serverError}}
                              </mat-error>
                              <mat-error *ngIf="checkError('email', 'emailExist')">
                                {{emailAlreadyExistMsg}}
                              </mat-error>
                      </mat-form-field>
            </div>
            <button mat-flat-button color="primary" class="mx-0 createAccountSubmitButton"
            (click)="createUserAccount()">Create</button>
            <button mat-button  type="button" (click)="cancelForm()" class="createAccountCancelButton">Cancel</button>
        </form>
    </div>
</div>
</div>
<div class="footer-boundary-bar"></div>
