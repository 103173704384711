<mat-form-field appearance="standard" class="autocomplete">
    <label class="custom-field-label" [class.required]="required">{{label}}</label>
    <input type="text"
           #editValue
           class="{{id}}"
           [placeholder]="placeholder"
           matInput
           autocomplete="off"
           [required]="required"
           name="autoComplete"
           (click)="trigger($event)"
           (blur)="onBlur($event)"
           [formControl]="autoCompleteControl"
           [matAutocomplete]="auto">

            <mat-icon class="sif sif-close close" matSuffix (click)="setEmpty()" *ngIf="editValue.value"></mat-icon>
  
     
            <mat-icon class="sif sif-chevron-s dropdown" matSuffix (click)="trigger($event)"></mat-icon>

          <mat-error *ngIf="checkError('required')" class="text-truncate">
           {{errorMessage || label +' '+'is required'}}
          </mat-error> 
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectedOption($event)">
      <mat-option *ngFor="let list of filteredList" [instance]="list" [value]="list" [class.active]="editValue.value === list.label">
        {{list.label}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>