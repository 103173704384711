<div class="header-boundary-bar"></div>
<div class="pageWrapper">
<div class="">
  <um-niq-logo></um-niq-logo>
  <um-welcome-text></um-welcome-text>
</div>
<div class="account-wrapper" [style.height]="isNotificationError === true ?
                                            (redirectPageForActivationLink=='active'? '345px' : '850px') :
                                            redirectPageForActivationLink =='active'? '275px': '785px'">
  <div class="account-container">
    <div class="accountHeader">

      <h3>Request Account</h3>

    <error-alert class="requestAccountToast" *ngIf="isNotificationError" [message]="notificationErrorMsg"></error-alert>
    <form name="requestAccountForm" (keyup.enter)="requestUserAccount()" [formGroup]="requestAccountForm">

      <div *ngIf="redirectPageForActivationLink=='inactive'">
        <div class="field">
          <mat-form-field appearance="standard">
            <label class="custom-field-label">First Name</label>
            <input type="text"
                   class="requestAccountFirstName"
                   placeholder="*Enter your first name"
                   aria-label="First Name"
                   matInput
                   required
                   autocomplete="off"
                   maxlength="256"
                   formControlName="firstName"
                   (blur)="inputBlurHandler($event, 'firstName')">
                  <mat-error *ngIf="checkError('firstName', 'required')">
                    First Name is required
                  </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="standard">
            <label class="custom-field-label">Last Name</label>
            <input type="text"
                  class="requestAccountLastName"
                   placeholder="*Enter a last name"
                   aria-label="Last Name"
                   matInput
                   required
                   autocomplete="off"
                   maxlength="256"
                   formControlName="lastName"
                   (blur)="inputBlurHandler($event, 'lastName')">
                  <mat-error *ngIf="checkError('lastName', 'required')">
                    Last Name is required
                  </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
                    <mat-form-field appearance="standard">
                      <label class="custom-field-label">Email</label>
                      <input type="text"
                             class="requestAccountEmail"
                             placeholder="*Enter a valid email address"
                             aria-label="Email"
                             matInput
                             required
                             autocomplete="off"
                             maxlength="256"
                             formControlName="email"
                             (input)="initialEmailSetup($event)">
                            <mat-error *ngIf="checkError('email', 'required') || checkError('email', 'pattern')">
                              Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="checkError('email', 'serverError')">
                              {{serverError}}
                            </mat-error>
                            <mat-error *ngIf="checkError('email', 'emailAlreadyExists')">
                              {{emailAlreadyExists}}
                            </mat-error>
                    </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="standard">
            <label class="custom-field-label">Phone Number</label>
            <input type="text"
            class="requestAccountTelephone"
                   aria-label="Phone Number"
                   placeholder="Enter a phone number"
                   matInput
                   autocomplete="off"
                   maxlength="20"
                   formControlName="telephone"
                   (blur)="inputBlurHandler($event, 'telephone')">
                  <mat-error *ngIf="checkError('telephone', 'required') || checkError('telephone', 'pattern')">
                    Please enter a valid telephone number
                  </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="standard">
            <label class="custom-field-label">Job Title</label>
            <input type="text"
                   class="requestAccountJobTitle"
                   placeholder="Enter a job title"
                   aria-label="Job Title"
                   matInput
                   autocomplete="off"
                   maxlength="50"
                   formControlName="jobTitle"
                   (blur)="inputBlurHandler($event, 'jobTitle')">
          </mat-form-field>
        </div>
        <div class="field">
          <label class="custom-field-label TUAYNLabel" name="TUAYNLabel"> Tell Us About Your Needs </label>
          <textarea class="requestAccountAboutUserField" label="Tell Us About Your Needs" rows="8" cols="30" placeholder="Tell us about your needs" formControlName="aboutUserField" maxlength="1000" #requestAccountAboutUserField (blur)="inputBlurHandler($event)"></textarea>
        </div>
        <br/>
            <button class="m-0 requestAccountSubmitButton" mat-flat-button color="primary" (click)="requestUserAccount()">Request Account</button>
          <div class="account-services-section account-locked-section buttons buttonclass">
            <span class="text-link mt-4" (click)="returnToLogin()">Return to Log In</span>
          </div>
      </div>
      <div *ngIf="redirectPageForActivationLink=='active'">
        <div class="field inline-message info-text">
          Please press "Resend Activation" below to receive a new link.
        </div>
        <button class="m-0 resendActivationButton" mat-flat-button color="primary"  [disabled]="disableResendActivateButton" #resendActivationButton (click)="resendActivationPage()">RESEND ACTIVATION</button>
          <div class="account-services-section account-locked-section buttons buttonclass">
            <span class="text-link mt-4 buttonclass" (click)="returnToLogin()">Return to Log In</span>
          </div>
      </div>
    </form>
  </div>
</div>
</div>
</div>
<div class="footer-boundary-bar"></div>
