<p class="text-center mb-1"><strong>NielsenIQ Studio User Agreement</strong></p>
<p class="m-0"><em>Last Updated: June 5, 2014</em></p>
<p>
  This User Agreement ("Agreement") sets forth the terms and conditions that
  apply to Your use of the NielsenIQ Studio web-based content development
  application (including any update, revision, enhancement, new feature and/or
  the addition thereto) (the "Service") owned and operated by NielsenIQ, Inc.
  ("NielsenIQ").
</p>

<p>
  PLEASE CAREFULLY READ THIS AGREEMENT. BY REGISTERING TO USE OR USING THE
  SERVICE, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT.
</p>

<p>
  <font face="Times New Roman, serif">
    <font size="3">
      <font color="#060A45">
        <font face="Arial, serif">
          <font size="2"
            >This Agreement is effective between You and NielsenIQ as of the
            date that You accept this Agreement by clicking a box indicating
            Your acceptance.</font
          >
        </font>
      </font>
    </font>
  </font>
</p>

<p
  class="western"
  style="margin-left: 0.5in; margin-top: 0.19in; margin-bottom: 0.19in"
>
  <font color="#060A45">&nbsp;</font>
</p>

<p class="western">
  <font color="#060A45">
    <font face="Tahoma, serif">
      <font size="2">
        <b>1.</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font size="1">
      <b>&nbsp;&nbsp;&nbsp; </b>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>Provision of NielsenIQ Studio Service</b>
      </font>
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <a name="_GoBack"> </a>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>1.1 Provision of Service.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >NielsenIQ grants You a license to use the Service in accordance with
        the Terms of Use located at www.affinnova.com/terms.</font
      >
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>1.2 Third Party Links.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >The Service may include links to third party websites or resources
        ("Resources"). Because NielsenIQ has no control over such Resources,
        NielsenIQ is not responsible for the availability thereof and does not
        endorse and is not responsible or liable for any content, advertising,
        products or other materials on or available from such Resources. YOUR
        USE OF RESOURCES, INCLUDING WITHOUT LIMITATION YOUR USE OF ANY CONTENT,
        INFORMATION, DATA, ADVERTISING, PRODUCTS OR OTHER MATERIALS ON OR
        AVAILABLE THROUGH SUCH RESOURCES, IS AT YOUR OWN RISK AND IS SUBJECT TO
        THE TERMS AND CONDITIONS AND PRIVACY POLICIES APPLICABLE TO SUCH
        RESOURCES. NielsenIQ has the right, at any time and in its sole
        discretion, to block links to the Service through technological or other
        means, without prior notice. In addition, certain features of the
        Service that interoperate with third-party services depend on the
        continuing availability of the relevant application programming
        interface and program for use with the Service. If a third party ceases
        to make available or support its services or the relevant application
        programming interface, NielsenIQ may cease providing such Service
        feature(s) without entitling You to any refund, credit or other
        compensation.</font
      >
    </font>
  </font>
</p>

<p
  class="western"
  style="margin-left: 0.5in; margin-top: 0.19in; margin-bottom: 0.19in"
>
  <font color="#060A45">&nbsp;</font>
</p>

<p class="western">
  <font color="#060A45">
    <font face="Tahoma, serif">
      <font size="2">
        <b>2.</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font size="1">
      <b>&nbsp;&nbsp;&nbsp; </b>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>Use of NielsenIQ Studio Service</b>
      </font>
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>2.1 Use of Service.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >You will use the Service for your internal business purposes only and
        only in accordance with the applicable user documentation for the
        Service and applicable laws, rules or regulations.</font
      >
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>2.2 Access to Accounts.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >"User Account" or "User" means an individual who is authorized by You
        to use the Service and who has been supplied a User identification and
        password by NielsenIQ. Your Account may be used by You only and cannot
        be shared or used by more than one individual. You are responsible for
        maintaining the confidentiality of Your User identifications and
        passwords for the Service. You shall use commercially reasonable efforts
        to prevent unauthorized access to or use of the Service, User names and
        passwords and shall promptly notify NielsenIQ of any such unauthorized
        access or use. You are entirely responsible for any and all activities
        that occur under Your User Account(s), whether or not authorized by You.
        NielsenIQ reserves the right to revoke and/or reassign a User Account in
        its sole discretion, with or without prior notice to You, if You or any
        User violates any part of this Agreement.</font
      >
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>2.3 Your Content.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >You are solely responsible for all Your Content, including without
        limitation the accuracy, quality and legality thereof and the means by
        which the Your Content was acquired. You represent and warrant that you
        have the necessary rights, licenses, consents and permissions to create,
        upload or otherwise use all Your Content. If You elect to share any Your
        Content with third parties through the Service (including but not
        limited to Contributors and Guests), You do so at your own risk and
        NielsenIQ will have no responsibility or liability arising out of the
        disclosure of Your Content to such third parties or the use or misuse of
        Your Content by such third parties.</font
      >
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>2.4 Privacy Policy.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >Any personal information provided by You or Users using the Service is
        subject to NielsenIQ's Privacy Policy, which is incorporated herein by
        reference. Click</font
      >
    </font>
  </font>
  <a href="http://www.affinnova.com/privacy-policy.html">
    <span style="text-transform: uppercase">
      <font color="#060A45">
        <font face="Arial, serif">
          <font size="2">here</font>
        </font>
      </font>
    </span>
  </a>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"> to read NielsenIQ's Privacy Policy.</font>
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>2.5 Restrictions.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >You shall not (a) permit use of or access to the Service by anyone
        other than Users, (b) reproduce, copy, duplicate, frame, mirror,
        distribute, adapt, create derivative works of, publish, translate,
        localize, transmit, port or otherwise modify the Service or any part of
        the Service, (c) encumber, transfer, sell, resell, sublicense, rent,
        lease, time-share, make available or use in any service bureau
        arrangement the Service or any part of the Service (other than Your
        Content), (d) reverse engineer, decompile, disassemble, translate or
        convert any software or computer programs used to access the Service
        (including without limitation for purposes of interoperability, except
        to the extent local law requires the same to be permitted for purposes
        of interoperability, and then in such case only to such extent and only
        without violation of local copyright law); (e) use the Service to store
        or transmit infringing, libelous, defamatory, obscene or otherwise
        unlawful or tortious material or to store or transmit material in
        violation of third-party privacy and other proprietary rights, (f) use
        the Service to upload, store or transmit viruses, worms, time bombs,
        Trojan horses and other harmful or malicious code, files, scripts,
        agents or programs, (g) interfere with or disrupt the integrity or
        performance of the Service or third party data contained therein, (h)
        attempt to gain unauthorized access to the Service or any related
        software, systems or networks, (i) attempt to gain unauthorized access
        to any other NielsenIQ software, systems or networks; or (j) permit any
        third party to engage in any of the acts proscribed in clauses (a)
        through (i).</font
      >
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>2.6 User Code of Conduct.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >In addition to the restrictions on use of the Service described in
        Section 2.5, You will not:</font
      >
    </font>
  </font>
</p>

<p>
  <font face="Times New Roman, serif">
    <font size="3">
      <font color="#060A45">
        <font face="Arial, serif">
          <font size="2"
            >(a)&nbsp;&nbsp;&nbsp;&nbsp;create a false identity or impersonate
            any person;</font
          >
        </font>
      </font>
    </font>
  </font>
</p>

<p>
  <font face="Times New Roman, serif">
    <font size="3">
      <font color="#060A45">
        <font face="Arial, serif">
          <font size="2"
            >(b)&nbsp;&nbsp;&nbsp;&nbsp;use offensive language (including
            vulgar, inciteful, or racist language) or engage in threatening,
            abusive, harassing, rude or otherwise objectionable behavior;</font
          >
        </font>
      </font>
    </font>
  </font>
</p>

<p>
  <font face="Times New Roman, serif">
    <font size="3">
      <font color="#060A45">
        <font face="Arial, serif">
          <font size="2"
            >(c)&nbsp;&nbsp;&nbsp;&nbsp;suggest, illicit or encourage any
            illegal activity;</font
          >
        </font>
      </font>
    </font>
  </font>
</p>

<p>
  <font face="Times New Roman, serif">
    <font size="3">
      <font color="#060A45">
        <font face="Arial, serif">
          <font size="2"
            >(d)&nbsp;&nbsp;&nbsp;&nbsp;use the Service to sell, market,
            distribute or advertise, or facilitate the sale, marketing,
            distribution or advertising of, any goods or services that would
            cause NielsenIQ or You to violate any laws or regulations;</font
          >
        </font>
      </font>
    </font>
  </font>
</p>

<p>
  <font face="Times New Roman, serif">
    <font size="3">
      <font color="#060A45">
        <font face="Arial, serif">
          <font size="2"
            >(e)&nbsp;&nbsp;&nbsp;&nbsp;transmit through the Service any
            material, non-public information about companies or any trade secret
            of any third party without the express authorization to do so;</font
          >
        </font>
      </font>
    </font>
  </font>
</p>

<p>
  <font face="Times New Roman, serif">
    <font size="3">
      <font color="#060A45">
        <font face="Arial, serif">
          <font size="2"
            >(f)&nbsp;&nbsp;&nbsp;&nbsp;damage, disable, overburden, impair or
            institute an attack upon any server used in connection with the
            Service; or</font
          >
        </font>
      </font>
    </font>
  </font>
</p>

<p>
  <font face="Times New Roman, serif">
    <font size="3">
      <font color="#060A45">
        <font face="Arial, serif">
          <font size="2"
            >(g)&nbsp;&nbsp;&nbsp;&nbsp;restrict, inhibit or otherwise interfere
            with any other User's use of the Service.</font
          >
        </font>
      </font>
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>2.7 Suspension of Service; Monitoring.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >If You breaches any provision of this Agreement, NielsenIQ shall have
        the right, in addition to and not in lieu of any other remedies
        available to it, to suspend the Service until such breach is cured by
        You. NielsenIQ reserves the right, but is not obligated, to record,
        monitor, edit or disclose activities occurring through or involving
        Service and/or investigate any allegation that any activity occurring
        through or involving the Service does not conform to this Agreement.
        NielsenIQ undertakes no responsibility to review Your Content. NielsenIQ
        may remove any Content if properly notified that it infringes on
        another's intellectual property rights. NielsenIQ is a provider of an
        interactive computer service within the meaning of the Communications
        Decency Act (Title 47, United States Code, Section 230) and reserves all
        rights granted under such law. You are solely responsible for any
        interaction with other users of the Service. NielsenIQ reserves the
        right but will have no obligation to monitor disputes between You or its
        Users and any other user of the Service.</font
      >
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>2.8 Service Location.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >The Service is controlled and operated by NielsenIQ from the United
        States. You hereby consent to having Your Content transferred to and
        processed and stored in the United States. NielsenIQ may limit the
        availability of the Service, in whole or in part, to any person,
        geographic area or jurisdiction that NielsenIQ chooses, at any time and
        in NielsenIQ's sole discretion.</font
      >
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <br />
  <br />
</p>

<p
  class="western"
  style="margin-left: 0.5in; margin-top: 0.19in; margin-bottom: 0.19in"
>
  <font color="#060A45">&nbsp;</font>
</p>

<p class="western">
  <font color="#060A45">
    <font face="Tahoma, serif">
      <font size="2">
        <b>3.</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font size="1">
      <b>&nbsp;&nbsp;&nbsp; </b>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>Confidential Information</b>
      </font>
    </font>
  </font>
  <font face="Arial, serif">
    <font size="2"
      >means all proprietary and confidential information and materials
      belonging or relating to a party, its affiliates and their business
      affairs, disclosed by or on behalf of a party pursuant to this Agreement
      or in the course of this Agreement. Your Confidential Information includes
      the Content and Inputs. Each party will (a) hold all Confidential
      Information of the other party in confidence, (b) not use Confidential
      Information of the other party except in accordance with this Agreement or
      an Order Form and (c) not disclose Confidential Information of the other
      party to any other person or legal entity, other than to employees or
      contractors of the receiving party and market research panel participants
      who (i) have a need to know such information in order to perform or
      utilize the Services, (ii) have been apprised by the receiving party of
      the confidential nature of such information and (iii) are bound by
      obligations of confidentiality substantially equivalent to those under
      this Agreement. Confidential Information shall not include information
      that (a) is or becomes generally available to the public, (b) is known to
      the receiving party at the time of disclosure, as evidenced by its written
      records, (c)&nbsp;is furnished to the receiving party on a
      non-confidential basis by the disclosing party or by any third party
      having a bona fide right to do so, or (d)&nbsp;is independently developed
      by the receiving party without use of the Confidential Information of the
      disclosing party, as evidenced by its written records. Either party may
      disclose Confidential Information of the other party to the extent
      required by law or court order, provided that such party provides
      reasonable advance written notice to the disclosing party so that the
      disclosing party may contest the disclosure or seek a protective order.
      Upon request by the disclosing party, the receiving party shall promptly
      return to the disclosing party or destroy all Confidential Information of
      the disclosing party in its possession or under its control, except one
      copy may be kept for archival purposes.</font
    >
  </font>
</p>

<p
  class="western"
  style="margin-left: 0.5in; margin-top: 0.19in; margin-bottom: 0.19in"
>
  <font color="#060A45">&nbsp;</font>
</p>

<p class="western">
  <font color="#060A45">
    <font face="Tahoma, serif">
      <font size="2">
        <b>4.</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font size="1">
      <b>&nbsp;&nbsp;&nbsp; </b>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>Intellectual Property</b>
      </font>
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>4.1 Your Content.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >You retain all right, title and interest in and to the Your Content,
        including all related intellectual property rights. No rights are
        granted to NielsenIQ with respect to the Your Content other than as
        expressly set forth herein.</font
      >
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>4.2 NielsenIQ's Rights in the Service.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >NielsenIQ owns all right, title and interest in and to the Service,
        including all copyright, trademark and other related intellectual
        property rights. As between NielsenIQ and You, NielsenIQ's
        methodologies, know how, general industry knowledge, algorithms,
        software, solutions and technologies, whether preexisting or developed
        by NielsenIQ in connection with the Service, this Agreement or an Order
        Form, together with all related intellectual property rights will be and
        remain NielsenIQ's exclusive intellectual property. NielsenIQ does not
        grant to You any license or other right in or to the Service or any
        NielsenIQ intellectual property except as expressly set forth in this
        Agreement and all rights not expressly granted are reserved by
        NielsenIQ.</font
      >
    </font>
  </font>
</p>

<p
  class="western"
  align="JUSTIFY"
  style="margin-top: 0.19in; margin-bottom: 0.17in"
>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2">
        <b>4.3 Service Suggestions.&nbsp;&nbsp;</b>
      </font>
    </font>
  </font>
  <font color="#060A45">
    <font face="Arial, serif">
      <font size="2"
        >If You or any User provides NielsenIQ with any suggestions, enhancement
        requests, recommendations or other feedback relating to the operation of
        the Service (collectively, "Suggestions"), then You grants NielsenIQ and
        its designees a worldwide, non-exclusive, sub-licensable (through
        multiple tiers), assignable, fully paid-up, royalty-free, perpetual,
        irrevocable right to use, reproduce, distribute (through multiple
        tiers), create derivative works of, publicly perform, publicly display,
        digitally perform, make, have made, sell, offer for sale and import such
        Suggestions in any media now known or hereafter developed, including the
        Service, for any purpose whatsoever, commercial or otherwise, without
        compensation to the provider of the Suggestions. All Suggestions will be
        deemed non-confidential and NielsenIQ is not liable for any use or
        disclosure of Suggestions.</font
      >
    </font>
  </font>
</p>
