/**
 * This service has operations to set and retrieve the current userInfo observable.
 *
 * @example
 * constructor(private userInfoService: UserInfoService) { }
 *
 * @export
 * @class UserInfoService
 */

// angular imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs imports
import { BehaviorSubject, Observable } from 'rxjs';

import { UserInfo } from '../../core/accessibility/user-info.model';
import { USER_INFORMATION } from '../../../utils/api-list';
import { AppConfigService } from '../../core/config/app-config.service';
import { USER_ROLE_MAP } from '../../../utils/common-variables';
import { map } from 'rxjs/operators';

@Injectable()
export class UserInfoService {

  public userInfo = new UserInfo();

  private _userDetails = new BehaviorSubject<any>(this.userInfo);
  currentUserDetails = this._userDetails.asObservable();

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService) {}

  /**
   * Updates _userDetails subject.
   *
   * @param { UserInfo } userInfoData Details of Logged-in user
   * @memberof UserInfoService
   */
  setUserInfo(userInfoData: any) {
    this._userDetails.next(userInfoData);
  }

  /**
   * Returns the current UserDetails observable. This method tries to return the UserInfo from already set _userDetails observable.
   * If the UserInfo object is not available in the Subject, then it will be fetched from API.
   *
   * @example
   * const userInfo$ = userInfoService.getUserInfo();
   *
   * @returns {Observable<UserInfo>}
   * @memberof UserInfoService
   */
  getUserInfo(): Observable<any> {
    this.currentUserDetails.subscribe(data => this.userInfo = data);
    if(this.userInfo && this.userInfo.user) {
      return this._userDetails.asObservable();
    }
    else {
      const userDetailsURL = `${this.appConfigService.config.gandalf.url}/${USER_INFORMATION}`;
      return this.httpClient.get(userDetailsURL)
      .pipe(map((data: UserInfo) => {
        this.userInfo = data;
        this.setUserInfo(this.userInfo);
        return data;
      }))
    }
  }

  checkAdminUser(role: string) {
    return role.toLowerCase() == USER_ROLE_MAP.administrator.toLowerCase();
  }

  checkFullAccessUser(role: string) {
    return role.toLowerCase() == USER_ROLE_MAP.administrator.toLowerCase() || role == USER_ROLE_MAP.nielsenFinance.toLowerCase();
  }

}
