<form name="createGroupForm" [formGroup]="groupsForm">
<div class="detailed-view">
  <div class="detailed-header">
    <h5 >{{header}}</h5>
  </div>
  <div class="detailed-content">
    <div class="details-pane">
        <div class="row content-pane px-2">
          <div class="field col-3 mw-100 px-3">
         <mat-form-field appearance="standard">
              <label class="custom-field-label" [class.required]="updateType=='create'">Group Name</label>
              <input type="text"
                     aria-label="Group Name"
                     matInput
                     [required]="updateType=='create'"
                     autocomplete="off"
                     (blur)="inputBlurHandler($event, 'groupName')"
                     formControlName="groupName">
                    <mat-error *ngIf="checkError('groupName', 'required')">
                      Group Name is required
                    </mat-error>
                    <mat-error *ngIf="checkError('groupName', 'serverError')">
                      {{serverError}}
                    </mat-error>
                    <mat-error *ngIf="checkError('groupName', 'maxlength')">
                      Group names cannot exceed 256 characters
                    </mat-error>
            </mat-form-field>
          </div>
          <div class="field col-2 mw-100 px-3">
            <label *ngIf="updateType=='edit'" class="custom-field-label CategoryLabel"> Category </label>
            <label *ngIf="updateType=='edit'" class="assignedCategory" style="font-size: 14px;" > {{selectedCategory}} </label>
            <um-autocomplete *ngIf="updateType=='create'" [list]="categoriesList" [id]="'categories'" label="Category" [required]="true" formControlName="category">
            </um-autocomplete>
          </div>
          <div class="field col-7 mw-100 px-3">
            <mat-form-field appearance="standard">
              <label class="custom-field-label">Description</label>
              <input type="text"
                     aria-label="Description"
                     matInput
                     class="createGroupDescription"
                     autocomplete="off"
                     (blur)="inputBlurHandler($event, 'description')"
                     formControlName="description">
                     <mat-error *ngIf="checkError('description', 'maxlength')">
                      Descriptions are limited to 5000 characters
                    </mat-error>
            </mat-form-field>
          </div>
        </div>
    </div>
  </div>
  <div class="detailed-footer">
    <div class="text-right">
      <div class="d-inline-block">
        <button mat-button color="secondary" *ngIf="updateType=='create'" flat secondary class="createGroupCancelButton" (click)="closeGroupPanel(null)">Cancel</button>
        <button mat-button color="secondary" *ngIf="updateType=='edit'" flat secondary class="editGroupCancelButton" (click)="cancelChanges()">Cancel</button>
      </div>
      <div class="d-inline-block">
        <button mat-flat-button color="primary" *ngIf="updateType=='create'" class="m-0 createGroupSubmitButton" (click)="updateGroup()">Save & Close</button>
        <button mat-flat-button color="primary"*ngIf="updateType=='edit'" class="m-0 updateGroupSubmitButton" (click)="updateGroup()">Save & Close</button>
      </div>
    </div>
  </div>
</div>
</form>

<!-- Confirmation dialog upon navigating from a dirty form -->
<ng-template modal #dirtyFormDialog>
  <div class="dirtyFormDialog">
  <h2 mat-dialog-title>Abandon Changes?</h2>
  <p mat-dialog-content>Any unsaved changes will be lost. Are you sure you want to Continue? </p>
  <div class="buttons" mat-dialog-actions>
    <button mat-button class="button-focus mr-3 updateGroupCancelDialog" (click)="dirtyDialogRef.close()" tabindex="-1">Cancel</button>
    <button mat-flat-button color="primary" class="m-0 updateGroupContinueDialog" (click)="continueDirtyNavigation()" tabindex="-1">Continue</button>
  </div>
  </div>
</ng-template>

<!-- Message dialog upon trying to edit group name -->
<ng-template modal #editGroupNameDialog>
  <div class="editGroupNameDialog">
  <h2 mat-dialog-title>Cannot Change Name</h2>
  <p mat-dialog-content>Group Names cannot be edited once created. If you would like to change the group name, please reach out to Product Support.</p>
  <div class="buttons" mat-dialog-actions>
    <button mat-flat-button color="primary" class="m-0 editGroupNameOKDialog" class="button-focus" autofocus (click)="editGroupNameDialogRef.close()" tabindex="-1">Ok</button>
  </div>
  </div>
</ng-template>
