import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewChecked, AfterViewInit } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { ActivatedRoute } from '@angular/router';
import { HttpLoaderService } from '../../../shared/components/http-loader/http-loader.service';
import { CommonService } from '../../../shared/services/common.service';
import { NIELSEN_CONTACT_US_URL } from '../../../../utils/common-variables';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { openToast } from 'src/app/utils/common-functions';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';


@Component({
  selector: 'um-resend-activation',
  templateUrl: './resend-activation.component.html',
  styleUrls: ['./resend-activation.component.scss']
})
export class ResendActivationComponent implements OnInit {

  studioLoginUrl = '';
  nielsenHelpURL: string;
  activationToken: string;
  resendActivationForm: FormGroup;
  serverError: string;
  disableResendButton: boolean;

  constructor(private route: ActivatedRoute,
    private usersService: UsersService,
    private httpLoaderService: HttpLoaderService,
    private element: ElementRef,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar) { }

  ngOnInit() {
    this.createResendActivationForm();
    const userInfo = this.route.snapshot.data['userInfo'];
    this.activationToken = userInfo.token;
    this.resendActivationForm.patchValue(userInfo);
    this.resendActivationForm.disable();
    this.studioLoginUrl = this.commonService.getStudioUrl();
    this.nielsenHelpURL = NIELSEN_CONTACT_US_URL;
    this.resendActivationForm.get('userEmail').markAsTouched();
    this.resendActivationForm.get('userEmail').setErrors({ invalidToken: true });
  }

  createResendActivationForm() {
    this.resendActivationForm = this.formBuilder.group({
      userEmail: [''],
    });
  }

  resendActivationLink() {
    const { userEmail } = this.resendActivationForm.getRawValue();
    this.usersService.resendActivationLinkByUser(userEmail, this.activationToken).subscribe(data => {
      this.disableResendButton = true;
      openToast(this._snackbar, "A new activation email has been sent to \"" + userEmail + "\"", STATUS.SUCCESS);
    },
      err => {
        // Hide Overlay-spinner
        this.httpLoaderService.display(false);
        this.resendActivationForm.get('userEmail').markAsTouched();
        this.resendActivationForm.get('userEmail').setErrors({ serverError: true });
        this.serverError = err.error.message;
      });
  }

  returnToLogin() {
    this.commonService.redirectToStudio();
  }

  public checkError(controlName: string, errorName: string) {
    return this.resendActivationForm.controls[controlName].hasError(errorName);
  }
}
