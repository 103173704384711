// angular imports
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, ApplicationRef, Input, forwardRef, TemplateRef, HostListener } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// MISC imports
import { EditUserComponent } from '../edit-user/edit-user.component';
import { UsersService } from '../../services/users.service';
import { getKeyValueList } from '../../utils/common-functions';
import { HttpLoaderService } from '../../../shared/components/http-loader/http-loader.service';
import { CommonService } from '../../../shared/services/common.service';
import { GROUP_ROLE_MAP } from '../../../../utils/common-variables';
import { UserInfoService } from "../../../shared/services/user-info.service";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';
import { openToast } from 'src/app/utils/common-functions';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart, ResolveStart, GuardsCheckStart } from '@angular/router';
import { UserFeatureFlagsComponent } from '../user-feature-flags/user-feature-flags.component';
import { ApplicationManagementService } from 'src/app/modules/application-management/services/application-management.service';

@Component({
  selector: 'um-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.scss'],
  providers: [UsersService]
})
export class ViewUsersComponent implements OnInit {

  userList = [];
  countryList = [];
  timezoneList = []
  organisationList = [];
  userGroupsList = [];
  roleMap = {};
  isAdmin: boolean;
  searchValue: any;
  prevSearchTerm = '';
  currentSearchTerm = '';
  selectedIndex = -1;
  currentSelectedIndex = -1;
  selectedUserDetails = {};

  editSearchTerm = '';
  editRouteChange = -1;
  offset: number = 0;
  closeConfirmationDialogRef: MatDialogRef<any>;

  targetRoute: string;
  globalFeaturesData: any;
  @Input() userAccess;

  @Output() onValidNavigation = new EventEmitter<boolean>();

  @ViewChild('closeConfirmationDialog') closeConfirmationDialog: TemplateRef<any>;

  @ViewChild(EditUserComponent)
  private editComponent: EditUserComponent;

  constructor(
    private usersService: UsersService,
    private userInfoService: UserInfoService,
    private applicationRef: ApplicationRef,
    private httpLoaderService: HttpLoaderService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private appManageService: ApplicationManagementService,
    private _snackbar: MatSnackBar) {
  }

  ngOnInit() {
    this.commonService.userAccess$.subscribe((data: any) => {
      if (data) {
        this.isAdmin = data.adminAccess;

        this.getUserList('');

        this.commonService.getCountries().subscribe(data => {
          this.countryList = getKeyValueList(data.countries);
          if (this.userList.length == 0) this.httpLoaderService.display(true);
        });

        this.commonService.getTimezones().subscribe(data => {
          this.timezoneList = getKeyValueList(data.timezone);
          if (this.userList.length == 0) this.httpLoaderService.display(true);
        });

        this.commonService.getRolesMap().subscribe(data => {
          this.roleMap = data;
          if (this.userList.length == 0) this.httpLoaderService.display(true);
        });
        setInterval(() => this.applicationRef.tick(), 100);
      }
    });

    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationStart) {
        if (
          !this.checkUserEditStatus() &&
          val.url !== '/users' &&
          val.url !== '/system-error' &&
          val.url !== '/invalid-url' &&
          val.url !== '/access-forbidden' &&
          val.url !== '/access-denied'
        ) {
          this.targetRoute = val.url;
          this.closeEditPane(this.checkUserEditStatus(), 'routeChange', 100);
          this.router.navigate(['users']);
        } else {
          return;
        }
      }
    })

    if(this.isAdmin) {
        this.usersService.getGlobalFeatureFlags().subscribe((data: any) => {
          if (data && data['features']) {
            this.globalFeaturesData = data.features;
          }
        })
    }
    
  }

  /**
   * Get list of Users from Answers
   * @param none => gets the entire list of users
   * @param searchTerm => gets list of users with search term
   * */
  getUserList(searchTerm) {
    if (searchTerm == '') this.searchValue = '';
    this.usersService.getUsersWithPageIndex(searchTerm, this.offset).subscribe(data => {
      if (data.users.length > 0) {
        data.users.map(user => {
          this.userList.push(user)
        });
        this.selectedUserDetails = {};
        this.selectedIndex = -1;
        if (searchTerm) this.currentSearchTerm = decodeURIComponent(searchTerm);
        this.httpLoaderService.display(false);
      } else {
        if (searchTerm) this.currentSearchTerm = decodeURIComponent(searchTerm);
      }
    });
  }

  /**
   * Get list of Users with search term
   * @param searchTerm => search field value
   * */
  searchByValue(searchTerm) {
    if (this.selectedIndex != -1) {
      if (!this.editComponent.checkFormStatus()) {
        this.editSearchTerm = searchTerm;
        this.closeEditPane(this.editComponent.checkFormStatus(), 'searchUser', searchTerm);
        return false;
      }
    }
    if (searchTerm !== this.prevSearchTerm) {
      this.userList = [];
      this.offset = 0;
      if (searchTerm) {
        this.getUserList(encodeURIComponent(searchTerm));
      } else {
        this.getUserList('');
      }
    }
    this.prevSearchTerm = searchTerm;
    this.selectedIndex = -1;
  }

  checkUserEditStatus() {
    let formStatus = true;
    if (this.selectedIndex !== -1) {
      formStatus = this.editComponent.checkFormStatus()
    }
    return formStatus;
  }

  //loads usersGroups List and organizations list while opening edit panel
  activateEditPane(userDetails, index) {

    if (userDetails.userGroups && userDetails.userGroups.length > 0) {
      this.userGroupsList = userDetails.userGroups
    }
    else {
      this.userGroupsList = []
    }
    let loggedInPermissionsList: any;
    this.userInfoService.getUserInfo().subscribe(data => {
      loggedInPermissionsList = data.permissions.administration
    });

    if (this.isAdmin || loggedInPermissionsList.organizations) {
      if (!(this.organisationList && this.organisationList.length > 0)) {
        this.usersService.getOrganizations().subscribe(data => {
          this.organisationList = data.orgs;
          //waiting for the organizations response and proceed with open edit panel
          this.openEditPane(userDetails, index);

        });
      } else {
        this.openEditPane(userDetails, index);
      }
    }
    else {
      this.organisationList = []
      this.openEditPane(userDetails, index);
    }
  }

  openEditPane(userDetails, index) {
    if (this.selectedIndex == -1) {
      this.selectedUserDetails = userDetails;
      this.selectedIndex = index;
    } else {
      if (this.editComponent.checkFormStatus()) {
        this.selectedUserDetails = userDetails;
        this.selectedIndex = index;
      } else {
        this.closeConfirmationDialogRef && this.closeConfirmationDialogRef.close();
        this.closeConfirmationDialogRef = this.dialog.open(this.closeConfirmationDialog, { disableClose: true });
      }
    }
    this.currentSelectedIndex = index;
  }

  closeEditPane(dirtyFormStatus, source, action) {
    if (source == 'routeChange') {
      this.editRouteChange = action;
    }
    if (dirtyFormStatus) {
      this.selectedUserDetails = {};
      this.selectedIndex = -1;
    } else {
      this.closeConfirmationDialogRef && this.closeConfirmationDialogRef.close();
      this.closeConfirmationDialogRef = this.dialog.open(this.closeConfirmationDialog, { disableClose: true });
    }
  }

  saveUserDetails(details) {
    this.usersService.getUsers(details.email).subscribe(data => {
      encodeURIComponent
      let userKeys = Object.keys(this.userList[this.selectedIndex]);
      for (let userKey of userKeys) {
        this.userList[this.selectedIndex][userKey] = data.users[0][userKey];
      }
      this.selectedUserDetails = {};
      this.selectedIndex = -1;
      openToast(this._snackbar, 'Changes saved successfully!', STATUS.SUCCESS);
    });
  }

  goToPrevAction() {
    this.searchValue = '';
    this.prevSearchTerm = '';
    this.editSearchTerm = '';
    this.editRouteChange = -1;
    this.currentSelectedIndex = this.selectedIndex;
    this.onValidNavigation.emit(true);
    this.closeConfirmationDialogRef.close();

  }

  continueNavDialog() {
    if (this.targetRoute && this.editRouteChange == 100) {
      this.selectedUserDetails = {};
      this.selectedIndex = -1;
      this.router.navigate([this.targetRoute]);
      this.closeConfirmationDialogRef.close();
      return false;
    }
    this.editRouteChange = -1;

    if (this.editSearchTerm) {
      this.selectedUserDetails = {};
      this.selectedIndex = -1;
      this.searchByValue(this.editSearchTerm);
      this.closeConfirmationDialogRef.close();
      return false;
    }
    if (this.selectedIndex == this.currentSelectedIndex) {
      this.selectedUserDetails = {};
      this.selectedIndex = -1;
    } else {
      this.selectedIndex = this.currentSelectedIndex;
    }
    this.closeConfirmationDialogRef.close();
  }

  // When scroll down the screen
  onScroll() {
    this.offset = this.offset + 1;
    this.getUserList(this.searchValue);
  }

  openUserFeatureFlags(email) {
    this.usersService.getUserLevelFeatureFlags(email).subscribe((data: any) => {
      const dialogRef = this.dialog.open(UserFeatureFlagsComponent, { disableClose: true, width: '80%' });
      dialogRef.componentInstance.userEmail = email;
      if (data.featurePolicies) {
        dialogRef.componentInstance.userFeatureFlags = this.mapGlobalFeatureFlagsWithUserLevelFlags(this.globalFeaturesData, data.featurePolicies);
      }
    })
  }

  mapGlobalFeatureFlagsWithUserLevelFlags(globalFeatureFlags, userLevelFeatureFlags) {
    const userFlagIds = userLevelFeatureFlags.map(userFlag => userFlag.featureId);
    let resultFeatureFlag = [];
    globalFeatureFlags.map(globalFlag => {
      if (userFlagIds.includes(globalFlag.id)) {
        globalFlag.enabled = true;
        globalFlag.isTogglable = !globalFlag.enabledForAll;
      } else {
        globalFlag.enabled = globalFlag.enabledForAll;
        if (!globalFlag.enabledForAll) globalFlag.isTogglable = true;
      }
      resultFeatureFlag.push(globalFlag);
    })
    return resultFeatureFlag;
  }
}
