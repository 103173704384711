/* List of 'users' module API paths which are appended to Base URL in http requests
 * Writing these in separate file so that it is easier to update API changes in future
*/

// Reset Password
export const RESET_PASSWORD = "resetPassword/reset";

// Parameter used in Reset Password API
export const RESET_PASSWORD_PARAMETER = "email";

// Update Password for reset password functionality
export const UPDATE_PASSWORD = "resetPassword/resetPassword";

// Change Password for changing the user password functionality
export const CHANGE_PASSWORD = "changePassword";

// Reset Password for expired credential of the user password functionality
export const RESET_EXPIRED_PASSWORD="resetExpiredPassword"

// Get list of organizations
export const GET_ORGANIZATIONS = 'organizations';

//Get list of groups
export const GET_GROUPS = 'user-groups';

// Create Account
export const CREATE_USER_ACCOUNT = 'createUserAccount';

// Activate Account
export const GET_USER_DETAILS = 'activate';

// User Operations
export const USERS_API = 'users';

export const INTERNAL_USER = 'internalUser';

// Search by username
export const SEARCH_USERNAME = 'userName';

// Get/Update Profile details
export const USER_PROFILE_DETAILS = 'userinfo';

//Load invalid URL page
export const INVALID_URL_PAGE = 'invalidUrl';

//Validate reset password token in url
export const VALIDATE_RESET_TOKEN = "resetPassword/validateToken";

// Get user details based on token
export const TOKEN_USER_DETAILS = 'tokenInfo';

export const VALIDATE_TOKEN = "resetPassword/validateToken";

// Get/resend activation triggered by admin
export const RESEND_ACTIVATION_BY_ADMIN = 'resendActivationByAdmin';

// Get/resend activation triggered by user in case of expired activation link
export const RESEND_ACTIVATION_BY_USER = 'resendActivationByUser';

// Request Account link triggered by user
export const REQUEST_CREAT_ACCOUNT = '/requestAccount/requestAccountRequest';

// Request global level feature flags
export const GLOBAL_FEATURE_FLAGS = '/featureFlags/global';

// Request user level feature flags
export const USERS_FEATURE_FLAGS = '/featureFlags/users';