// angular imports
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

// MISC imports
import { ErrorPageComponent } from './components/error-page/error-page.component';
import {HealthPageComponent} from "./components/health/health-page.component";

const ROUTES: Routes = [
  {
    path: 'invalid-url',
    component: ErrorPageComponent,
    data: {
      "errorType": "invalidUrl"
    }
  },
  {
    path: 'system-error',
    component: ErrorPageComponent,
    data: {
      "errorType": "systemError"
    }
  },
  {
    path: 'access-denied',
    component: ErrorPageComponent,
    data: {
      "error": "accessDenied",
      "errorType": "invalidPermission"
    }
  },
  {
    path: 'access-forbidden',
    component: ErrorPageComponent,
    data: {
      "error": "accessForbidden",
      "errorType": "invalidPermission"
    }
  },
  {
    path: 'health',
    component: HealthPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {useHash: true})],
  exports: [RouterModule]
})
export class SharedRoutingModule {
}
