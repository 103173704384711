// angular imports
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// MISC imports
import { UsersService } from '../../services/users.service';
import { convertToRegex, openToast } from '../../../../utils/common-functions';
import { User } from '../../models/user';
import { HttpLoaderService } from '../../../shared/components/http-loader/http-loader.service';
import { MESSAGES } from '../../utils/messages';
import { CommonService } from '../../../shared/services/common.service';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';

@Component({
  selector: 'um-expired-password',
  templateUrl: './expired-password.component.html',
  styleUrls: ['./expired-password.component.scss']
})
export class ExpiredPasswordComponent implements OnInit {

  // User fields from 'User' class
  email: string;
  userResetInfo = new User();
  expiredPasswordForm: FormGroup;
  passwordsMustMatchMsg: string;
  notificationErrorMsg: string;
  isNotificationError: boolean;

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  constructor(private route: ActivatedRoute,
    private httpLoaderService: HttpLoaderService,
    private usersService: UsersService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar) {
  }

  ngOnInit() {
    this.createExpiredPasswordForm();
    const handlerResponse = this.route.snapshot.data['userInfo'];
    this.email = handlerResponse.email;
  }

  createExpiredPasswordForm() {
    this.expiredPasswordForm = this.formBuilder.group({
      currentPassword: [''],
      password: ['', Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])[\S.*?]{6,}$/)],
      confirmPassword: ['', Validators.pattern(/^$/)]
    });
  }

  /**
   * Redirect to Studio Login Page
   * @param none
   */
  cancelForm() {
    this.commonService.redirectToStudio();
  }

  passwordChangeHandler() {
    const { confirmPassword, password } = this.expiredPasswordForm.getRawValue();
    if (!confirmPassword) {
      this.expiredPasswordForm.get('confirmPassword').setErrors({ required: true });
      return false;
    }
    if (password) {
      this.expiredPasswordForm.get('confirmPassword').setValidators([Validators.required, Validators.pattern(convertToRegex(password))]);
    } else {
      this.expiredPasswordForm.get('confirmPassword').setErrors({ passwordsMustMatch: true });
    }
    this.expiredPasswordForm.get('confirmPassword').updateValueAndValidity();
    this.passwordsMustMatchMsg = MESSAGES.PASSWORD_MATCH;
  }

  changePasswordRequestHandler(data) {
    if (data && data.errorCode) {
      this.isNotificationError = true;
      this.notificationErrorMsg = data.error;
      return false;
    }
    this.isNotificationError = false;
    openToast(this._snackbar, 'Password is changed successfully', STATUS.SUCCESS);
    // Reset password values of form
    this.formGroupDirective.resetForm();
    this.commonService.redirectToStudio();
  }

  changeUserPassword() {
    if (this.expiredPasswordForm.valid) {
      let payload = {email: this.email, ...this.expiredPasswordForm.value};
      this.usersService.resetExpiredPassword(payload).subscribe(data => {
        this.changePasswordRequestHandler(data);
      },
        err => {
          // Hide Overlay-spinner
          this.httpLoaderService.display(false);
          if (err.error.errorCode === 422) {
            this.changePasswordRequestHandler(err.error);
          }
        });
    }
  }

  public checkError(controlName: string, errorName: string) {
    return this.expiredPasswordForm.controls[controlName].hasError(errorName);
  }

  /**
   * Redirect to Studio Login
   * @param none
   */
  public returnToLogin() {
      this.commonService.redirectToStudio();
    }
  }