import {
  Component,
  OnInit,
  Input,
  Optional,
  Self,
  ViewChild,
  Inject,
} from "@angular/core";
import { NgControl, FormControl, ControlValueAccessor } from "@angular/forms";
import {
  MatAutocompleteTrigger,
  AUTOCOMPLETE_OPTION_HEIGHT,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { debounceTime } from "rxjs/operators";
import { MatInput } from "@angular/material/input";

@Component({
  selector: "um-autocomplete",
  templateUrl: "./autocomplete.component.html",
  styleUrls: ["./autocomplete.component.scss"],
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() id: string;
  @Input() list = [];
  @Input() required = true;
  @Input() label: string;
  @Input() errorMessage: string;

  _value: Object;
  previousSelectedValue: Object;
  @Input() disabled: boolean;
  filteredList;
  autoCompleteControl = new FormControl();

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  @ViewChild(MatInput) inputRef;

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl,
    @Inject(AUTOCOMPLETE_OPTION_HEIGHT) public option_height: number
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange(_) {
    this.autoCompleteControl.setValue(_);
  }
  onTouched() {}
  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this._value = value;
    this.previousSelectedValue = value;
    this.autoCompleteControl.setValue(value);
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  public displayFn(userRole): string {
    return userRole && userRole.label;
  }

  ngOnInit() {
    this.autoCompleteControl.valueChanges
      .pipe(debounceTime(100))
      .subscribe((data) => {
        if (typeof data === "string") {
          if (!data.length) this.previousSelectedValue = "";
          this.filteredList = this.list.filter(
            (itemData) =>
              itemData.label.toLowerCase().indexOf(data.toLowerCase()) > -1
          );
          this.filteredList = this.filteredList.filter(
            (itemData, index, self) =>
              index ===
              self.findIndex(
                (t) => t.label === itemData.label && t.value === itemData.value
              )
          );
          const valueIndex = this.list.findIndex(
            (item) => item.value.toLowerCase() === data.toLowerCase()
          );
          const labelIndex = this.list.findIndex(
            (item) => item.label.toLowerCase() === data.toLowerCase()
          );
          if (valueIndex > -1) {
            this.autoCompleteControl.setValue(this.list[valueIndex]);
            this.previousSelectedValue = this.list[valueIndex];
          } else if (labelIndex > -1) {
            this.autoCompleteControl.setValue(this.list[labelIndex]);
            this.previousSelectedValue = this.list[labelIndex];
          }
        }
        this.onChange(data);
      });
  }

  // setTimeout is used to resolve autocomplete panel DOM element access issue
  trigger(event) {
    if (event.type === "click") {
      this.filteredList = this.list;
      if (!this.autocomplete.panelOpen) {
        setTimeout(() => {
          this.autocomplete.openPanel();
        });
        this.changed_input();
      } else {
        setTimeout(() => {
          this.autocomplete.closePanel();
        });
      }
    }
  }

  public changed_input(): void {
    if (
      this.autoCompleteControl.value &&
      typeof this.autoCompleteControl.value === "object"
    ) {
      const index = this.list.findIndex((option) => {
        return (
          option.label.toLowerCase() ===
          this.autoCompleteControl.value.label.toLowerCase()
        );
      });
      if (index === -1) return;
      // used setTimeout to resolve panel DOM element access issue
      setTimeout(() => {
        this.matAutocomplete.panel &&
          (this.matAutocomplete.panel.nativeElement.scrollTop =
            this.option_height * index);
      }, 0);
    }
  }

  public checkError = (errorName: string) => {
    return this.autoCompleteControl.hasError(errorName);
  };

  public setEmpty() {
    this.onChange("");
    this.previousSelectedValue = "";
    this.autoCompleteControl.setErrors({ required: true });
    this.autoCompleteControl.updateValueAndValidity();
    return this.autoCompleteControl.setValue("");
  }

  public onBlur(event) {
    this.autoCompleteControl.setValue(this.previousSelectedValue);
  }

  selectedOption(e) {
    this.previousSelectedValue = e.option.value;
  }
}
