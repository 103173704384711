// Angular imports
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ViewChildren, QueryList, Input, TemplateRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// MISC imports
import { OrganizationService } from '../../services/organization.service';
import { Organization } from '../../models/organization';
import { trimSpaces, compareArray } from '../../../../utils/common-functions';
import { HttpLoaderService } from '../../../shared/components/http-loader/http-loader.service';
import { MESSAGES } from '../../utils/messages';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'um-edit-organization',
  templateUrl: './edit-organization.component.html',
  styleUrls: ['./edit-organization.component.scss']
})
export class EditOrganizationComponent implements OnInit {

  duplicateName: boolean;
  duplicateDomain: boolean;

  @Input() selectedOrganization: Organization;
  @Input() currentOrganization: Organization;
  invalidDomainsMsg = MESSAGES.VALID_DOMAINS;
  invalidOrgNameMsg = MESSAGES.VALID_NAME;
  orgNameServerError:string;
  domainNamesServerError: string;
  dirtyFormDialogRef: MatDialogRef<any>;
  orgForm: FormGroup;
  /**
   * Organization object.
   *
   * @type {Organization}
   * @memberof EditOrganizationComponent
   */
  updateOrgDetails: { organization: Organization } = {
    'organization': new Organization()
  };

  @Output() closePanel = new EventEmitter<any>();
  @Output() continueDialog = new EventEmitter<any>();

  @ViewChild('dirtyFormDialog') dirtyFormDialog: TemplateRef<any>;

  constructor(private organizationService: OrganizationService,
    private httpLoaderService: HttpLoaderService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder) { }

  /**
   * Initialize the edit-organization component view.
   *
   * @memberof EditOrganizationComponent
   */
  ngOnInit() {
    this.createOrgForm();
    this.updateOrgDetails.organization = Object.assign({}, this.selectedOrganization);
    this.orgForm.patchValue(this.updateOrgDetails.organization);
  }

  createOrgForm() {
    this.orgForm = this.formBuilder.group({
      orgName: [''],
      description: [''],
      domainNames: ['']
    });
  }
  /*
  * Reset Organization Name error message of to default
  * @param none
  */
  resetOrgNameMessage() {
    if(this.duplicateName) {
      this.orgForm.get('orgName').setErrors({invalidOrgName: true});
      this.duplicateName = false;
    }
  }

  /*
  * Reset Organization Domain error message to default
  * @param none
  */
  resetOrgDomainMessage() {
    if(this.duplicateDomain) {
      this.orgForm.get('domainNames').setErrors({invalidDomainName: true});
      this.duplicateDomain = false;
    }
  }

  /*
   * Trim spaces in input fields on blur event
   * @param targetElement => target input element
   */
  public inputBlurHandler(evnt, fieldName): void {
    if(evnt.target.value) {
      evnt.target.value = trimSpaces(evnt.target.value);
      this.orgForm.get(fieldName).setValue(trimSpaces(evnt.target.value));
    }
  }

  /*
  * Emit an event to close the panel along with parameters
  * @param none
  */
 public checkFormStatus(): boolean {
  this.updateOrgDetails.organization = {...this.updateOrgDetails.organization, ...this.orgForm.value};
  const dirtyForm = compareArray(this.currentOrganization, this.updateOrgDetails.organization);
  if(!dirtyForm) {
    this.dirtyFormDialogRef = this.dialog.open(this.dirtyFormDialog, {disableClose: true});
    return false;
  }
  return dirtyForm;
 }

  /*
  * Emit an event to continue navigation even if the form is dirty
  * @param none
  */
  continueDirtyNavigation(): void {
    this.continueDialog.emit(true);
    this.dirtyFormDialogRef.close();
  }

  /*
  * Emit an event to close the panel on clicking 'cancel' button
  * @param none
  */
  closeOrgPanel(): void {
    this.checkFormStatus() ? this.closePanel.emit(null) : '';
  }

  /*
  * Validate form and call `updateOrganization()` on successful validation
  * @param none
  */
  validateForm(): void {
    if(this.orgForm.valid) {
      this.updateOrganization();
    }
  }

  /*
  * Update Organization on successful form validation
  * @param none
  */
  private updateOrganization(): void {
    this.updateOrgDetails.organization = {...this.updateOrgDetails.organization, ...this.orgForm.value};
    this.organizationService.updateOrganization(this.updateOrgDetails).subscribe(data => {
      this.closePanel.emit(data);
    },
    err => {
      if(err.status && (err.status == 400) || (err.status == 422)) {
        this.duplicateName = true;
        this.editOrgErrorHandler('orgName');
        this.orgNameServerError = err.error.error;
      } else if(err.status && err.status == 409) {
        this.duplicateDomain = true;
        this.editOrgErrorHandler('domainNames');
        this.domainNamesServerError = err.error.error;
      } else if(err.status && err.status == 406) {
        this.editOrgErrorHandler('domainNames');
        this.domainNamesServerError = err.error.error;
      }
    });
  }

  /**
   * Handle Edit Organization error scenario
   *
   * 422: Duplicate Organization Name
   * 409: Duplicate Domain
   *
   * @param fieldCtrlName Error field control
   */
  private editOrgErrorHandler(fieldCtrlName): void {
    this.httpLoaderService.display(false);
    this.orgForm.controls[fieldCtrlName].setErrors({serverError: true});
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.orgForm.controls[controlName].hasError(errorName);
  }

}
