<div class="header-boundary-bar"></div>
<div class="pageWrapper">
<div class="">
  <um-niq-logo></um-niq-logo>
  <um-welcome-text></um-welcome-text>
</div>
<div class="account-wrapper" #accountContainer [style.height]="checkError('resetPasswordEmail', 'serverError')  ? '385px' : '360px'">
  <div class="account-container">
    <div class="accountHeader">
     <h3>Reset Password</h3>
    </div>
    <error-alert
      class="createAccountToast"
      *ngIf="isNotificationError"
      [message]="notificationErrorMsg"
    ></error-alert>
    <form name="passwordResetForm" [formGroup]="resetPasswordForm">
      <div class="field">
        <mat-form-field appearance="standard">
          <label class="custom-field-label ">Email</label>
          <input
            type="text"
            class="resetPasswordEmail"
            placeholder="*Enter a valid email address"
            aria-label="Email"
            matInput
            autocomplete="off"
            maxlength="256"
            (keyup.enter)="resetUserPassword()"
            formControlName="resetPasswordEmail"
          />
          <mat-error
            *ngIf="
              checkError('resetPasswordEmail', 'required') ||
              checkError('resetPasswordEmail', 'pattern')
            "
          >
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="checkError('resetPasswordEmail', 'serverError')">
            {{ serverError }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field inline-message info-text">
        <p *ngIf="inactiveEmailError == true">
          This account needs to be activated. <br />
          Click the "Resend Activation" button below or you can return to login
          page.
        </p>
        <p *ngIf="inactiveEmailError == false">
          Enter the email address used to create your Studio Account. A link
          will be sent to reset your password.
        </p>
      </div>
      <br />
      <button
          mat-flat-button
          color="primary"
          *ngIf="inactiveEmailError == true"
          [disabled]="disableResendActivationLinkButton"
          class="m-0 px-4 resendActivationLinkButton"
          (click)="resendActivationLink()"
          #resendActivationLinkButton
        >
          Resend Activation
        </button>
        <button
          mat-flat-button
          color="primary"
          *ngIf="inactiveEmailError == false"
          class="m-0 resetPasswordSubmitButton"
          (click)="resetUserPassword()"
        >
          Reset Password
        </button>
        <div class="account-services-section account-locked-section buttons buttonclass">
          <span class="text-link mt-4" (click)="returnToLogin()"
            >Return to Log In</span
          >
        </div>
      </form>
  </div>
</div>
</div>
<div class="footer-boundary-bar"></div>
