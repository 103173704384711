<div class="header-boundary-bar"></div>
<div class="pageWrapper">
<div class="">
  <um-niq-logo></um-niq-logo>
  <um-welcome-text></um-welcome-text>
</div>
<div class="account-wrapper lg-wrapper" [style.height]="isNotificationMsg === true ? '715px' : '645px'" #accountContainer>
  <div class="account-container">
    <div class="accountHeader">
      <h3>Edit Profile</h3>
    </div>
    <error-alert class="editProfileToast" *ngIf="isNotificationMsg" [message]="errorNotificationMsg"></error-alert>
    <form name="editProfileForm" #activateForm [formGroup]="editProfileForm" (keyup.enter)="updateUserProfile()">
      <div class="field">
        <mat-form-field appearance="standard">
          <label class="custom-field-label">First Name</label>
          <input type="text" class="editProfileFirstName" aria-label="First Name" matInput required autocomplete="off"
            placeholder="*Enter your first name" maxlength="256" (blur)="inputBlurHandler($event, 'firstName')"
            formControlName="firstName">
          <mat-error *ngIf="checkError('firstName', 'required')">
            First Name is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field appearance="standard">
          <label class="custom-field-label">Middle Name</label>
          <input type="text" class="editProfileMiddleName" aria-label="Middle Name" matInput autocomplete="off"
            placeholder="Enter a midle name or initial" maxlength="256" (blur)="inputBlurHandler($event, 'middleName')"
            formControlName="middleName">
          <mat-error *ngIf="checkError('middleName', 'required')">
            Middle Name is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field appearance="standard">
          <label class="custom-field-label">Last Name</label>
          <input type="text" class="editProfileLastName" aria-label="Last Name" matInput required autocomplete="off"
            placeholder="*Enter a last name" maxlength="256" (blur)="inputBlurHandler($event, 'lastName')"
            formControlName="lastName">
          <mat-error *ngIf="checkError('lastName', 'required')">
            Last Name is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field">
        <mat-form-field appearance="standard">
          <label class="custom-field-label"
            [class.disable-color]="editProfileForm.controls.email.disabled">Email</label>
          <input type="text" class="editProfileEmail" aria-label="Email" matInput required autocomplete="off"
          placeholder="*Enter a confirmable email address"
            maxlength="256" tabindex="4" formControlName="email">
          <mat-error *ngIf="checkError('email', 'pattern')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <um-autocomplete [list]="countriesList" [id]="'editProfileCountry'" label="Country" [required]="true"
          formControlName="countryName" placeholder="Select Country">
        </um-autocomplete>
      </div>
      <div class="field">
        <um-autocomplete [list]="timeZonesList" [id]="'editProfileTimezone'" label="Time Zone" [required]="true"
          formControlName="timeZone" placeholder="Select Time Zone">
        </um-autocomplete>
      </div>
      <button mat-flat-button color="primary" class="mx-0 editProfileSubmitButton"
        (click)="updateUserProfile()">Save</button>
        <button mat-button (click)="cancelForm()" class="editProfileCancelButton">Cancel</button>
    </form>
  </div>
</div>
</div>
<div class="footer-boundary-bar"></div>
