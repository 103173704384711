// angular imports
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
// MISC imports
import { InterceptorModule } from '../interceptor/interceptor.module';
import { SharedModule } from '../shared/shared.module';
import { LicenceTextComponent } from '../shared/components/licence-text/licence-text.component';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ExpiredPasswordComponent } from './components/expired-password/expired-password.component';
import { UsersService } from './services/users.service';
import { ResendActivationComponent } from './components/resend-activation/resend-activation.component';
import { RequestAccountComponent } from './components/request-account/request-account.component';
import { UserFeatureFlagsComponent } from './components/user-feature-flags/user-feature-flags.component';

@NgModule({
  declarations: [
    LicenceTextComponent,
    ActivateAccountComponent,
    EditProfileComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    ResendActivationComponent,
    ExpiredPasswordComponent,
    RequestAccountComponent,
    UserFeatureFlagsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    InterceptorModule,
    SharedModule
  ],
  providers: [UsersService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsersModule {
}
