import {Component, OnInit} from '@angular/core';
import { CommonService } from '../../services/common.service';
import { HttpLoaderService } from '../http-loader/http-loader.service';

import { AppConfigService } from '../../../../modules/core/config/app-config.service';
import { HEALTH_DOWN } from '../../../../utils/common-variables';

@Component({
  selector: 'health-page',
  templateUrl: './health-page.component.html',
  styleUrls: ['./health-page.component.scss']
})
export class HealthPageComponent implements OnInit {

  /**
   * Health Message
   *
   * @type {any}
   * @memberof HealthPageComponent
   */
  healthJSON = {
    "status": "",
    "gandalf": {}
  };

  constructor(
    private commonService: CommonService,
    public appConfigService: AppConfigService,
    private httpLoaderService: HttpLoaderService){
  }

  ngOnInit() {
    this.commonService.checkGandalfHealth().subscribe(data => {
        this.healthJSON.status = data.status;
        this.healthJSON.gandalf = { status: data.status, url: this.appConfigService.config.gandalf.url + "/health" };
      },
      err => {
        this.healthJSON.status = HEALTH_DOWN;
        this.healthJSON.gandalf = { status: HEALTH_DOWN, url: this.appConfigService.config.gandalf.url + "/health", error : err };
        this.httpLoaderService.display(false);
      });
  }

}
