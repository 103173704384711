<ng-template modal #orgSearchDialog>
  <div class="orgSearchDialog">
    <h2 mat-dialog-title>{{ modalTitle }}</h2>
    <div class="org-search-content" mat-dialog-content>
      <div class="search-area search-modal">
        <div class="field">
          <mat-form-field inline appearance="standard">
            <label matPrefix>SEARCH:</label>
            <input
              matInput
              class="orgSearchTerm"
              [(ngModel)]="searchValue"
              maxlength="256"
              (keyup.enter)="searchByValue(orgSearchTerm.value)"
              #orgSearchTerm
            />
            <button
              matSuffix
              mat-icon-button
              class="search-btn"
              (click)="searchByValue(orgSearchTerm.value)"
            >
              <mat-icon class="sif sif-search" title="search"></mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="search-results-container">
        <div *ngIf="orgList && orgList.length > 0" class="results-container">
          <p class="tiny-text modal-message">
            Select Organization. If the user's organization is not present in
            the list, please select "NielsenIQ Studio" and Product Support will
            be alerted.
          </p>
          <div class="org-results-box">
            <div *ngIf="modalOrigin != orgAccessOriginValue">
              <mat-radio-group
                name="selectedOrg"
                aria-labelledby="Org List"
                aria-labelledby="Select Organization"
                [(ngModel)]="userOrg"
              >
                <mat-radio-button
                  (change)="selectOrgField(org)"
                  #orgResultList
                  class="d-flex flex-column my-2"
                  *ngFor="let org of orgList"
                  [value]="org.value"
                >
                  <span [class.text-bold]="orgResultList.checked">{{
                    org.label
                  }}</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div *ngIf="modalOrigin == orgAccessOriginValue">
              <div *ngFor="let org of orgList">
                <mat-checkbox
                  name="orgAccessList"
                  [checked]="
                    selectedOrgAccessValues.indexOf(org.value) > -1
                      ? 'checked'
                      : null
                  "
                  (change)="toggleOrgAccessSelection($event, org)"
                  aria-labelledby="Select Organization Access"
                  noink
                  #orgAccessList
                >
                  <span [class.text-bold]="orgAccessList.checked">{{
                    org.label
                  }}</span>
                </mat-checkbox>
              </div>
            </div>
          </div>
          <p class="error-text" *ngIf="showOrgSelectionError">
            Please select Organization.
          </p>
          <p class="error-text" *ngIf="showOrgAccessError">
            Non-NielsenIQ users can only have access to one organization
          </p>
        </div>
        <div
          *ngIf="!orgList || orgList.length == 0"
          class="no-results-container pt-3"
        >
          <span class="no-results-icon">
            <mat-icon class="sif sif-search"></mat-icon>
          </span>
          <h3 class="text-uppercase">There are no search results</h3>
          <p class="normal-text">
            Type the name of the Organization you want to find<br />and then
            click ' <mat-icon class="sif sif-search"></mat-icon>' to get started
          </p>
        </div>
      </div>
    </div>
    <div class="buttons" mat-dialog-actions>
      <button
        mat-button
        color="secondary"
        (click)="closeOrgModal()"
        class="orgSearchCancelButton"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="m-0 orgSearchSaveButton"
        *ngIf="modalOrigin != orgAccessOriginValue"
        (click)="selectOrganization()"
      >
        Save & Close
      </button>
      <button
        mat-flat-button
        color="primary"
        class="m-0 orgSearchSaveButton"
        *ngIf="modalOrigin == orgAccessOriginValue"
        (click)="selectOrganizationAccess()"
      >
        Save & Close
      </button>
    </div>
  </div>
</ng-template>
<!-- </nd-dialog> -->

<!-- Domain Mismatch Dialog -->
<ng-template modal="" #domainMismatchDialog>
  <div class="editDomainMismatchDialog">
    <h2 mat-dialog-title>Domain Mismatch</h2>
    <p mat-dialog-content>
      This organization's domain name ({{ userEmailDomain }}) does not match
      that of "{{ selectedOrg.label }}". Are you sure you want to make this
      assignment?
    </p>
    <div class="buttons" mat-dialog-actions>
      <button
        mat-button
        class="domainMismatchDialogCancelBtn"
        color="secondary"
        (click)="domainMismatchDialogRef.close()"
        tabindex="-1"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="m-0 domainMismatchDialogContinueBtn"
        (click)="continueOrgSubmission()"
        tabindex="-1"
      >
        Yes
      </button>
    </div>
  </div>
</ng-template>

<!-- Domain Mismatch Dialog For org Access-->
<ng-template class="um-inner-dialog" modal="" #accessDomainMismatchDialog>
  <div class="editAccessDomainMismatchDialog">
    <h2 mat-dialog-title>Domain Mismatch</h2>
    <p mat-dialog-content>
      The domain of this user "{{ userEmailDomain }}" does not match that of the
      organizations "{{ selectedMisMatchedOrgAccessValues }}". Are you sure you
      want to provide this user access?
    </p>
    <div class="buttons" mat-dialog-actions>
      <button
        mat-button
        class="accessDomainMismatchDialogCancelBtn"
        (click)="accessDomainMismatchDialogRef.close()"
        tabindex="-1"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="m-0 accessDomainMismatchDialogContinueBtn"
        (click)="continueOrgAccessSubmission()"
        tabindex="-1"
      >
        Yes
      </button>
    </div>
  </div>
  <!-- <div class="index-overlay-loader"></div> -->
</ng-template>

<!-- No Organization Domain Dialog -->
<ng-template class="um-inner-dialog" modal="" #noOrgDomainDialog>
  <div class="editDomainMismatchDialog">
    <h2 mat-dialog-title>No Organization Domain</h2>
    <p mat-dialog-content>
      "{{ noDomainsOrgValues }}" does not have any domains associated with it.
    </p>
    <p>
      Please reach out to Product Support to have the domain added or set user's
      organization to "NielsenIQ Studio".
    </p>
    <div class="buttons" mat-dialog-actions>
      <button
        mat-flat-button
        color="primary"
        class="m-0 noDomainDialogOkBtn"
        (click)="noOrgDomainDialogRef.close()"
        tabindex="-1"
      >
        OK
      </button>
    </div>
  </div>
  <!-- <div class="index-overlay-loader"></div> -->
</ng-template>
