/* List of 'organization' module API paths which are appended to Base URL in http requests
 * Writing these in separate file so that it is easier to update API changes in future
*/

// Get list of Organizations
export const ORGANIZATIONS_API = 'organizations';

// Search by organization name
export const SEARCH_ORGNAME = 'orgName';

