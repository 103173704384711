export const SESSION_INTERVAL_DURATION = 2 * 60 * 1000;

export const NIELSEN_CONTACT_US_URL = "http://innovation.nielsen.com/contact-us";

export const HELP_URL = "https://mybases.nielseniq.com/confluence/display/IPS/Studio+User+Guide";

export const HEALTH_DOWN = "Down";

// Map containing different roles of a User

export const USER_ROLE_MAP = {
  "administrator": "ROLE_SYSTEM_ADMINISTRATOR",
  "nielsenFinance": "ROLE_Nielsen+Finance",
  "panel": "ROLE_Panel",
  "limitedAccess": "ROLE_Limited_Access"
}

export const GROUP_ROLE_MAP = {
  GROUP_MANAGEMENT : "Group Management",
  USER_MANAGEMENT : "User Management",
  ORGANIZATION_MANAGEMENT : "Organization Management"
}

export const USER_ACCESS = {
  "adminAccess": false,
  "fullAccess": false
}


export const TABLE_HEAD_SCROLL_ORDER = {
  "users": 130,
  "groups": 130,
  "organizations": 184
}

export const ORGANIZATION_MODAL_TITLE_MAP = {
  "editUser": "Select the organization this user is part of",
  "orgAccess": "Select the organization(s) this user has access to"
};

export const GROUP_MODAL_TITLE_MAP = {
  "usergroups": "Select the group(s) this user is part of"
};

export const USERS_MODAL_TITLE_MAP = {
  'viewMembers':'Members',
  'manageMembers':'Manage Group '
};

export const ORGANIZATION_MODAL_ORIGIN = {
  "editUser": "editUser",
  "orgAccess": "orgAccess"
}

export const GROUP_MODAL_ORIGIN = {
  "usergroups": "usergroups"
}
