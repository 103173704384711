// angular imports
import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";

// MISC imports
import { HeartBeatResolver } from "../shared/services/heart-beat-resolver.service";
import { ActivationTokenResolver } from "./services/activation-token-resolver.service";
import { AppLayoutComponent } from "../app-layout/app-layout.component";
import { CreateAccountComponent } from "./components/create-account/create-account.component";
import { RequestAccountComponent } from "./components/request-account/request-account.component";
import { ActivateAccountComponent } from "./components/activate-account/activate-account.component";
import { EditProfileComponent } from "./components/edit-profile/edit-profile.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { ExpiredPasswordComponent } from "./components/expired-password/expired-password.component";
import { UsersService } from "./services/users.service";
import { ResendActivationComponent } from "./components/resend-activation/resend-activation.component";
import { AccessibilityResolver } from "../core/accessibility/accessibility-resolver.service";
import { ARWEN_PAGE_NAMES } from "../core/accessibility/page-names";
import { ResendActivationResolver } from "../shared/services/resend-activation-resolver.service";
import { ChangePasswordResolver } from "../shared/services/change-password-resolver.service";

const ROUTES: Routes = [
  {
    path: "create",
    component: CreateAccountComponent,
    data: {
      pageName: ARWEN_PAGE_NAMES.createAccount,
    },
    resolve: {
      status: HeartBeatResolver,
    },
    canActivate: [AccessibilityResolver],
  },
  {
    path: "activate",
    component: ActivateAccountComponent,
    resolve: {
      userInfo: ActivationTokenResolver,
    },
  },
  {
    path: "resend-activation",
    component: ResendActivationComponent,
    resolve: {
      userInfo: ResendActivationResolver,
    },
  },
  {
    path: "edit",
    component: EditProfileComponent,
    data: {
      pageName: ARWEN_PAGE_NAMES.editUser,
    },
    resolve: {
      status: HeartBeatResolver,
    },
  },
  {
    path: "reset",
    component: ResetPasswordComponent,
  },
  {
    path: "changePassword",
    component: ChangePasswordComponent,
    resolve: {
      userInfo: ChangePasswordResolver,
    },
  },
  {
    path: "changePassword/:token",
    component: ChangePasswordComponent,
  },
  {
    path: "expiredPassword",
    component: ExpiredPasswordComponent,
    resolve: {
      userInfo: ChangePasswordResolver,
    },
  },
  {
    path: "requestAccount",
    component: RequestAccountComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { useHash: true })],
  exports: [RouterModule],
  providers: [
    AccessibilityResolver,
    HeartBeatResolver,
    UsersService,
    ActivationTokenResolver,
    ResendActivationResolver,
    ChangePasswordResolver,
    ExpiredPasswordComponent,
  ],
})
export class UsersRoutingModule {}
