// angular imports
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModel } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// MISC imports
import { SharedModule } from './modules/shared/shared.module';
import { InterceptorModule } from './modules/interceptor/interceptor.module';
import { AppComponent } from './app.component';
import { HttpLoaderService } from './modules/shared/components/http-loader/http-loader.service';
import { HttpLoaderComponent } from './modules/shared/components/http-loader/http-loader.component';
import { AppRoutingModule } from "./app-routing.module";
import { UsersModule } from './modules/users/users.module';
import {GroupsModule} from "./modules/groups/groups.module";
import { OrganizationsModule } from './modules/organizations/organizations.module';
import { AppLayoutModule } from './modules/app-layout/app-layout.module';
import { CommonService } from './modules/shared/services/common.service';
import { CoreModule } from './modules/core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AUTOCOMPLETE_OPTION_HEIGHT } from '@angular/material/autocomplete';
import { LoggerType, NgxComponentsConfig, NgxComponentsModule } from '@basesstudio/ngx-components';

/**
 * NgxComponents configuration.
 */
 const ngxConfig: NgxComponentsConfig = {
  moduleName: 'User Management',
  logger: {
    type: LoggerType.CONSOLE
  }
};


@NgModule({
  declarations: [
    AppComponent,
    HttpLoaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    InterceptorModule,
    FormsModule,
    CommonModule,
    CoreModule.forRoot(),
    NgxComponentsModule.forRoot(ngxConfig),
    SharedModule,
    AppRoutingModule,
    UsersModule,
    OrganizationsModule,
    GroupsModule,
    AppLayoutModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule
  ],
  providers: [HttpLoaderService, CommonService],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
