import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { HttpLoaderService } from '../http-loader/http-loader.service';

@Component({
  selector: 'um-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  /**
   * Error Message
   *
   * @type {string}
   * @memberof ErrorPageComponent
   */
  public error: string;

  /**
   * Error Type
   *
   * @type {string}
   * @memberof ErrorPageComponent
   */
  public errorType: string;

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private httpLoaderService: HttpLoaderService){
      this.error = this.route.snapshot.data['error'];
      this.errorType = this.route.snapshot.data['errorType'];
  }

  ngOnInit() {
    this.httpLoaderService.display(false);
  }

  /**
   * Redirect to Studio Login Page
   * @param none
   */
  returnToLogin() {
    this.commonService.redirectToStudio();
  }

}
