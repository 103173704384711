<ng-template modal #groupSearchDialog>
  <div class="groupSearchDialog">
  <h2 mat-dialog-title>{{modalTitle}}</h2>
  <div class="org-search-content" mat-dialog-content>
    <div class="search-results-container">
      <div *ngIf="groupsList && userGroupsList && userGroupsList.length >= 0" class="results-container">
        <div class="org-results-box">
          <div *ngFor="let group of groupsList">
            <mat-checkbox 
            name="gList"
            [checked]="modifiedGroupsValues.indexOf(group.value) > -1 ? 'checked' : null"
            (change)="toggleGroupAccessSelection($event, group)"
            aria-labelledby="Select Group" noink #gList>
            <span [class.text-bold]="gList.checked">{{group.value}}</span>
             </mat-checkbox>   
        </div>
        </div>
      </div>
      <div *ngIf="!groupsList" class="no-results-container pt-3">
         <span class="no-results-icon">
          <mat-icon class="sif sif-search"></mat-icon>
        </span>
        <h3 class="text-upspercase">There are no Group results</h3>
      </div>


    </div>
  </div>

  <div class="buttons" mat-dialog-actions>
    <button mat-button color="secondary" (click)="closeGroupModal('cancel')" class="groupAccessCancelButton" tabindex="-1">Cancel</button>
    <button mat-flat-button color="primary" class="m-0 groupAccessSaveButton" (click)="updateAndSave()" tabindex="-1">Save & Close</button>
  </div>
</div>
</ng-template>

