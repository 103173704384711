/**
 * This resend-activation-resolver service is used to check the correctness of activation link sent
 * to the user in case where activation-token is expired.
 * 
 * This service retrieves user email based on the activation token and passes the same to its respective
 * component. If the token is expired or invalid, then the user is redirected to invalid-url page. 
 *
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../core/config/app-config.service';
import { TOKEN_USER_DETAILS } from '../../users/utils/api-list';
import { map } from 'rxjs/operators';

@Injectable()
export class ResendActivationResolver implements Resolve<any>{

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService) {}

  resolve(route: ActivatedRouteSnapshot):Observable<any> {
    const activationToken = route.queryParams['t'];
    const getUserEmailURL = `${this.appConfigService.config.gandalf.url}/${TOKEN_USER_DETAILS}?t=${activationToken}`
    return this.httpClient.get(getUserEmailURL)
    .pipe(map((response: any) => {
      let userResponse = {
        token: activationToken,
        userEmail: response.email
      }
      return userResponse;
    }));
  }
}
