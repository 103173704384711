import { Injectable } from '@angular/core';

/**
 * This service provides common utility functions for org-search component.
 *
 * @example
 * constructor(private orgSearchService: OrgSearchService,) { }
 *
 * @export
 * @class OrgSearchService
 */
@Injectable()
export class OrgSearchService {

  constructor(){};

  /**
   * Returns an array of Organizations that match the search term.
   *
   * @param {Array<any>} items List of Organizations
   * @param {string} searchTerm Searched text
   * @returns {Array<any>} resultant Organizations
   * @memberof OrgSearchService
   */
  public getSearchResults(organizations: any[], searchTerm: string): any[] {
    if(!organizations) return [];
    if(!searchTerm) return organizations;
    searchTerm = searchTerm.toLowerCase();
    return organizations.filter( organization => {
      return organization.label.toLowerCase().includes(searchTerm);
    });
  }

}