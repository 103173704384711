// angular imports
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// MISC imports
import { UsersService } from '../../services/users.service';
import { trimSpaces, openToast } from '../../../../utils/common-functions';
import { User } from '../../models/user';
import { getKeyValueList } from '../../utils/common-functions';
import { MESSAGES } from '../../utils/messages';
import { CommonService } from '../../../shared/services/common.service';
import { UserInfoService } from '../../../shared/services/user-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';

@Component({
  selector: 'um-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  providers: [UsersService]
})
export class EditProfileComponent implements OnInit {

  country: string;
  timezone: string;
  editProfileForm: FormGroup;
  countriesList = [];
  timeZonesList = [];
  errorNotificationMsg: string;
  isNotificationMsg: boolean;

  // User fields from 'User' class
  profileData = new User();

  constructor(private userInfoService: UserInfoService,
    private usersService: UsersService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private _snackbar: MatSnackBar) { }

  ngOnInit() {
    this.createChangePasswordForm();
    this.userInfoService.getUserInfo().subscribe(data => {
      this.profileData = data.user;
      this.editProfileForm.patchValue(data.user);
      this.editProfileForm.get('email').disable();

      this.commonService.getTimezones().subscribe(data => {
        this.timeZonesList = getKeyValueList(data.timezone);
        if (this.timeZonesList.length) {
          const selectedTimeZone = this.timeZonesList.filter(timeZone => timeZone.value === this.profileData.timeZone);
          this.editProfileForm.get('timeZone').setValue(selectedTimeZone.length && selectedTimeZone[0]);
        }
      });

      this.commonService.getCountries().subscribe(data => {
        this.countriesList = getKeyValueList(data.countries);
        this.editProfileForm.get('countryName').setValue(this.profileData.countryName ? { label: this.profileData.countryName, value: this.profileData.countryName}: null);
      });
    });
  }

  createChangePasswordForm() {
    this.editProfileForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      middleName: [''],
      email: ['', Validators.pattern(/^([a-zA-Z0-9'_\-\+\.]+)@([a-zA-Z0-9_\-\+\.]+)\.([a-zA-Z]{2,50})$/)],
      countryName: [''],
      timeZone: ['']
    });
  }

  // Trim spaces on blur in input fields
  inputBlurHandler(evnt, fieldName) {
    if (evnt.target.value) {
      evnt.target.value = trimSpaces(evnt.target.value);
      this.editProfileForm.get(fieldName).setValue(trimSpaces(evnt.target.value));
    }
  }

  // Redirect the user to Studio home page
  cancelForm() {
    this.commonService.redirectToStudio();
  }

  updateUserProfile() {
    if (this.editProfileForm.valid) {
      this.isNotificationMsg = false;
      let profileData = { ...this.profileData, ...this.editProfileForm.getRawValue() };
      profileData.timeZone = profileData.timeZone.value;
      profileData.countryName = profileData.countryName.value;
      this.usersService.updateUserProfile(profileData).subscribe(data => {
        this.userInfoService.setUserInfo(data);
        openToast(this._snackbar, "You have successfully updated details of \"" + profileData.email + "\"", STATUS.SUCCESS);

        this.commonService.redirectToStudio();
      });
    } else {
      this.isNotificationMsg = true;
      this.errorNotificationMsg = MESSAGES.ENTER_REQUIRED_DETAILS;
    }
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.editProfileForm.controls[controlName].hasError(errorName);
  }

  public displayFn(timeZone): string {
    return timeZone.label;
  }

   /**
   * Redirect to Studio Login
   * @param none
   */
   public returnToLogin() {
    this.commonService.redirectToStudio();
  }
}
