/**
 * This change-password-resolver service is used to check the correctness of reset link sent
 * to the user in case when user requests to reset password.
 * 
 * This service retrieves user email based on the reset-password token and passes the same to its
 * respective component. If the token is invalid, then the user is redirected to invalid-url page.
 * If the token is expired, then the user is again redirected to reset password page.
 *
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../core/config/app-config.service';
import { VALIDATE_RESET_TOKEN } from '../../users/utils/api-list';
import { CommonService } from './common.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ChangePasswordResolver implements Resolve<any>{

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private commonService: CommonService) {}

  resolve(route: ActivatedRouteSnapshot):Observable<any>|any {
    const passwordLinkToken= route.queryParams['t'];
    if(passwordLinkToken) {
      let getUserInfoURL = `${this.appConfigService.config.gandalf.url}/${VALIDATE_RESET_TOKEN}?token=${passwordLinkToken}`
      return this.httpClient.get(getUserInfoURL)
      .pipe(map((response: any) => {
        if(response.error && response.errorCode == 202) {
          this.router.navigate(['reset', { tokenExpired: true }]);
          return false;
        }
        let userResponse = {
          changePasswordPage: false,
          token: passwordLinkToken,
          email: response.users.email
        }
        return userResponse;
      }));
    }else {
      this.commonService.checkHeartBeat(false).subscribe();
      let userResponse = {
        changePasswordPage: true
      }
      return userResponse;
    }
  }
}
