<div class="um-content">
  <mat-card>
    <um-update-groups *ngIf="showNewGroup" updateType="create" (closePanel)="closeCreateGroup($event)"></um-update-groups>
    <div class="buttons" *ngIf="!showNewGroup ">
      <button mat-flat-button color="secondary" (click)="showNewGroup = true">Create New</button>
    </div>
    <div class="table-data" *ngIf="groupList.length > 0">
      <table class="um-table table table-bordered table-hover table-condensed table-sticky">
        <thead>
        <tr>
          <th style="width: 2000px;">Group Name</th>
          <th style="width: 4650px;">Description</th>
          <th style="width: 300px;">Members</th>
          <th style="width: 550px;">Action</th>
        </tr>
        </thead>
        <tbody *ngFor="let group of groupList; let rowIndex = index">
        <tr class="user-td">
          <td>{{group.groupName}}</td>
          <td>{{group.description | titlecase}}</td>
          <td>
            <div class="d-flex justify-content-center">
            <button mat-button color="primary" class="m-0" (click)="viewMembers(rowIndex)">{{group.users ? group.users.length : 0}}</button>
            </div>
            <user-search *ngIf="viewMembersModal==true && rowIndex == viewMembersIndex " modalOrigin="viewMembers"  [groupName]="group.groupName" searchInput="view" (hideUserModal)="viewMembersModal = false"></user-search>
            </td>
          <td>
            <div class="text-center">
                <span class="edit-icon pr-4"  [ngClass]="{'active-edit-icon': rowIndex == editGroupIndex}" (click)="editGroup(rowIndex)">
                    <mat-icon class="sif sif-edit" title="edit"></mat-icon>
                </span>
              <span *ngIf="permissionsList.users" class="edit-icon pr-2" [ngClass]="{'active-edit-icon': rowIndex == manageMembersIndex}" (click)="manageMembers(rowIndex)">
                    <mat-icon class="sif sif-group" title="manage"></mat-icon>
                </span>
            <user-search *ngIf="manageMembersModal==true && rowIndex == manageMembersIndex && permissionsList.users" modalOrigin="manageMembers"  [groupName]="group.groupName" [isInternalGroup]="group.isInternalGroup" searchInput="edit" (hideUserModal)="manageMembersModal = false" (closePanel)="closeManageMembers($event)"></user-search>
          </div>
          </td>
        </tr>
        <tr class="detailed-container">
          <td colspan="5">
            <um-update-groups *ngIf="rowIndex == editGroupIndex && showEditPanel==true" updateType="edit"  [selectedGroup]="group" [currentGroup]="group" (continueDialog)="continueNavigation()" (closePanel)="closeEditGroup($event)"></um-update-groups>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </mat-card>
</div>
