/**
 * The `ActivationTokenResolver` service is used to check the validity of activation-token link sent
 * to the user in case when user requests to activate account.
 *
 * This service retrieves user information based on the activation token and passes the same to its
 * respective component. If the token is invalid, then the user is redirected to invalid-url page.
 * If the token is expired, then the user is redirected to 'resend activation link' page.
 *
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AppConfigService } from '../../core/config/app-config.service';
import { GET_USER_DETAILS } from '../utils/api-list';
import { map } from 'rxjs/operators';

@Injectable()
export class ActivationTokenResolver implements Resolve<any>{

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private appConfigService: AppConfigService) {
     }

  // resolve(route: ActivatedRouteSnapshot): Observable<any>|Promise<any>|any {
  //   return this.usersService.getUserDetails(route.queryParams['t']);
  // }

  resolve(route: ActivatedRouteSnapshot):Observable<any>|Promise<any>|any {
    let getUserInfoURL = `${this.appConfigService.config.gandalf.url}/${GET_USER_DETAILS}?t=${route.queryParams['t']}`
    return this.httpClient.get(getUserInfoURL)
    .pipe(map((response: any) => {
      if(response.error && response.errorCode == 202) {
        this.router.navigate(['/resend-activation'], {queryParams: {t: route.queryParams['t']}});
        return false;
      }
      let userResponse = {
        token: route.queryParams['t'],
        user: response
      }
      return userResponse;
    }));
  }
}
