// Angular imports
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// MISC imports
import { OrganizationService } from '../../services/organization.service';
import { Organization } from '../../models/organization';
import { trimSpaces } from '../../../../utils/common-functions';
import { HttpLoaderService } from '../../../shared/components/http-loader/http-loader.service';
import { ORGANIZATION_TYPE } from '../../utils/common-variables';
import { MESSAGES } from '../../utils/messages';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'um-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.scss']
})
export class CreateOrganizationComponent implements OnInit {

  /**
   * Organization object.
   *
   * @type {Organization}
   * @memberof CreateOrganizationComponent
   */
  public orgDetails: { organization: Organization } = {
    'organization': new Organization()
  };
  duplicateName: boolean;
  duplicateDomain: boolean;
  orgForm: FormGroup;
  filteredOrgTypes: Observable<any>;
  invalidDomainsMsg = MESSAGES.VALID_DOMAINS;
  invalidOrgNameMsg = MESSAGES.VALID_NAME;
  orgNameServerError: string;
  domainNamesServerError: string;
  orgTypeList = ORGANIZATION_TYPE;
  @Output() closePanel = new EventEmitter<number>();

  @ViewChild('createOrgName') organizationName: ElementRef;
  @ViewChild('createOrgType') orgTypeField: ElementRef;
  @ViewChild('createOrgDomain') createOrgDomain: ElementRef;

  @ViewChildren('createOrgName, createOrgType') mandatoryFields: QueryList<any>;

  constructor(private organizationService: OrganizationService,
    private httpLoaderService: HttpLoaderService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createOrgForm();
  }

  createOrgForm() {
    this.orgForm = this.formBuilder.group({
      orgName: [''],
      orgType: [''],
      description: [''],
      domainNames: ['']
    });
  }

  /*
  * Reset Organization Name error message of to default
  * @param none
  */
  resetOrgNameMessage() {
    if (this.duplicateName) {
      this.orgForm.get('orgName').setErrors({ invalidOrgName: true });
      this.duplicateName = false;
    }
  }

  /*
  * Reset Organization Domain error message to default
  * @param none
  */
  resetOrgDomainMessage() {
    if (this.duplicateDomain) {
      this.orgForm.get('domainNames').setErrors({ invalidDomainName: true });
      this.duplicateDomain = false;
    }
  }

  /*
   * Trim spaces in input fields on blur event
   * @param targetElement => target input element
   */
  public inputBlurHandler(evnt, fieldName): void {
    if (evnt.target.value) {
      evnt.target.value = trimSpaces(evnt.target.value);
      this.orgForm.get(fieldName).setValue(trimSpaces(evnt.target.value));
    }
  }

  /*
  * Emit an event to close the panel along with parameters
  * @param {any} response
  */
  closeOrgPanel(response): void {
    this.closePanel.emit(response);
  }

  /*
  * Validate form and call `createOrganization()` on successful validation
  * @param none
  */
  validateForm(): void {
    if (this.orgForm.valid) {
      this.createOrganization();
    }
  }

  /*
  * Create new Organization on successful form validation
  * @param none
  */
  private createOrganization(): void {
    const { orgType, domainNames } = this.orgForm.value;
    this.orgDetails.organization = { ...this.orgDetails.organization, ...this.orgForm.value };
    this.orgDetails.organization.country = 'India';
    this.orgDetails.organization.orgType = orgType.value;
    if (!domainNames) {
      this.orgDetails.organization.domainNames = null;
    }

    this.organizationService.createOrganization(this.orgDetails).subscribe(data => {
      this.closeOrgPanel(data);
    },
      err => {
        if (err.status && (err.status == 400) || (err.status == 422)) {
          this.duplicateName = true;
          this.createOrgErrorHandler('orgName');
          this.orgNameServerError = err.error.error;
        } else if (err.status && err.status == 409) {
          this.duplicateDomain = true;
          this.createOrgErrorHandler('domainNames');
          this.domainNamesServerError = err.error.error;
        } else if (err.status && err.status == 406) {
          this.createOrgErrorHandler('domainNames');
          this.domainNamesServerError = err.error.error;
        }
      });
  }

  /**
   * Handle Create Organization error scenario
   *
   * 422: Duplicate Organization Name
   * 409: Duplicate Domain
   *
   * @param fieldCtrlName Error field control
   */
  private createOrgErrorHandler(fieldCtrlName) {
    this.httpLoaderService.display(false);
    this.orgForm.controls[fieldCtrlName].setErrors({ serverError: true });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.orgForm.controls[controlName].hasError(errorName);
  }
}
