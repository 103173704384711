// angular imports
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  TemplateRef,
} from "@angular/core";
import { DatePipe } from "@angular/common";
// MISC imports
import {
  compareArray,
  convertToRegex,
  getUserOrgAcessData,
  openToast,
} from "../../../../utils/common-functions";
import { UsersService } from "../../services/users.service";
import { getKeyValueList } from "../../utils/common-functions";
import { User } from "../../models/user";
import { ExpiredDatePipe } from "../../../shared/pipes/expired-date/expired-date.pipe";
import { DUMMY_PASSWORD } from "../../utils/common-variables";
import {
  ORGANIZATION_MODAL_ORIGIN,
  GROUP_MODAL_ORIGIN,
  USER_ROLE_MAP,
  GROUP_ROLE_MAP,
} from "../../../../utils/common-variables";
import { UserInfoService } from "../../../shared/services/user-info.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { STATUS } from "src/app/modules/shared/components/error-alert/error-alert.component";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
  selector: "um-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
  providers: [DatePipe, ExpiredDatePipe],
})
export class EditUserComponent implements OnInit {
  @Input() user;
  @Input() userData;
  @Input() isAdmin;
  @Input() countryList;
  @Input() timezoneList;
  @Input() roleMap;
  @Input() organisationList;
  @Input() userGroupsList;
  @Output() onEditPaneClose = new EventEmitter<boolean>();
  @Output() onUserUpdate = new EventEmitter<boolean>();

  oldUserDetails = {};
  accountExpDate: any;
  orgSearchResult: any;
  showOrgModal = false;
  showGroupsModal = false;
  showOrgAccessModal = false;
  userOrganization: string;
  userOrgAccessNames: any;
  isLoggedInUserInUmGroup: boolean;
  selectGroupsAccessList: any;
  userOrgAccessData = [];
  modalOrgMap = {};
  modalGroupMap = {};
  permissionsList: any;
  isInternalUser = false;
  existingGroupsList = [];
  rolesList = [];
  updateOrgAccessDialogRef: MatDialogRef<any>;

  // User fields from 'User' class
  userDetails: { user: User } = {
    user: new User(),
  };

  editUserForm: FormGroup;
  @ViewChild("updateOrgAccessDialog") updateOrgAccessDialog: TemplateRef<any>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  constructor(
    private usersService: UsersService,
    private datePipe: DatePipe,
    private userInfoService: UserInfoService,
    private expiredDatePipe: ExpiredDatePipe,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private _snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.createEditUserForm();
    this.modalOrgMap = ORGANIZATION_MODAL_ORIGIN;
    this.modalGroupMap = GROUP_MODAL_ORIGIN;
    let roleList = [];
    this.userData.isInternalUser
      ? (roleList = this.roleMap.nielsenRoles)
      : (roleList = this.roleMap.nonNielsenRoles);

    this.isInternalUser = this.userData.isInternalUser;
    this.user = Object.assign({}, this.userData);
    this.user.password = DUMMY_PASSWORD;

    let formattedExpDate = this.datePipe.transform(
      new Date(this.user.expirationDate),
      "yyyy-MM-dd"
    );
    this.user.expirationDate = formattedExpDate;
    this.editUserForm
      .get("country")
      .setValue(
        this.user.countryName
          ? { label: this.user.countryName, value: this.user.countryName }
          : null
      );
    this.editUserForm.get("password").setValue(this.user.password || "");
    this.editUserForm.get("accountExpDate").setValue(this.user.expirationDate);
    this.editUserForm.get("accountLocked").setValue(this.user.accountLocked);
    this.editUserForm.get("archive").setValue(this.user.archive);
    this.editUserForm.get("innovationLab").setValue(this.user.innovationLab);
    const selectedTimeZone = this.timezoneList.filter(
      (time) => time.value === this.user.timeZone
    );
    this.editUserForm
      .get("timeZone")
      .setValue(selectedTimeZone.length ? selectedTimeZone[0] : null);

    // Display user's accessible Organization Names
    if (!this.user.organizationAccess) this.user.organizationAccess = [];
    this.userOrgAccessData = getUserOrgAcessData(
      this.organisationList,
      this.user.organizationAccess
    );
    this.userOrgAccessNames = this.userOrgAccessData.map((org) => org.label);
    this.oldUserDetails = Object.assign({}, this.user);

    this.usersService.getUsers(this.user.email).subscribe((data) => {
      if (data.users[0].userGroups) {
        this.userGroupsList = [...data.users[0].userGroups];
      }
    });

    this.isLoggedInUserInUmGroup = false;
    this.userInfoService.getUserInfo().subscribe((data) => {
      this.permissionsList = data.permissions.administration;

      if (
        data.user.userGroups &&
        data.user.userGroups.indexOf(GROUP_ROLE_MAP.USER_MANAGEMENT) > -1
      ) {
        this.isLoggedInUserInUmGroup = true;
      }
      if (this.isLoggedInUserInUmGroup && !this.isAdmin) {
        if (this.user.globalRole == USER_ROLE_MAP.administrator) {
          roleList = roleList.filter(
            (selection) => selection.displayName == USER_ROLE_MAP.administrator
          );
        } else {
          roleList = roleList.filter(
            (selection) => selection.displayName != USER_ROLE_MAP.administrator
          );
        }
      }
      this.rolesList = getKeyValueList(roleList);
    });

    let userRole = this.rolesList.filter(
      (data) => data.value === this.user.globalRole
    );
    this.editUserForm
      .get("userRole")
      .setValue(userRole.length ? userRole[0] : null);
    this.editUserForm.get("userRole").valueChanges.subscribe((data) => {
      if (typeof data === "object") {
        this.user.globalRole = data.value;
        this.handleRoleSelection(this.user.globalRole);
      }
    });
  }

  createEditUserForm() {
    this.editUserForm = this.formBuilder.group({
      userRole: ["", Validators.required],
      country: ["", Validators.required],
      timeZone: ["", Validators.required],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])[\S.*?]{6,}$/),
        ],
      ],
      accountExpDate: [""],
      archive: [""],
      innovationLab: [""],
      accountLocked: [""],
    });
  }

  handleRoleSelection(role: string) {
    let selectedRole = role.toLowerCase();
    if (
      selectedRole == USER_ROLE_MAP.panel.toLowerCase() ||
      selectedRole == USER_ROLE_MAP.limitedAccess.toLowerCase()
    ) {
      this.user.archive = false;
      this.user.innovationLab = false;
      this.editUserForm.get("archive").setValue(false);
    }
  }

  assignUserValues() {
    const {
      userRole,
      country,
      timeZone,
      accountExpDate,
      accountLocked,
      password,
    } = this.editUserForm.getRawValue();
    this.user.globalRole = userRole.value;
    this.user.countryName = (country && country.value) || null;
    this.user.timeZone = (timeZone && timeZone.value) || null;
    this.user.accountLocked = accountLocked;
    this.user.expirationDate = accountExpDate;
  }

  selectExpDate() {
    var pastDate = this.expiredDatePipe.transform(this.accountExpDate);
  }

  // Assign selected Organization Name to user
  selectOrganization(selectedOrg) {
    this.userOrganization = selectedOrg.label;
    this.user.organization = selectedOrg.value;
    this.selectOrganizationAccess(selectedOrg);
    this.updateOrgAccessDialogRef = this.dialog.open(
      this.updateOrgAccessDialog,
      { disableClose: true }
    );
  }

  // Assign selected Organization Access to user
  selectOrganizationAccess(selectedOrgAccess) {
    if (Array.isArray(selectedOrgAccess)) {
      this.userOrgAccessData = [...selectedOrgAccess];
    } else {
      this.userOrgAccessData = [
        ...[
          {
            label: selectedOrgAccess.label,
            value: selectedOrgAccess.value,
            domains: selectedOrgAccess.domains,
          },
        ],
      ];
    }
    this.userOrgAccessNames = this.userOrgAccessData.map((org) => org.label);
    this.user.organizationAccess = this.userOrgAccessData.map(
      (org) => org.value
    );
  }

  // Assign selected Group Name to user
  selectGroupAccess(selectGroups) {
    let currentGroups = [];

    //fetching existing user Groups and adding new changes
    this.usersService.getUsers(this.user.email).subscribe((data) => {
      if (data.users[0].userGroups) {
        this.existingGroupsList = data.users[0].userGroups;
      }

      // assign a new group only if the group is not already assigned
      for (let g in selectGroups) {
        if (!(this.existingGroupsList.indexOf(selectGroups[g]) > -1)) {
          currentGroups.push({
            type: "add",
            group: selectGroups[g],
          });
        }
      }

      //remove a group only is the group is already assigned
      for (let g in this.existingGroupsList) {
        if (!(selectGroups.indexOf(this.existingGroupsList[g]) > -1)) {
          currentGroups.push({
            type: "remove",
            group: this.existingGroupsList[g],
          });
        }
      }

      this.userGroupsList = [...selectGroups];
      this.user.userGroups = [...this.userGroupsList];
      this.selectGroupsAccessList = [...currentGroups];
    });
  }

  checkFormStatus() {
    this.assignUserValues();
    let isDirtyForm = compareArray(this.oldUserDetails, this.user);
    return isDirtyForm;
  }

  cancelChanges() {
    let dirtyFormStatus = this.checkFormStatus();
    this.onEditPaneClose.emit(dirtyFormStatus);
  }

  saveEditedUser() {
    if (this.editUserForm.valid) {
      this.assignUserValues();
      //after success - cancelChanges()
      const {
        userRole,
        country,
        timeZone,
        accountExpDate,
        accountLocked,
        archive,
        innovationLab,
        password,
      } = this.editUserForm.getRawValue();
      this.userDetails.user.email = this.userData.email;
      this.userDetails.user.globalRole = userRole.value;
      this.userDetails.user.countryName = (country && country.value) || null;
      this.userDetails.user.timeZone = (timeZone && timeZone.value) || null;
      this.userDetails.user.accountLocked = accountLocked;
      this.userDetails.user.expirationDate = this.toJSONLocal(
        new Date(accountExpDate)
      );
      this.userDetails.user.organization = this.user.organization;
      this.userDetails.user.archive = archive;
      this.userDetails.user.innovationLab = innovationLab;
      this.userDetails.user.organizationAccess = this.user.organizationAccess;
      this.userDetails.user.groupAccess = this.selectGroupsAccessList;

      if (password != DUMMY_PASSWORD) this.userDetails.user.password = password;
      this.usersService
        .updateUserAccount(this.userDetails)
        .subscribe((data) => {
          this.onUserUpdate.emit(this.user);
        });
    }
  }

  toJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON();
  }

  resendActivationLink() {
    this.usersService
      .resendActivationLinkByAdmin(this.userData.email)
      .subscribe((data) => {
        openToast(
          this._snackbar,
          "Activation Email Sent Successfully",
          STATUS.SUCCESS
        );
      });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.editUserForm.controls[controlName].hasError(errorName);
  };

  public setEmpty = (controlName: string) => {
    return this.editUserForm.controls[controlName].setValue("");
  };

  public displayFn(userRole): string {
    return userRole && userRole.label;
  }
}
