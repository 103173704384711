/**
 * heart-beat-resolver service is used to check the validity of user's session. This resolver service
 * is called before page load and at an interval of every 2 minutes only in pages where the
 * user's session is required.
 *
 * This service checks with Gandalf for user's session based on JWT token. If the session exists,
 * it responds with 200 and if the session is expired, then the user is redirected to login page.
 */
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable, interval } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { CommonService } from './common.service';
import { SESSION_INTERVAL_DURATION } from '../../../utils/common-variables';

@Injectable()
export class HeartBeatResolver implements Resolve<any>{

  constructor(
    private commonService: CommonService) {
  }

  resolve(): Observable<any> | Promise<any> | any {
    interval(SESSION_INTERVAL_DURATION)
      .pipe(
        flatMap(() => this.commonService.checkHeartBeat(true))
      ).subscribe();
    return this.commonService.checkHeartBeat(false);
  }

}
