/* common functions used across 'Users' module */

/*
 *	This function accepts lists such as country, timezone and roleMap and
 *	formats into an array of label and value to use in nd-combobox
 */
export let getKeyValueList = function(rawData) {
	var resultList = [];
	for(let listItem in rawData) {
		resultList.push({label: rawData[listItem].displayName, value: rawData[listItem].name});
  }
  return resultList;
}
