/* List of common API paths which are appended to Base URL in http requests
 * Writing these in separate file so that it is easier to update API changes in future
*/

// Get the details of logged-in user
export const USER_INFORMATION = "userinfo";

// HeartBeat API to check user session
export const HEART_BEAT = "heartBeat";

// Get list of Countries
export const GET_COUNTRIES = "datalist?type=countries";

// Get list of Timezones
export const GET_TIMEZONES = "datalist?type=timezones";

//Get Health of Gandalf
export const GANDALF_HEALTH_API = 'health';

// Get list of roles
export const GET_ROLES_MAP = 'rolesmap';

// Get list of Organizations
export const ORGANIZATIONS_LIST = 'organizations';
