import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GroupsService } from "../../services/groups.service";
import { UpdateGroupsComponent } from "../update-groups/update-groups.component";
import { MESSAGES } from "../../utils/messages";
import { UserInfoService } from "../../../shared/services/user-info.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { openToast } from 'src/app/utils/common-functions';
import { STATUS } from 'src/app/modules/shared/components/error-alert/error-alert.component';

@Component({
  selector: 'um-list-groups',
  templateUrl: './list-groups.component.html',
  styleUrls: ['./list-groups.component.scss'],
  providers: [GroupsService]
})
export class ListGroupsComponent implements OnInit {

  groupList = [];
  permissionsList: any;
  showNewGroup = false;
  viewMembersModal = false;
  manageMembersModal = false;
  showEditPanel = false;
  viewMembersIndex = -1;
  editGroupIndex = -1;
  manageMembersIndex = -1;
  currentViewMembersIndex = -1;
  currentEditGroupIndex = -1;
  currentManageMembersIndex = -1;

  @ViewChild('successToast') successToast: ElementRef;
  @ViewChild(UpdateGroupsComponent)
  private updateGroupComponent: UpdateGroupsComponent;
  constructor(private groupService: GroupsService, private userInfoService: UserInfoService, private _snackbar: MatSnackBar) { }

  ngOnInit() {
    this.userInfoService.getUserInfo().subscribe(data => {

      this.permissionsList = data.permissions.administration
    })
    this.getGroups()
  }

  /** Get list of Groups
   * @param none => gets the entire list of Groups
  **/
  getGroups() {
    this.groupService.getGroups().subscribe(data => {
      this.groupList = data.userGroups;
    });
  }


  /**
   * update or reset the index(editGroupIndex) to the selected group index(currentEditGroupIndex) while editing Group
   * @param index
   */
  editGroup(index) {


    if (this.currentEditGroupIndex == -1) {   //if there is no previous selection
      this.editGroupIndex = index;
    }
    else if (this.currentEditGroupIndex != index) {

      const dirtyEditForm = this.updateGroupComponent.checkFormStatus();
      if (dirtyEditForm) {
        this.editGroupIndex = index;
      }
    }
    this.currentEditGroupIndex = index;
    this.showEditPanel = true;

  }

  /**
   * update or reset the index(viewMembersIndex) to the selected group index(currentViewMembersIndex) while viewing Group Members
   * @param index
   */
  viewMembers(index) {
    if (this.currentViewMembersIndex == -1) {
      this.viewMembersIndex = index;
    }
    this.viewMembersIndex = index;
    this.currentViewMembersIndex = index;
    this.viewMembersModal = true;
  }


  /**
   * update or reset the index based on the selected group's index while managing Group Members
   * @param index
   */
  manageMembers(index) {
    if (this.currentManageMembersIndex == -1) {
      this.manageMembersIndex = index;
    }
    this.manageMembersIndex = index;
    this.currentManageMembersIndex = index;
    this.manageMembersModal = true;
  }


  /**
   *  Close create new group Panel view
   * @param newGroup
   */
  closeCreateGroup(newGroup) {
    this.showNewGroup = false;
    if (newGroup) {
      this.showSuccessToast(newGroup.groupName + MESSAGES.CREATE_GROUP)
    }
  }


  /**
   * close Edit group panel view
   * @param updatedGroup
   */

  closeEditGroup(updatedGroup) {
    if (updatedGroup) {
      this.showSuccessToast(updatedGroup.groupName + MESSAGES.EDIT_GROUP)
    }
    this.resetEditGroupPanel();
    this.showEditPanel = false;
  }


  /**
   *  close Manage Members Modal view
   * @param updatedGroup
   */
  closeManageMembers(updatedGroup) {
    this.manageMembersIndex = -1;
    this.currentManageMembersIndex = -1;
    if (updatedGroup) {
      this.showSuccessToast(MESSAGES.MANAGE_MEMBERS)
    }
    else {
      this.getGroups()
    }

  }

  /**
   * Reset index for Edit Group Panel when the panel is closed
   */
  resetEditGroupPanel() {
    this.editGroupIndex = -1;
    this.currentManageMembersIndex = -1;
    this.currentEditGroupIndex = -1;
  }


  /**
   * show success toast message when operations are successfully completed
   * @param message
   */
  showSuccessToast(message) {
    openToast(this._snackbar, message, STATUS.SUCCESS);

    this.getGroups();
  }

  continueNavigation() {
    this.editGroupIndex == this.currentEditGroupIndex ? this.resetEditGroupPanel() : this.editGroupIndex = this.currentEditGroupIndex;
  }

}
