// angular imports
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  Inject,
  TemplateRef,
} from "@angular/core";
// MISC imports
import { UsersService } from "../../../users/services/users.service";
import {
  extractEmailDomain,
  checkDomainMismatch,
} from "../../../../utils/common-functions";
import {
  ORGANIZATION_MODAL_TITLE_MAP,
  ORGANIZATION_MODAL_ORIGIN,
} from "../../../../utils/common-variables";
import { DEFAULT_ORGANIZATION } from "../../../users/utils/common-variables";
import { OrgSearchService } from "./org-search.service";
import { HttpLoaderService } from "../http-loader/http-loader.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatRadioGroup } from "@angular/material/radio";

@Component({
  selector: "org-search",
  templateUrl: "./org-search.component.html",
  styleUrls: ["./org-search.component.scss"],
})
export class OrgSearchComponent implements OnInit {
  searchValue: string;
  orgAccessOriginValue: string;
  modalTitle: string;
  orgList: any;
  totalOrgList: any;
  userEmailDomain: string;
  showOrgSelectionError: boolean;
  showOrgAccessError: boolean;
  selectedOrg = {
    label: "",
    value: "",
    domains: "",
    emailDoman: "",
  };
  modalOrginMap = {};
  selectedOrgAccessData = [];
  noDomainsOrgValues: string;
  selectedMisMatchedOrgAccessValues: string;
  selectedOrgAccessValues = [];
  dialogRef: MatDialogRef<any>;
  domainMismatchDialogRef: MatDialogRef<any>;
  noOrgDomainDialogRef: MatDialogRef<any>;
  accessDomainMismatchDialogRef: MatDialogRef<any>;

  @Input() modalOrigin: string;
  @Input() userEmail;
  @Input() userOrg;
  @Input() organisationsList;
  @Input() isInternalUser;
  @Output() hideOrgModal = new EventEmitter();
  @Output() selectOrgName = new EventEmitter();
  @Output() selectOrgAccess = new EventEmitter();

  @ViewChild("orgSearchDialog") public orgSearchDialog: TemplateRef<any>;
  @ViewChild("domainMismatchDialog") domainMismatchDialog: TemplateRef<any>;
  @ViewChild("accessDomainMismatchDialog")
  accessDomainMismatchDialog: TemplateRef<any>;
  @ViewChild("noOrgDomainDialog") noOrgDomainDialog: TemplateRef<any>;
  @ViewChild("orgResultList") orgResultList: MatRadioGroup;
  @ViewChildren("orgAccessList") orgAccessList: QueryList<any>;

  constructor(
    private orgSearchService: OrgSearchService,
    private httpLoaderService: HttpLoaderService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.httpLoaderService.display(true);
    this.modalOrginMap = ORGANIZATION_MODAL_ORIGIN;
    this.orgAccessOriginValue = ORGANIZATION_MODAL_ORIGIN.orgAccess;
    this.modalTitle = ORGANIZATION_MODAL_TITLE_MAP[this.modalOrigin];
    setTimeout(() => {
      this.getOrganisationsList();
    }, 0);
  }

  // Get organization list and assign to organization field
  formatOrganisations(orgList) {
    let formattedOrgList = [];
    for (let org in orgList) {
      formattedOrgList.push({
        label: orgList[org].displayName,
        value: orgList[org].orgName,
        domains: orgList[org].domainNames,
      });
    }
    return formattedOrgList;
  }

  // Get Organizations from Answers
  getOrganisationsList() {
    this.orgList = [];
    let resultData = Object.assign([], this.organisationsList);
    if (resultData && resultData.length > 0)
      this.orgList = this.formatOrganisations(resultData);
    this.totalOrgList = Object.assign([], this.orgList);
    if (
      this.modalOrigin.toLowerCase() ==
      ORGANIZATION_MODAL_ORIGIN.orgAccess.toLowerCase()
    ) {
      this.selectedOrgAccessData = [...this.userOrg];
      this.selectedOrgAccessValues = this.userOrg.map((org) => org.value);
    }

    //this.orgSearchDialog.nativeElement.open();
    this.dialogRef = this.dialog.open(this.orgSearchDialog, {
      panelClass: "custom-modalbox",
      disableClose: true,
    });
    if (this.dialogRef) {
      this.dialogRef.afterClosed().subscribe((result) => {
        this.closeOrgModal();
      });
    }

    let selectedOrg = this.orgList.filter(
      (data) => data.value === this.userOrg
    );
    if (selectedOrg.length) {
      this.selectedOrg = Object.assign({}, selectedOrg[0]);
    }

    this.httpLoaderService.display(false);
  }

  searchByValue(searchTerm) {
    this.orgList = [
      ...this.orgSearchService.getSearchResults(this.totalOrgList, searchTerm),
    ];
  }

  closeOrgModal() {
    this.hideOrgModal.emit(true);
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  selectOrgField(selectedOrg) {
    this.selectedOrg.value = selectedOrg.value;
    this.selectedOrg.label = selectedOrg.value;
    this.selectedOrg.domains = selectedOrg.domains;
    this.showOrgSelectionError = false;
  }

  toggleOrgAccessSelection(event, selectedOrgAccess) {
    if (event.checked) {
      this.selectedOrgAccessData.push({
        value: selectedOrgAccess.value,
        label: selectedOrgAccess.label,
        domains: selectedOrgAccess.domains,
      });
    } else {
      this.selectedOrgAccessData = this.selectedOrgAccessData.filter(
        (selection) => selection.value != selectedOrgAccess.value
      );
    }
    this.selectedOrgAccessValues = this.selectedOrgAccessData.map(
      (org) => org.value
    );
  }

  continueOrgSubmission() {
    this.selectedOrgAccessValues = this.selectedOrgAccessData.map(
      (org) => org.value
    );
    this.selectOrgName.emit(this.selectedOrg);
    this.closeOrgModal();
    if (this.domainMismatchDialogRef) {
      this.domainMismatchDialogRef.close();
    }
  }

  continueOrgAccessSubmission() {
    this.selectedOrgAccessValues = this.selectedOrgAccessData.map(
      (org) => org.value
    );
    this.selectOrgAccess.emit(this.selectedOrgAccessData);
    this.closeOrgModal();
    if (this.accessDomainMismatchDialogRef) {
      this.accessDomainMismatchDialogRef.close();
    }
  }

  selectOrganization() {
    if (this.orgList && this.orgList.length > 0) {
      // If the request is from create account.
      if (!this.userEmail) {
        this.continueOrgSubmission();
        return false;
      }

      // skip domain validation for "NielsenIQ Studio" as it is the default organization
      if (
        this.selectedOrg.value.toLowerCase() ==
        DEFAULT_ORGANIZATION.toLowerCase()
      ) {
        this.continueOrgSubmission();
        return false;
      }

      // Check domain matching as per Organization
      this.userEmailDomain = extractEmailDomain(this.userEmail);
      let comparisonReponse = checkDomainMismatch(this.userEmailDomain, [
        this.selectedOrg,
      ]);
      switch (comparisonReponse.result) {
        case "noOrgDomain":
          this.noDomainsOrgValues = comparisonReponse.noDomainOrgs.toString();
          this.noOrgDomainDialogRef = this.dialog.open(this.noOrgDomainDialog, {
            panelClass: "custom-modalbox",
            disableClose: true,
          });
          break;
        case "domainMismatched":
          this.dialog.openDialogs.pop();
          this.domainMismatchDialogRef = this.dialog.open(
            this.domainMismatchDialog,
            { panelClass: "custom-modalbox", disableClose: true }
          );
          break;
        case "domainMatched":
          this.continueOrgSubmission();
          break;
      }
    }
  }

  // Check domain matching as per Organization and provide access
  selectOrganizationAccess() {
    this.userEmailDomain = extractEmailDomain(this.userEmail);
    let comparisonReponse = checkDomainMismatch(
      this.userEmailDomain,
      this.selectedOrgAccessData
    );
    switch (comparisonReponse.result) {
      case "noOrgDomain":
      case "domainMismatched":
        this.selectedMisMatchedOrgAccessValues = comparisonReponse.mismatchedAndNoDomainOrgs.toString();
        this.accessDomainMismatchDialogRef = this.dialog.open(
          this.accessDomainMismatchDialog,
          { panelClass: "custom-modalbox", disableClose: true }
        );
        break;
      case "domainMatched":
        this.continueOrgAccessSubmission();
        break;
    }
  }
}
