// angular imports
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

// MISC imports
import {
  USERS_API,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  RESET_PASSWORD_PARAMETER,
  RESEND_ACTIVATION_BY_ADMIN,
  RESEND_ACTIVATION_BY_USER,
  CHANGE_PASSWORD,
  INTERNAL_USER,
  RESET_EXPIRED_PASSWORD,
  REQUEST_CREAT_ACCOUNT,
  GLOBAL_FEATURE_FLAGS,
  USERS_FEATURE_FLAGS
} from '../utils/api-list';
import {GET_ORGANIZATIONS,GET_GROUPS} from '../utils/api-list';
import {SEARCH_USERNAME} from '../utils/api-list';
import {GET_USER_DETAILS} from '../utils/api-list';
import { USER_PROFILE_DETAILS } from '../utils/api-list';
import { AppConfigService } from '../../core/config/app-config.service';

@Injectable()
export class UsersService {

  constructor (
    public httpClient: HttpClient,
    public appConfigService: AppConfigService) {
  }

  /**
   * Reset User's Password
   * @param email
   * */
  resetUserPassword(userEmail) {
    return this.httpClient.put<any>(`${this.appConfigService.config.gandalf.url}/${RESET_PASSWORD}?${RESET_PASSWORD_PARAMETER}=${encodeURIComponent(userEmail)}`, {})
  }

  /**
   * Update User's Password for reset password functionality
   * @param userInfo
   * */
  updateUserPassword(token, userInfo) {
    return this.httpClient.put<any>(`${this.appConfigService.config.gandalf.url}/${UPDATE_PASSWORD}?t=${token}`, userInfo)
  }

  /**
   * change User's Password for reset password functionality
   * @param userInfo
   * */
  changeUserPassword(userInfo) {
    return this.httpClient.put<any>(`${this.appConfigService.config.gandalf.url}/${CHANGE_PASSWORD}`, userInfo)
  }

  /**
   * change User's Password for expired user
   * @param userInfo
   * */
  resetExpiredPassword(userInfo) {
    return this.httpClient.put<any>(`${this.appConfigService.config.gandalf.url}/${RESET_EXPIRED_PASSWORD}`, userInfo)
  }

  /**
   * Get list of Organisations from Answers
   * @param none
   * */
  getOrganizations() {
    return this.httpClient.get<any>(`${this.appConfigService.config.gandalf.url}/${GET_ORGANIZATIONS}`);
  }

  /**
   * Get list of Groups to which User is assigned to
   */
  getGroups(){
    return this.httpClient.get<any>(`${this.appConfigService.config.gandalf.url}/${GET_GROUPS}`);
  }


  /**
   * Create User with specified user details
   * @param Create Account form data
   * */
  createUserAccount(userInfo) {
    return this.httpClient.post<any>(`${this.appConfigService.config.gandalf.url}/${USERS_API}`, userInfo);
  }

  /**
   * Get saved user information by sending unique token
   * @param user token
   * */
  getUserDetails(token) {
    let userParams = new HttpParams();
    userParams = userParams.append('t', token);

    return this.httpClient.get<any>(`${this.appConfigService.config.gandalf.url}/${GET_USER_DETAILS}`, {params: userParams});
  }

  /**
   * Activate User with specified user details
   * @param Activate Account form data
   * */
  activateUserAccount(token, userInfo) {
    return this.httpClient.put<any>(`${this.appConfigService.config.gandalf.url}/${GET_USER_DETAILS}?t=${token}`, userInfo);
  }

  /**
   * Get list of Users from Answers
   * @param none => gets the entire list of users
   * @param searchTerm => gets list of users with search term
   * */
  getUsers(searchTerm) {
    let getUsersAPI = searchTerm === '' ? `${this.appConfigService.config.gandalf.url}/${USERS_API}` : `${this.appConfigService.config.gandalf.url}/${USERS_API}?${SEARCH_USERNAME}=${encodeURIComponent(searchTerm)}`;
    return this.httpClient.get<any>(getUsersAPI);
  }

  getUsersWithInternalFlag(searchTerm) {
    let getUsersAPI = searchTerm === '' ? `${this.appConfigService.config.gandalf.url}/${USERS_API}/${INTERNAL_USER}` : `${this.appConfigService.config.gandalf.url}/${USERS_API}/${INTERNAL_USER}?${SEARCH_USERNAME}=${encodeURIComponent(searchTerm)}`;
    return this.httpClient.get<any>(getUsersAPI);
  }

  getUsersWithPageIndex(searchTerm, offset) {
    let getUsersAPI = `${this.appConfigService.config.gandalf.url}/${USERS_API}?`;
    getUsersAPI = searchTerm === '' ? getUsersAPI : `${getUsersAPI}${SEARCH_USERNAME}=${searchTerm}&`;
    let pageIndex = offset == 0 ? offset : offset*100;
    getUsersAPI = getUsersAPI + `startIndex=${pageIndex}`;
    return this.httpClient.get<any>(getUsersAPI);
  }

  /**
   * Update User with specified user details
   * @param Update Account form data
   * */
  updateUserAccount(userInfo) {
    return this.httpClient.put<any>(`${this.appConfigService.config.gandalf.url}/${USERS_API}`, userInfo);
  }

  /**
   * Update User profile with specified profile details
   * @param profileData - Update profile form data
   * */
  updateUserProfile(profileData) {
    return this.httpClient.put<any>(`${this.appConfigService.config.gandalf.url}/${USER_PROFILE_DETAILS}`, profileData);
  }

  /* ----- Resend Activation Link ----- */
  /**
   * re-sending Activation Link for a user via admin console
   * @param email - email of the user for whom we are resending the activation link
   * */
  resendActivationLinkByAdmin(email) {
    let userParams = new HttpParams();
    userParams = userParams.append('email', encodeURIComponent(email));
    return this.httpClient.get<any>(`${this.appConfigService.config.gandalf.url}/${RESEND_ACTIVATION_BY_ADMIN}`, {params: userParams});
  }

  /**
   * Resend activation link to user
   * @param token - token present in activation link
   */
  resendActivationLinkByUser(email, token) {
    let userParams = new HttpParams();
    userParams = userParams.append('email', encodeURIComponent(email));
    if (token != null){
      userParams = userParams.append('t', token);
    }
    return this.httpClient.get<any>(`${this.appConfigService.config.gandalf.url}/${RESEND_ACTIVATION_BY_USER}`, {params: userParams})
  }

  /**
   * Request Account link for user
   * @param requestAttributes - requestAttributes represents the user request details
   */
  accountCreationRequestSubmission(requestAttributes){
    return this.httpClient.post<any>(`${this.appConfigService.config.gandalf.url}/${REQUEST_CREAT_ACCOUNT}`, requestAttributes);
  }

  getUserLevelFeatureFlags(email) {
    const url = `${this.appConfigService.config.gandalf.url}${USERS_FEATURE_FLAGS}`;
    return this.httpClient.get(url+'/'+ email);
  }

   getGlobalFeatureFlags() {
    return this.httpClient.get(`${this.appConfigService.config.gandalf.url}${GLOBAL_FEATURE_FLAGS}`);
  }
  
  updateUserLevelFeatureFlags(email, payload) {
    const url = `${this.appConfigService.config.gandalf.url}${USERS_FEATURE_FLAGS}/`+ email;
    return this.httpClient.put(url, payload);
  }
}
