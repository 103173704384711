// User model for Organization properties
export class Organization {
	orgName: string;
	orgType: string;
	country: string;
	displayName: string;
	description: string;
	domainNames: string;
	parentOrganizationID: string;
}
