// angular imports
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

// MISC imports
import { UsersRoutingModule } from './modules/users/users-routing.module';
import { SharedRoutingModule } from './modules/shared/shared-routing.module';
import { AppLayoutModule } from './modules/app-layout/app-layout.module';

const ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/users',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/invalid-url'
  }
];

@NgModule({
  imports: [
    SharedRoutingModule,
    UsersRoutingModule,
    AppLayoutModule,
    RouterModule.forRoot(ROUTES, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
