export const MESSAGES = {
VALID_EMAIL : 'Please enter a valid email address',
ERROR_BELOW_DETAILS: 'An error occurred. See below for more details',
ACCOUNT_EMAIL_ALREADY_EXISTS: 'An account with that email address already exists.',
ACCOUNT_SUCCESFULLY_CREATED: 'You have successfully created an account for \"',
ENTER_REQUIRED_DETAILS: 'Please enter required details.',
CONFIRM_PASSWORD: 'Please confirm your password',
PASSWORD_MATCH: 'Passwords must match',
PASSWORD_RESET_LINK_EXPIRED: 'The password reset link has expired.',
PASSWORD_RESET_CHECK_EMAIL_FOR_DETAILS: 'Your password has been reset. Please check your email address for details on logging in.',
ACCOUNT_CREATION_REQUEST_SUCCESFULLY_SENT: 'Your account request has been received. You should be receiving an activation link to the email you provided shortly.',
ACCOUNT_EXISTS_NEEDS_TO_BE_ACTIVATED: 'An account exists for this email but needs to be activated.',

};
