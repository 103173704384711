// angular imports
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  TemplateRef
} from '@angular/core';
import { HttpLoaderService } from "../http-loader/http-loader.service";
import { GROUP_MODAL_TITLE_MAP } from "../../../../utils/common-variables";
import { GroupsService } from "../../../groups/services/groups.service";
import { UsersService } from "../../../users/services/users.service";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'group-search',
  templateUrl: './group-search.component.html',
  styleUrls: ['./group-search.component.scss'],
  providers: [GroupsService]
})
export class GroupSearchComponent implements OnInit {

  modalTitle: string;
  groupsList = []
  user: any;
  existingGroupsList = []
  selectedGroupsData = [];
  modifiedGroupsValues = [];  //temp array to store changes
  dialogRef: MatDialogRef<any>;

  @Input() modalOrigin: string;
  @Input() userEmail: string;
  @Input() userGroupsList;
  @Input() isInternalUser;
  @Output() hideGroupModal = new EventEmitter();
  @Output() selectGroups = new EventEmitter();

  @ViewChild('groupSearchDialog') groupSearchDialog: TemplateRef<any>;
  @ViewChildren('gList') gList: QueryList<any>;
  @ViewChild('manageGroupsSuccessToast') successToast: ElementRef;

  constructor(private usersService: UsersService,
    private groupService: GroupsService,
    private httpLoaderService: HttpLoaderService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.httpLoaderService.display(true);
    this.modalTitle = GROUP_MODAL_TITLE_MAP[this.modalOrigin];
    this.groupService.getGroups().subscribe(data => {
      this.groupsList = data.userGroups;
      this.groupsList = this.formatGroups(this.groupsList)
    });
    if (!this.userGroupsList) {
      this.userGroupsList = []
    }

    this.usersService.getUsers(this.userEmail).subscribe(data => {
      if (data.users[0].userGroups) {
        this.existingGroupsList = data.users[0].userGroups
      }
    });
    this.modifiedGroupsValues = this.userGroupsList;
    this.selectedGroupsData = [...this.userGroupsList]
    this.httpLoaderService.display(false);
    setTimeout(() => {
      this.dialogRef = this.dialog.open(this.groupSearchDialog, {
        width: '450px',
        panelClass: 'custom-modalbox',
        disableClose: true
      });
      this.dialogRef.afterClosed().subscribe(result => {
        this.closeGroupModal('cancel');
      });
    })

  }

  // Format groups List Data
  formatGroups(groupsList) {
    let formattedGroupList = [];
    for (let group in groupsList) {
      if (this.isInternalUser == false) {
        if (this.isInternalUser == groupsList[group].isInternalGroup) {
          formattedGroupList.push({
            value: groupsList[group].groupName
          });
        }
      } else {
        formattedGroupList.push({
          value: groupsList[group].groupName
        });
      }
    }
    return formattedGroupList;
  }


  /**
   * close Group Modal
   */
  closeGroupModal(type) {
    if (type == 'cancel') {
      this.selectGroups.emit(this.selectedGroupsData);
    }
    this.hideGroupModal.emit(true);
    this.dialogRef.close();
  }

  /**
   * Updates the list of selected groups data when the form is updated
   * @param event
   */
  toggleGroupAccessSelection(event, group) {
    if (event.checked && !(this.modifiedGroupsValues.indexOf(group.value) > -1)) {
      this.modifiedGroupsValues.push(group.value)
    } else {
      this.modifiedGroupsValues.splice(this.modifiedGroupsValues.indexOf(group.value), 1)
    }
  }


  /**
   * Saves updated Groups Data and closes the modal
   */
  updateAndSave() {
    this.selectGroups.emit(this.modifiedGroupsValues);
    this.closeGroupModal('save');
  }

}
