// angular imports
import { Component, OnInit } from '@angular/core';

// MISC imports
import { environment } from '../../../environments/environment';
import { UserInfoService } from '../shared/services/user-info.service';
import { USER_ACCESS } from "../../utils/common-variables";
import { CommonService } from '../shared/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'um-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  isDev = !environment.production;
  activePageTitle: string;
  currentUser: {};
  permissionsList: any;

  userAccess = USER_ACCESS;

  tabIndexes = [
    { name: 'users', url: '/users', index: 0, label: 'Users' },
    { name: 'groups', url: '/groups', index: 1, label: 'Groups' },
    { name: 'organizations', url: '/organizations', index: 2, label: 'Organization' },
    { name: 'APP_MANAGEMENT_TAB', url: '/application-management', index: 3, label: 'Application Management' }
  ];

  constructor(
    private userInfoService: UserInfoService,
    private commonService: CommonService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.userInfoService.getUserInfo().subscribe(data => {
      this.currentUser = data.user;
      const currentUserRole = data.user.globalRole.toLowerCase();
      this.userAccess.adminAccess = this.userInfoService.checkAdminUser(currentUserRole);
      this.userAccess.fullAccess = this.userInfoService.checkFullAccessUser(currentUserRole);
      this.commonService.setUserAccess(this.userAccess);
      this.permissionsList = data.permissions.administration
    });
  }
}
