// User model for user properties
export class User {
	firstName: string;
	middleName: string;
	lastName: string;
	email: string;
	organization: string;
	organizationAccess: string;
	groupAccess: string;
	createdBy: string;
	countryName: string;
	timeZone: string;
	globalRole: string;
	currentPassword: String;
	password: string;
	confirmPassword: string;
	accountLocked: boolean;
	expirationDate: any;
	token: string;
	archive: boolean;
	isEULA: boolean;
  	innovationLab: boolean;
	//countryName?: string;
}
